import React, { useMemo, useState } from 'react'
import TablePairs from '../../components/pages/liquidity/tablePairs'
import TransparentButton from '../../components/common/Buttons/transparentButton'
import {useNavigate} from 'react-router-dom'
import TabFilter from '../../components/common/TabFilter'
import MobileFilterModal from '../../components/common/MobileFilterModal'
import { NewPools, PoolTypes } from '../../config/constants'
import { ZERO_ADDRESS } from '../../utils/formatNumber'
import FilterIcon from '../../icons/FilterIcon'
import { useGetAllLiquidityPools } from 'hooks/v3/useGetAllLiquidityPools'
import { useMerklClaimAll } from 'hooks/v3/useMerklClaimAll'
import { useGetMerklDataQuery } from 'hooks/queries/useGetMerklDataQuery'
import Spinner from 'components/common/Spinner'
import { ElapsedSinceLastDistribution } from './ElapsedSinceLastDistribution'
import NoFound from "../../components/common/NoFound";
import {useWeb3React} from "@web3-react/core";
import BigNumber from "bignumber.js";

const sortOptions = [
  {
    label: 'Avg. APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'TVL',
    value: 'totalTvl',
    isDesc: true,
  },
  {
    label: 'Total Pool',
    value: 'pool',
    isDesc: true,
  },
  {
    label: 'Earned Usd',
    value: 'earn',
    isDesc: true,
  },
]

const Index = () => {
  const [filter, setFilter] = useState(PoolTypes.ALL)
  const [mobileFilter, setMobileFilter] = useState(false)
  const [isInactive, setIsInactive] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState({
    label: 'APR',
    value: 'apr',
    isDesc: true,
  })

  const { merklDataQuery } = useGetMerklDataQuery()
  const { claimAll, isLoading: isClaimLoading } = useMerklClaimAll()
  const allPools = useGetAllLiquidityPools()
  // const pools = [...new Map(allPools.map((item) => [item.poolAddress.toLowerCase(), item])).values()]
  const pools = allPools

  const filteredPairs = useMemo(() => {
    let final

    if (isInactive) {
      final = pools.filter((ele) => ele.gauge.apr.isZero())

      // final = [...final, ...pairs]
    } else {
      final = pools
    }

    const result = final.filter((item) => {
      const isCorrect = item.gauge.address !== ZERO_ADDRESS
      return isCorrect
    })
    let res
    if (filter === PoolTypes.ALL){
      res = result.filter((item) => item.alm === 'MANUAL');
    }
    else{
      res = result.filter((item) => item.alm === filter);
    }
    if (!searchText || searchText === '') {
      return res
    }
    return (
      res &&
      res.filter((item) => {
        const withSpace = item.symbol.replace('/', ' ')
        const withComma = item.symbol.replace('/', ',')

        if (item.symbol.includes('miMATIC') && searchText.toLowerCase().includes('mai')) return true

        return (
          item.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
          withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
          withComma.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    )
  }, [allPools, filter, searchText, isInactive])

  const oldFilteredPairs = useMemo(() => {
    return filteredPairs.filter((item) => !(NewPools.includes(item.address) && item.gauge.apr.isZero()))
  }, [filteredPairs])

  const sortedPairs = useMemo(() => {
    return oldFilteredPairs.sort((a, b) => {
      let res
      switch (sort.value) {
        case 'apr':
          res = a.gauge.apr
            .minus(b.gauge.apr)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'totalTvl':
          res = new BigNumber(a.totalTvl)
            .minus(new BigNumber(b.totalTvl))
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'pool': {
          res = a.account.totalUsd
            .minus(b.account.totalUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        }
        case 'stake':
          res = a.account.stakedUsd
            .minus(b.account.stakedUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'earn':
          res = a.account.earnedUsd
            .minus?.(b.account.earnedUsd)
            .times?.(sort.isDesc ? -1 : 1)
            .toNumber()
          break

        default:
          break
      }
      return res
    })
  }, [oldFilteredPairs, sort])

  const earnedPairs = useMemo(() => {
    return allPools.filter((pair) => {
      return !pair.account?.earnedUsd?.isZero?.()
    })
  }, [allPools, isInactive])

  const navigate = useNavigate()

  const handleAddPopup = () => {
    navigate('/liquidity/addV3')
  }

  const handleManagePopup = () => {
    navigate('/liquidity/managev3')
  }

  const hasTransactionDataToClaim = Object.keys(merklDataQuery.data?.transactionData ?? {})?.length > 0
  const isClaimDisabled = (earnedPairs.length === 0 || !hasTransactionDataToClaim || isClaimLoading) && true
  const { account } = useWeb3React()

  return (
    <>
      <div className='w-full max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 pt-5 pb-10 xl:pt-10 md:pt-8 mx-auto'>
        <div className='lg:flex items-end justify-between'>
          <div className='max-w-[532px] w-full'>
            <h1 className='text-[28px] md:text-[36px] font-semibold text-white  f-f-fg'>Liquidity</h1>
            <p className='text-[#b8b6cb] text-base leading-[20px] md:leading-6 mt-1'>
              Create a pair or add liquidity to existing pools.
            </p>
          </div>
          <div className='bottom-0 md:bottom-4 transition-all duration-300 ease-in-out flex items-center fixed md:max-w-[600px] mx-auto w-full left-0 right-0 z-[100] border-white border-[1px] rounded-[15px] bg-componentPrimary justify-between px-5 py-3 md:py-3.5'>
            <ElapsedSinceLastDistribution />
            <button
              disabled={earnedPairs.length === 0 || !hasTransactionDataToClaim || isClaimLoading}
              onClick={claimAll}
              content={`CLAIM ALL EARNINGS`}
              className={`transition-all duration-300 ease-in-out py-[13px] md:py-[14.53px] bg-[#2166fa] ${
                isClaimDisabled ? 'bg-grayHover text-black' : 'text-white'
              } w-[50%] flex items-center justify-center text-sm tracking-[0.84px] md:tracking-[1.36px] px-[33px] lg:px-[43px] xl:px-[33px] rounded-[15px]`}
            >
              {isClaimLoading && (
                <span className='mr-1'>
                  <Spinner />
                </span>
              )}{' '}
              CLAIM ALL EARNINGS
            </button>
          </div>
        </div>
        <div className='flex items-center justify-between w-full mt-3 xl:mt-9 lg:space-x-8 xl:gap-x-6 relative flex-col xsm:flex-row'>
          <div className='w-[260px] flex gap-x-2 pb-4 xsm:pb-0'>
            <div className='rounded-[15px] flex items-center relative bg-componentPrimary flex-grow'>
              <img
                  className='lg:block pointer-events-none absolute left-3 w-6 h-6'
                  alt=''
                  src='/images/svgs/search.svg'
              />
              <input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder='Search Pair or Token'
                  className='bg-componentPrimary placeholder-placeholder xl:h-[52px] w-full text-white text-sm xl:text-[14px] pl-10 xl:pl-[41px] pr-3 py-3 md:py-[18px] rounded-[15px]'
              />
            </div>
            {/* filter button for mobile */}
            <button
                onClick={() => {
                  setMobileFilter(!mobileFilter)
                }}
                className='flex w-16 justify-center items-center flex-shrink-0 lg:hidden bg-componentPrimary rounded-[15px]'
            >
              <FilterIcon />
            </button>
          </div>
          <div className='hidden lg:flex'>
            <TabFilter data={Object.values(PoolTypes)} filter={filter} setFilter={setFilter} />
          </div>
          <div className='flex flex-row'>
            <div className='flex items-center gap-x-5 ml-2'>
              <TransparentButton
                content={'MY POSITIONS'}
                className={
                  'xl:max-w-[250px] max-w-[250px] h-[52px] px-4 text-sm xl:text-[14px] lg:flex items-center justify-center w-full rounded-[15px] text-sm tracking-[1.04px] xl:tracking-[1.36px]'
                }
                onClickHandler={handleManagePopup}
              />
            </div>
            <div className='flex items-center gap-x-5 ml-2'>
              <TransparentButton
                  content={'ADD LIQUIDITY'}
                  className={
                    'xl:max-w-[250px] max-w-[250px] h-[52px] px-4 text-sm xl:text-[14px] lg:flex items-center justify-center w-full rounded-[15px] text-sm tracking-[1.04px] xl:tracking-[1.36px]'
                  }
                  onClickHandler={handleAddPopup}
              />
            </div>
          </div>

          {/* mobile filters popup */}
          {mobileFilter && (
            <MobileFilterModal
              setMobileFilter={setMobileFilter}
              setFilter={setFilter}
              filter={filter}
              tabs={Object.values(PoolTypes)}
              isInactive={isInactive}
              setIsInactive={setIsInactive}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
            />
          )}
        </div>
        {account ? (<TablePairs
          pairsData={sortedPairs}
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          filter={filter}
          searchText={searchText}
        />
        ) : (
        <div className='w-full mt-9'>
          <NoFound title={'Please connect your wallet'} />
        </div>
        )}
      </div>
    </>
  )
}

export default Index
