import React from 'react'
import styled from 'styled-components'
const Button = styled.button`
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
`

const TransparentButton = ({ content, className, onClickHandler = null, disabled, fontWeight }) => {
  return (
    <Button
      role={'button'}
      aria-disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onClickHandler()
        }
      }}
      className={`${className} bg-white text-black hover:bg-grayHover ${fontWeight ? fontWeight : 'font-semibold'} ${disabled ? 'cursor-not-allowed' : ''}`}
    >
      {content}
    </Button>
  )
}

export default TransparentButton
