import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { v4 as uuidv4 } from 'uuid'
import { TransactionType } from '../../config/constants'
import { useV3MintActionHandlers } from '../../state/mintV3/hooks'
import useWeb3 from '../useWeb3'
import { completeTransaction, openTransaction, updateTransaction } from '../../state/transactions/actions'
import {getERC20Contract, getWMNTContract} from '../../utils/contractHelpers'
import { getAllowance, sendContract } from '../../utils/api'
import {formatAmount, fromWei, MAX_UINT256} from '../../utils/formatNumber'
import { useSteerStrategiesQuery } from 'hooks/queries/useSteerStrategiesQuery'
import { useSteerLiquidityRatio } from 'hooks/queries/useSteerLiquidityRatio'
import Big from 'big.js'
import {BigNumber, utils} from "ethers";
import {useSteerPeripheryContract} from "../useContract";

const useSteerAdd = (baseCurrency, quoteCurrency) => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const steerPeriphery = useSteerPeripheryContract();

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers()

  const baseCurrencyAddress = baseCurrency?.address ? baseCurrency?.address?.toLowerCase() : ''
  const quoteCurrencyAddress = quoteCurrency?.address ? quoteCurrency?.address?.toLowerCase() : ''

  const steerStrategiesQuery = useSteerStrategiesQuery()

    const steerStrategy = steerStrategiesQuery?.data?.find((strategy) => {
    return (
      (strategy?.token0?.address?.toLowerCase() === baseCurrencyAddress &&
        strategy?.token1?.address?.toLowerCase() === quoteCurrencyAddress) ||
      (strategy?.token0?.address?.toLowerCase() === quoteCurrencyAddress &&
        strategy?.token1?.address?.toLowerCase() === baseCurrencyAddress)
    )
  })

  const strategyAddress = steerStrategy?.id
  const liquidityRatioQuery = useSteerLiquidityRatio(strategyAddress)
  const liquidityRatio = liquidityRatioQuery?.data

  const handleSteerAdd = async (amountA, amountB, amountToWrap) => {
    if ((!amountA && !amountB) || !strategyAddress || liquidityRatio === undefined) return

    const key = uuidv4()
    const wrapuuid = uuidv4()
    const approve0uuid = uuidv4()
    const approve1uuid = uuidv4()
    const supplyuuid = uuidv4()
    setPending(true)

    dispatch(
      openTransaction({
        key,
        title: `Add ${baseCurrency.symbol}/${quoteCurrency.symbol} liquidity`,
        transactions: {
          ...(amountToWrap
            ? {
                [wrapuuid]: {
                  desc: `Wrap ${formatAmount(fromWei(amountToWrap.toString(10)))} MNT for WMNT`,
                  status: TransactionType.WAITING,
                  hash: null,
                },
              }
            : {}),
          [approve0uuid]: {
            desc: `Approve ${baseCurrency.symbol}`,
            status: amountToWrap ? TransactionType.START : TransactionType.WAITING,
            hash: null,
          },
          [approve1uuid]: {
            desc: `Approve ${quoteCurrency.symbol}`,
            status: TransactionType.START,
            hash: null,
          },
          [supplyuuid]: {
            desc: `Deposit tokens in the pool`,
            status: TransactionType.START,
            hash: null,
          },
        },
      }),
    )

    if (amountToWrap) {
      const wmntContract = getWMNTContract(web3)
      try {
        await sendContract(dispatch, key, wrapuuid, wmntContract, 'deposit', [], account, amountToWrap.toString(10))
      } catch (err) {
        console.log('wrap error :>> ', err)
        setPending(false)
        return
      }
    }

    let isApproved = true
    const baseTokenContract = getERC20Contract(web3, baseCurrencyAddress)
    const baseAllowance = await getAllowance(baseTokenContract, strategyAddress, account)

    if (amountA && fromWei(baseAllowance, baseCurrency.decimals).lt(amountA.toExact())) {
      isApproved = false

      try {
        await sendContract(
            dispatch,
            key,
            approve0uuid,
            baseTokenContract,
            'approve',
            [steerPeriphery.address, MAX_UINT256],
            account,
        )
      } catch (err) {
        console.log('approve 0 error :>> ', err)
        setPending(false)
        return
      }
    }
    if (isApproved) {
      dispatch(
        updateTransaction({
          key,
          uuid: approve0uuid,
          status: TransactionType.SUCCESS,
        }),
      )
    }




    isApproved = true

    const quoteTokenContract = getERC20Contract(web3, quoteCurrencyAddress)
    const quoteAllowance = await getAllowance(quoteTokenContract, strategyAddress, account)

    if (amountB && fromWei(quoteAllowance, quoteCurrency.decimals).lt(amountB.toExact())) {
      isApproved = false
      try {
        await sendContract(
            dispatch,
            key,
            approve1uuid,
            quoteTokenContract,
            'approve',
            [steerPeriphery.address, MAX_UINT256],
            account,
        )
      } catch (err) {
        console.log('approve 1 error :>> ', err)
        setPending(false)
        return
      }
    }
    if (isApproved) {
      dispatch(
        updateTransaction({
          key,
          uuid: approve1uuid,
          status: TransactionType.SUCCESS,
        }),
      )
    }



    try {
      const amount0 = amountA?.numerator
        ? Big(amountA.numerator.toString())
            .div(10 ** baseCurrency.decimals)
            .toString()
        : 0

      const amount1 = amountB?.numerator
        ? Big(amountB.numerator.toString())
            .div(10 ** quoteCurrency.decimals)
            .toString()
        : 0

      dispatch(
        updateTransaction({
          key,
          supplyuuid,
          status: TransactionType.WAITING,
        }),
      )
      try {
        await sendContract(
            dispatch,
            key,
            supplyuuid,
            steerPeriphery,
            'deposit',
            [strategyAddress,
                utils.parseUnits(amount0, steerStrategy?.token0Decimals),
                utils.parseUnits(amount1, steerStrategy?.token1Decimals),
                // TODO::crust update these value based on slippage
                BigNumber.from(0), //Min amount 0
                BigNumber.from(0), //Min amount 1
                account
              ],
            account,
        )
      } catch (err) {
        console.log('deposit error :>> ', err)
        setPending(false)
        return
      }
    } catch (err) {
      setPending(false)
      return
    }

    onFieldAInput('')
    onFieldBInput('')
    dispatch(
      completeTransaction({
        key,
        final: 'Liquidity Added',
      }),
    )
    setPending(false)
  }

  return { onSteerAdd: handleSteerAdd, pending }
}

export { useSteerAdd }
