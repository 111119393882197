import { useMemo } from 'react'
import {
  MULTICALL_ADDRESS,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
} from '../config/constants/v3/addresses'
import { algebraAbi, multicallV3Abi } from '../config/abi/v3'
import { getContract } from '../utils/contract'
import { useActiveWeb3React } from './useActiveWeb3React'
import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'

const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson

export function useContract(addressOrAddressMap, ABI, withSignerIfPossible = true) {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !chainId) return null

    let address

    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account])
}

export function useMulticall2Contract() {
  return useContract(MULTICALL_ADDRESS, multicallV3Abi, false)
}

export function useV3Algebra() {
  return useContract(NONFUNGIBLE_POSITION_MANAGER_ADDRESSES, algebraAbi, false)
}

export function useV3NFTPositionManagerContract() {
  return useContract(NONFUNGIBLE_POSITION_MANAGER_ADDRESSES, NFTPositionManagerABI, false)
}
