import { useElapsedTime } from 'use-elapsed-time'
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import contracts from 'config/constants/contracts'
import merklDistributorAbi from 'config/abi/merklDistributorAbi.json'
import { useEffect } from 'react'
import { getContract } from 'utils/contract'
// import { getNetworkLibrary } from 'utils'
import { defaultChainId } from 'config/constants'
import Countdown from 'react-countdown'
import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";

export const ElapsedSinceLastDistribution = () => {
  const { account, library } = useActiveWeb3React()

  const merklDistributorContract = getContract(
    contracts.merklDistributor[defaultChainId],
    merklDistributorAbi,
      library,
      account
  )

  const lastDistributionQuery = useQuery(['last-merkl-distribution-date'], async () => {
    try {
      const response = await merklDistributorContract.endOfDisputePeriod()
      return response
    } catch (error) {
      console.error("Error In the world" , error)
    }
  })

  const elapsedSinceLastDistribution = lastDistributionQuery.data
    ? dayjs().diff(lastDistributionQuery.data * 1000)
    : null

  const { elapsedTime, reset } = useElapsedTime({
    updateInterval: 5,
    isPlaying: true,
    startAt: elapsedSinceLastDistribution / 1000,
  })

  useEffect(() => {
    reset()
  }, [lastDistributionQuery.data])

  const isDisputeBefore = dayjs(lastDistributionQuery.data * 1000).isBefore(dayjs())

  const [hours, minutes] = new Date(elapsedTime * 1000).toISOString().substring(11, 16).split(':')

  const countup = `${hours}h ${minutes}m`

  const isMerkelEnabled = false;

  const renderCounter = () => {
    if (isDisputeBefore) return countup

    return (
      <Countdown
        onComplete={() => lastDistributionQuery.refetch()}
        date={lastDistributionQuery.data * 1000}
        renderer={({ hours, minutes, seconds }) => (
          <span>
            {hours}h {minutes}m {seconds}s
          </span>
        )}
      />
    )
  }

  return lastDistributionQuery.data ? (
    <div className='py-[5px] px-[10px] rounded-[15px] justify-end items-center inline-flex'>
      <div className='justify-start items-center gap-2 flex flex-col'>
        <div className='text-[18px] text-center text-white font-semibold'>
          {isMerkelEnabled ? lastDistributionQuery.data ? renderCounter() : '-' : 'N/A'}
        </div>
        <div className='text-white text-[14px] leading-5 tracking-wider'>
          {isDisputeBefore ? 'Since Last ' : 'Until next '}
          <div className='inline-flex relative'>
            <span className='mr-2'>preCRST Distribution</span>
          </div>
        </div>
      </div>
    </div>
  ) : (
      <div className='py-[5px] px-[10px] rounded-[15px] justify-end items-center inline-flex'>
        <div className='justify-start items-center gap-2 flex flex-col'>
          <div className='text-[18px] text-center text-white font-semibold'>
            N/A
          </div>
          <div className='text-white text-[14px] leading-5 tracking-wider'>
            Until next
              <span className='mr-2'> preCRST Distribution</span>
          </div>
        </div>
      </div>
  )
}
