import random from 'lodash/random'

// Array of available nodes to connect to
export const MainNodes = ['https://rpc.mantle.xyz/']

const getRpcUrl = () => {
  const randomIndex = random(0, MainNodes.length - 1)
  return MainNodes[randomIndex]
}

export default getRpcUrl
