// a list of tokens by chain
import {Token} from '@crust_finance/sdk-core'
import {ChainId} from 'config/constants'
import {WMNT_EXTENDED} from '../../../v3lib/entities/constants'
import {MANTLE} from "../../../v3lib/entities/MANTLE";
import {logoURIMap} from "../logo";

export const toToken = (t) => {
  return new Token(Number(t.chainId), t.address, t.decimals, t.symbol, t.name)
}

const routeAssets = {
  WMNT: {
    name: 'Wrapped Mantle',
    symbol: 'WMNT',
    address: '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8',
    chainId: 5000,
    decimals: 18,
    logoURI: logoURIMap["wMNT"].logoURI,
  },
  USDC: {
    name: 'USD Circle',
    symbol: 'USDC',
    address: '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
    chainId: 5000,
    decimals: 6,
    logoURI: logoURIMap["USDC"].logoURI,
  },
  // CRUST: {
  //   name: 'CRUST',
  //   symbol: 'CRST',
  //   address: '0x0d4b4d63149cf37de1C996D3e5b6f0d9CC3Be2e3',
  //   chainId: 5000,
  //   decimals: 18,
  //   logoURI: logoURIMap["CRST"].logoURI,
  // },
  USDT: {
    name: 'Tether USD',
    symbol: 'USDT',
    address: '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE',
    chainId: 5000,
    decimals: 6,
    logoURI: logoURIMap["USDT"].logoURI,
  },
  WETH: {
    name: 'Wrapped ETH',
    symbol: 'WETH',
    address: '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111',
    chainId: 5000,
    decimals: 18,
    logoURI: logoURIMap["wETH"].logoURI,
  },
}

export const USDC = toToken(routeAssets.USDC)
export const USDT = toToken(routeAssets.USDT)
// const CRST = toToken(routeAssets.CRUST)

const WBNB_ONLY = Object.fromEntries(Object.entries(WMNT_EXTENDED).map(([key, value]) => [key, [value]]))

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST = {
  ...WBNB_ONLY,
  // [ChainId.MANTLE]: [...WBNB_ONLY[ChainId.MANTLE], USDC, USDT, RETRO],
  [ChainId.MANTLE]: [...WBNB_ONLY[ChainId.MANTLE], USDC, USDT],
}

export const ADDITIONAL_BASES = {}
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES = {}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES = {
  // [ChainId.MANTLE]: [ExtendedEther.onChain(ChainId.MANTLE), WMNT_EXTENDED[ChainId.MANTLE], USDT, USDC, RETRO],
  [ChainId.MANTLE]: [MANTLE.onChain(ChainId.MANTLE), WMNT_EXTENDED[ChainId.MANTLE], USDT, USDC],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR = {
  ...WBNB_ONLY,
  // [ChainId.MANTLE]: [...WBNB_ONLY[ChainId.MANTLE], RETRO, USDC, USDT],
  [ChainId.MANTLE]: [...WBNB_ONLY[ChainId.MANTLE], USDC, USDT],
}
export const PINNED_PAIRS = {
  [ChainId.MANTLE]: [[USDC, USDT]],
}
