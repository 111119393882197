import { ExternalLinkIcon } from 'icons/ExternalLinkIcon'
import { formatAmount } from 'utils/formatNumber'

export const PartnerItem = ({url, apr, disabled, description, active, logoUrl, onSelect }) => {
  const stats = [
    // { name: 'Total Value Locked', value: tvl ? `$${formatAmount(tvl, true)}` : '-' },
    { name: 'APR', value: apr && !disabled ? `${formatAmount(apr, true)}%` : '-' },
  ]

  return (
    <li className={`w-[50%] flex ${active && 'border-[3px] border-[#75FD3A] border-solid rounded-[13px]'} cursor-pointer`} onClick={onSelect} >
      <div className={`flex w-full flex-col gap-5 rounded-xl bg-white bg-opacity-[0.05] px-3 py-9`}>
        <div className={`flex flex-row w-full justify-between items-start items-center mb-4`}>
          <div className={'flex flex-row items-center justify-center'}>
            <div
              className={`${
                disabled && 'pointer-events-none'
              } w-[18px] h-[18px] flex flex-col items-center justify-center border border-[#B8B6CB] hover:border-[#2EF80D] rounded-full`}
            >
              <div className={`w-2 h-2 ${active ? 'bg-[#2EF80D]' : 'bg-transparent'}  rounded-full`} />
            </div>
            <div className='flex md:w-full justify-center items-center gap-x-2 ml-3'>
              <img className='w-full max-w-[100px] object-contain' alt='' src={logoUrl} />
            </div>
          </div>
          <div className='w-[30px] h-[30px] ml-2 flex items-center'>
            <a href={url} target='_blank' rel='noreferrer'>
              <ExternalLinkIcon />
            </a>
          </div>
        </div>
        {stats.map((stat) => {
          return (
            <div key={stat.name} className='w-full flex items-center justify-between gap-x-2'>
              <span className='text-[13px] text-white font-medium'>{stat.name}</span>
              <span className='text-[14px] text-white font-semibold'>{stat.value}</span>
            </div>
          )
        })}
        <div className='flex justify-center text-white'>{description}</div>
      </div>
    </li>
  )
}
