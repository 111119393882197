import { defaultChainId } from '../config/constants'
import addresses from '../config/constants/contracts'
import {
  FUSION_ROUTER_ADDRESSES,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
} from '../config/constants/v3/addresses'

export const getAddress = (address) => {
  return address[defaultChainId]
}

export const getMultiCallAddress = () => {
  return getAddress(addresses.multiCall)
}

export const getCRSTAddress = () => {
  return getAddress(addresses.CRUST)
}

export const getWMNTAddress = () => {
  return getAddress(addresses.WMNT)
}

export const getETHAddress = () => {
  return getAddress(addresses.ETH)
}

export const getRouterAddress = () => {
  return getAddress(addresses.router)
}

export const getVeCRSTAddress = () => {
  return getAddress(addresses.veCRST)
}

export const getVeDistAddress = () => {
  return getAddress(addresses.veDist)
}

export const getVoterAddress = () => {
  return getAddress(addresses.voter)
}

export const getMinterAddress = () => {
  return getAddress(addresses.minter)
}

export const getRewardsAPIAddress = () => {
  return getAddress(addresses.rewardsAPI)
}

export const getVeTHEAPIAddress = () => {
  return getAddress(addresses.veNFTAPI)
}

export const getPairV3APIAddress = () => {
  return getAddress(addresses.pairV3API)
}

export const getRewardsV3APIAddress = () => {
  return getAddress(addresses.rewardsV3API)
}

export const getVeTHEV3APIAddress = () => {
  return getAddress(addresses.veNFTV3API)
}

export const getAlgebraAddress = () => {
  return getAddress(NONFUNGIBLE_POSITION_MANAGER_ADDRESSES)
}

export const getV3VoterAddress = () => {
  return getAddress(addresses.v3voter)
}

export const getFusionRouterAddress = () => {
  return getAddress(FUSION_ROUTER_ADDRESSES)
}

export const getOptionAddress = () => {
  return getAddress(addresses.option)
}

export const getBribeOptionAddress = () => {
  return getAddress(addresses.bribeOption)
}