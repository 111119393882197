import { api } from './slice'

export const PricesDocument = `
    query prices($block24: Int!, $block48: Int!, $blockWeek: Int!) {
  current: bundles(first: 1, subgraphError: allow) {
    maticPriceUSD
  }
  oneDay: bundles(first: 1, block: {number: $block24}, subgraphError: allow) {
    maticPriceUSD
  }
  twoDay: bundles(first: 1, block: {number: $block48}, subgraphError: allow) {
    maticPriceUSD
  }
  oneWeek: bundles(first: 1, block: {number: $blockWeek}, subgraphError: allow) {
    maticPriceUSD
  }
}
    `
export const AllV3TicksDocument = `
    query allV3Ticks($poolAddress: String!, $skip: Int!) {
  ticks(
    first: 1000
    skip: $skip
    where: {poolAddress: $poolAddress}
    orderBy: tickIdx
  ) {
    tickIdx
    liquidityNet
    price0
    price1
  }
}
    `
export const FeeTierDistributionDocument = `
    query feeTierDistribution($token0: String!, $token1: String!) {
  _meta {
    block {
      number
    }
  }
  asToken0: pools(
    orderBy: totalValueLockedToken0
    orderDirection: desc
    where: {token0: $token0, token1: $token1}
  ) {
    fee
    totalValueLockedToken0
    totalValueLockedToken1
  }
  asToken1: pools(
    orderBy: totalValueLockedToken0
    orderDirection: desc
    where: {token0: $token1, token1: $token0}
  ) {
    fee
    totalValueLockedToken0
    totalValueLockedToken1
  }
}
    `
export const LimitFarmDocument = `
    query limitFarm($time: BigInt) {
  limitFarmings(
    orderBy: createdAtTimestamp
    orderDirection: desc
    first: 1
    where: {startTime_gt: $time, isDetached: false}
  ) {
    startTime
    endTime
  }
}
    `
export const EternalFarmDocument = `
    query eternalFarm {
  eternalFarmings(where: {isDetached: false}, first: 1) {
    startTime
    endTime
  }
}
    `
export const FetchRewardsDocument = `
    query fetchRewards($account: Bytes) {
  rewards(orderBy: amount, orderDirection: desc, where: {owner: $account}) {
    id
    rewardAddress
    amount
    owner
  }
}
    `
export const FetchTokenDocument = `
    query fetchToken($tokenId: ID) {
  tokens(where: {id: $tokenId}) {
    id
    symbol
    name
    decimals
  }
}
    `
export const FetchLimitDocument = `
    query fetchLimit($limitFarmingId: ID) {
  limitFarmings(where: {id: $limitFarmingId}) {
    id
    rewardToken
    bonusRewardToken
    pool
    startTime
    endTime
    reward
    bonusReward
    multiplierToken
    createdAtTimestamp
    tier1Multiplier
    tier2Multiplier
    tier3Multiplier
    tokenAmountForTier1
    tokenAmountForTier2
    tokenAmountForTier3
    enterStartTime
    isDetached
    minRangeLength
  }
}
    `
export const FetchEternalFarmDocument = `
    query fetchEternalFarm($farmId: ID) {
  eternalFarmings(where: {id: $farmId}) {
    id
    rewardToken
    bonusRewardToken
    pool
    startTime
    endTime
    reward
    bonusReward
    rewardRate
    bonusRewardRate
    isDetached
    tier1Multiplier
    tier2Multiplier
    tier3Multiplier
    tokenAmountForTier1
    tokenAmountForTier2
    tokenAmountForTier3
    multiplierToken
    minRangeLength
  }
}
    `
export const FetchPoolDocument = `
    query fetchPool($poolId: ID) {
  pools(where: {id: $poolId}) {
    id
    fee
    token0 {
      id
      decimals
      symbol
    }
    token1 {
      id
      decimals
      symbol
    }
    sqrtPrice
    liquidity
    tick
    feesUSD
    untrackedFeesUSD
  }
}
    `
export const FeeHourDataDocument = `
    query feeHourData($pool: String, $startTimestamp: BigInt, $endTimestamp: BigInt) {
  feeHourDatas(
    first: 1000
    where: {pool: $pool, timestamp_gte: $startTimestamp, timestamp_lte: $endTimestamp}
  ) {
    id
    pool
    fee
    changesCount
    timestamp
    minFee
    maxFee
    startFee
    endFee
  }
}
    `
export const LastFeeHourDataDocument = `
    query lastFeeHourData($pool: String) {
  feeHourDatas(
    first: 1
    orderBy: timestamp
    orderDirection: desc
    where: {pool: $pool}
  ) {
    id
    pool
    fee
    changesCount
    timestamp
    minFee
    maxFee
    startFee
    endFee
  }
}
    `
export const LastNotEmptyHourDataDocument = `
    query lastNotEmptyHourData($pool: String, $timestamp: BigInt) {
  feeHourDatas(
    first: 1
    orderBy: timestamp
    orderDirection: desc
    where: {pool: $pool, timestamp_lt: $timestamp}
  ) {
    id
    pool
    fee
    changesCount
    timestamp
    minFee
    maxFee
    startFee
    endFee
  }
}
    `
export const LastNotEmptyPoolHourDataDocument = `
    query lastNotEmptyPoolHourData($pool: String, $timestamp: Int) {
  poolHourDatas(
    first: 1
    orderBy: periodStartUnix
    orderDirection: desc
    where: {pool: $pool, periodStartUnix_lt: $timestamp}
  ) {
    periodStartUnix
    volumeUSD
    tvlUSD
    feesUSD
    untrackedVolumeUSD
    token1Price
    token0Price
  }
}
    `
export const LastPoolHourDataDocument = `
    query lastPoolHourData($pool: String) {
  poolHourDatas(
    first: 1
    where: {pool: $pool}
    orderBy: periodStartUnix
    orderDirection: desc
  ) {
    periodStartUnix
    volumeUSD
    tvlUSD
    feesUSD
    untrackedVolumeUSD
  }
}
    `
export const PoolHourDataDocument = `
    query poolHourData($pool: String, $startTimestamp: Int, $endTimestamp: Int) {
  poolHourDatas(
    first: 1000
    where: {pool: $pool, periodStartUnix_gte: $startTimestamp, periodStartUnix_lte: $endTimestamp}
    orderBy: periodStartUnix
    orderDirection: asc
    subgraphError: allow
  ) {
    periodStartUnix
    volumeUSD
    tvlUSD
    feesUSD
    untrackedVolumeUSD
    token0Price
    token1Price
  }
}
    `
export const LastEventDocument = `
    query lastEvent {
  limitFarmings(
    first: 1
    orderDirection: desc
    orderBy: createdAtTimestamp
    where: {isDetached: false}
  ) {
    createdAtTimestamp
    id
    startTime
    endTime
  }
}
    `
export const FutureEventsDocument = `
    query futureEvents($timestamp: BigInt) {
  limitFarmings(
    orderBy: startTime
    orderDirection: asc
    where: {startTime_gt: $timestamp, isDetached: false}
  ) {
    id
    createdAtTimestamp
    rewardToken
    bonusReward
    bonusRewardToken
    pool
    startTime
    endTime
    reward
    tier1Multiplier
    tier2Multiplier
    tier3Multiplier
    tokenAmountForTier1
    tokenAmountForTier2
    tokenAmountForTier3
    multiplierToken
    enterStartTime
    isDetached
    minRangeLength
  }
}
    `
export const CurrentEventsDocument = `
    query currentEvents($startTime: BigInt, $endTime: BigInt) {
  limitFarmings(
    orderBy: endTime
    orderDirection: desc
    where: {startTime_lte: $startTime, endTime_gt: $endTime, isDetached: false}
  ) {
    id
    rewardToken
    bonusReward
    bonusRewardToken
    pool
    startTime
    endTime
    reward
    tier1Multiplier
    tier2Multiplier
    tier3Multiplier
    tokenAmountForTier1
    tokenAmountForTier2
    tokenAmountForTier3
    enterStartTime
    multiplierToken
    isDetached
    minRangeLength
  }
}
    `
export const FrozenStakedDocument = `
    query frozenStaked($account: String, $timestamp: Int) {
  stakeTxes(
    where: {owner: $account, timestamp_gte: $timestamp}
    orderBy: timestamp
    orderDirection: asc
  ) {
    timestamp
    stakedALGBAmount
    xALGBAmount
  }
}
    `
export const TransferedPositionsDocument = `
    query transferedPositions($account: Bytes) {
  deposits(
    orderBy: id
    orderDirection: desc
    where: {owner: $account, onFarmingCenter: true}
  ) {
    id
    owner
    pool
    L2tokenId
    limitFarming
    eternalFarming
    onFarmingCenter
    rangeLength
  }
}
    `
export const HasTransferedPositionsDocument = `
    query hasTransferedPositions($account: Bytes) {
  deposits(first: 1, where: {owner: $account, onFarmingCenter: true}) {
    id
  }
}
    `
export const PositionsOnEternalFarmingDocument = `
    query positionsOnEternalFarming($account: Bytes) {
  deposits(
    orderBy: id
    orderDirection: desc
    where: {owner: $account, onFarmingCenter: true, eternalFarming_not: null}
  ) {
    id
    owner
    pool
    L2tokenId
    eternalFarming
    onFarmingCenter
    enteredInEternalFarming
  }
}
    `
export const TransferedPositionsForPoolDocument = `
    query transferedPositionsForPool($account: Bytes, $pool: Bytes, $minRangeLength: BigInt) {
  deposits(
    orderBy: id
    orderDirection: desc
    where: {owner: $account, pool: $pool, liquidity_not: "0", rangeLength_gte: $minRangeLength}
  ) {
    id
    owner
    pool
    L2tokenId
    limitFarming
    eternalFarming
    onFarmingCenter
    enteredInEternalFarming
    tokensLockedLimit
    tokensLockedEternal
    tierLimit
    tierEternal
  }
}
    `
export const PositionsOnFarmingDocument = `
    query positionsOnFarming($account: Bytes, $pool: Bytes) {
  deposits(
    orderBy: id
    orderDirection: desc
    where: {owner: $account, pool: $pool, onFarmingCenter: true}
  ) {
    id
  }
}
    `
export const InfiniteFarmsDocument = `
    query infiniteFarms {
  eternalFarmings(where: {isDetached: false}) {
    id
    rewardToken
    bonusRewardToken
    pool
    startTime
    endTime
    reward
    bonusReward
    rewardRate
    bonusRewardRate
    tokenAmountForTier1
    tokenAmountForTier2
    tokenAmountForTier3
    tier1Multiplier
    tier2Multiplier
    tier3Multiplier
    multiplierToken
    minRangeLength
  }
}
    `
export const TopPoolsDocument = `
    query topPools {
  pools(
    first: 50
    orderBy: totalValueLockedUSD
    orderDirection: desc
    subgraphError: allow
  ) {
    id
  }
}
    `
export const TopTokensDocument = `
    query topTokens {
  tokens(
    first: 50
    orderBy: totalValueLockedUSD
    orderDirection: desc
    subgraphError: allow
  ) {
    id
  }
}
    `
export const StakeHistoryDocument = `
    query stakeHistory($id: ID) {
  factories {
    currentStakedAmount
    earnedForAllTime
    ALGBbalance
    xALGBtotalSupply
  }
  stakes(where: {id: $id}) {
    stakedALGBAmount
    xALGBAmount
  }
}
    `
export const StakeDocument = `
    query stake {
  histories(first: 1000, where: {date_gte: 1642626000}) {
    date
    currentStakedAmount
    ALGBbalance
    xALGBminted
    xALGBburned
    xALGBtotalSupply
    ALGBfromVault
  }
}
    `
export const SurroundingTicksDocument = `
    query surroundingTicks($poolAddress: String!, $tickIdxLowerBound: BigInt!, $tickIdxUpperBound: BigInt!, $skip: Int!) {
  ticks(
    subgraphError: allow
    first: 1000
    skip: $skip
    where: {poolAddress: $poolAddress, tickIdx_lte: $tickIdxUpperBound, tickIdx_gte: $tickIdxLowerBound}
  ) {
    tickIdx
    liquidityGross
    liquidityNet
    price0
    price1
  }
}
    `
export const PopularPoolsDocument = `
    query popularPools {
  pools(orderBy: totalValueLockedUSD, orderDirection: desc, first: 6) {
    token0 {
      id
    }
    token1 {
      id
    }
  }
}
    `

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    prices: build.query({
      query: (variables) => ({ document: PricesDocument, variables }),
    }),
    allV3Ticks: build.query({
      query: (variables) => ({ document: AllV3TicksDocument, variables }),
    }),
    feeTierDistribution: build.query({
      query: (variables) => ({
        document: FeeTierDistributionDocument,
        variables,
      }),
    }),
    limitFarm: build.query({
      query: (variables) => ({ document: LimitFarmDocument, variables }),
    }),
    eternalFarm: build.query({
      query: (variables) => ({ document: EternalFarmDocument, variables }),
    }),
    fetchRewards: build.query({
      query: (variables) => ({ document: FetchRewardsDocument, variables }),
    }),
    fetchToken: build.query({
      query: (variables) => ({ document: FetchTokenDocument, variables }),
    }),
    fetchLimit: build.query({
      query: (variables) => ({ document: FetchLimitDocument, variables }),
    }),
    fetchEternalFarm: build.query({
      query: (variables) => ({ document: FetchEternalFarmDocument, variables }),
    }),
    fetchPool: build.query({
      query: (variables) => ({ document: FetchPoolDocument, variables }),
    }),
    feeHourData: build.query({
      query: (variables) => ({ document: FeeHourDataDocument, variables }),
    }),
    lastFeeHourData: build.query({
      query: (variables) => ({ document: LastFeeHourDataDocument, variables }),
    }),
    lastNotEmptyHourData: build.query({
      query: (variables) => ({
        document: LastNotEmptyHourDataDocument,
        variables,
      }),
    }),
    lastNotEmptyPoolHourData: build.query({
      query: (variables) => ({
        document: LastNotEmptyPoolHourDataDocument,
        variables,
      }),
    }),
    lastPoolHourData: build.query({
      query: (variables) => ({ document: LastPoolHourDataDocument, variables }),
    }),
    poolHourData: build.query({
      query: (variables) => ({ document: PoolHourDataDocument, variables }),
    }),
    lastEvent: build.query({
      query: (variables) => ({ document: LastEventDocument, variables }),
    }),
    futureEvents: build.query({
      query: (variables) => ({ document: FutureEventsDocument, variables }),
    }),
    currentEvents: build.query({
      query: (variables) => ({ document: CurrentEventsDocument, variables }),
    }),
    frozenStaked: build.query({
      query: (variables) => ({ document: FrozenStakedDocument, variables }),
    }),
    transferedPositions: build.query({
      query: (variables) => ({
        document: TransferedPositionsDocument,
        variables,
      }),
    }),
    hasTransferedPositions: build.query({
      query: (variables) => ({
        document: HasTransferedPositionsDocument,
        variables,
      }),
    }),
    positionsOnEternalFarming: build.query({
      query: (variables) => ({
        document: PositionsOnEternalFarmingDocument,
        variables,
      }),
    }),
    transferedPositionsForPool: build.query({
      query: (variables) => ({
        document: TransferedPositionsForPoolDocument,
        variables,
      }),
    }),
    positionsOnFarming: build.query({
      query: (variables) => ({
        document: PositionsOnFarmingDocument,
        variables,
      }),
    }),
    infiniteFarms: build.query({
      query: (variables) => ({ document: InfiniteFarmsDocument, variables }),
    }),
    topPools: build.query({
      query: (variables) => ({ document: TopPoolsDocument, variables }),
    }),
    topTokens: build.query({
      query: (variables) => ({ document: TopTokensDocument, variables }),
    }),
    stakeHistory: build.query({
      query: (variables) => ({ document: StakeHistoryDocument, variables }),
    }),
    stake: build.query({
      query: (variables) => ({ document: StakeDocument, variables }),
    }),
    surroundingTicks: build.query({
      query: (variables) => ({ document: SurroundingTicksDocument, variables }),
    }),
    popularPools: build.query({
      query: (variables) => ({ document: PopularPoolsDocument, variables }),
    }),
  }),
})

export { injectedRtkApi as api }
