import web3NoAccount from './web3'
import {
  getAlgebraAddress,
  getMinterAddress,
  getMultiCallAddress,
  getRewardsAPIAddress,
  getRouterAddress,
  getV3VoterAddress,
  getVeDistAddress,
  getVeCRSTAddress,
  getVeTHEV3APIAddress,
  getVoterAddress,
  getWMNTAddress,
  getOptionAddress,
  getBribeOptionAddress,
} from './addressHelpers'
import {
  ERC20Abi,
  gaugeAbi,
  multiCallAbi,
  routerAbi,
  veDistAbi,
  veTHEAbi,
  voterAbi,
  bribeAbi,
  minterAbi,
  pairAbi,
  rewardAPIAbi,
  wbnbAbi,
  veTheV3ApiAbi,
  v3voterAbi,
  extraRewarderAbi,
  optionAbi,
  ichiVaultAbi,
  bribeOptionAbi,
  steerVaultAbi
} from '../config/abi'
import { algebraAbi } from '../config/abi/v3'
import {contracts} from "@steerprotocol/contracts/deployments/mantle.json";

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount
  return new _web3.eth.Contract(abi, address)
}

export const getIchiVaultContract = (web3, address) => {
  return getContract(ichiVaultAbi, address, web3)
}

export const getERC20Contract = (web3, address) => {
  return getContract(ERC20Abi, address, web3)
}

export const getWMNTContract = (web3) => {
  return getContract(wbnbAbi, getWMNTAddress(), web3)
}

export const getMulticallContract = (web3) => {
  return getContract(multiCallAbi, getMultiCallAddress(), web3)
}
export const getRouterContract = (web3) => {
  return getContract(routerAbi, getRouterAddress(), web3)
}

export const getVeCRSTContract = (web3) => {
  return getContract(veTHEAbi, getVeCRSTAddress(), web3)
}

export const getVeDistContract = (web3) => {
  return getContract(veDistAbi, getVeDistAddress(), web3)
}

export const getVoterContract = (web3) => {
  return getContract(voterAbi, getVoterAddress(), web3)
}

export const getMinterContract = (web3) => {
  return getContract(minterAbi, getMinterAddress(), web3)
}
export const getRewardsAPIContract = (web3) => {
  return getContract(rewardAPIAbi, getRewardsAPIAddress(), web3)
}
export const getVeTHEV3APIContract = (web3) => {
  return getContract(veTheV3ApiAbi, getVeTHEV3APIAddress(), web3)
}
export const getGaugeContract = (web3, address) => {
  return getContract(gaugeAbi, address, web3)
}

export const getBribeContract = (web3, address) => {
  return getContract(bribeAbi, address, web3)
}

export const getPairContract = (web3, address) => {
  return getContract(pairAbi, address, web3)
}

export const getAlgebraContract = (web3) => {
  return getContract(algebraAbi, getAlgebraAddress(), web3)
}

export const getV3VoterContract = (web3) => {
  return getContract(v3voterAbi, getV3VoterAddress(), web3)
}

export const getExtraRewarder = (web3) => {
  return getContract(extraRewarderAbi, '0xA7266B2303725F731851dfE944a432f8A2EA5c9c', web3)
}

export const getOptionContract = (web3) => {
  return getContract(optionAbi, getOptionAddress(), web3)
}

export const getBribeOptionContract = (web3) => {
  return getContract(bribeOptionAbi, getBribeOptionAddress(), web3)
}

export const getSteerPeriphery = (web3) => {
  return getContract(contracts.SteerPeriphery.abi, contracts.SteerPeriphery.address, web3)
}

export const getSteerVault = (web3, address) => {
  return getContract(steerVaultAbi, address, web3)
}

