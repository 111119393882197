import { CurrencyAmount, Price } from '@crust_finance/sdk-core'
import { useMemo } from 'react'
import { USDC as USDC_V3_TOKEN } from '../../config/constants/v3/routing'
//import { useBestV3TradeExactOut } from './useBestV3Trade'
import { useTokenPrice } from '../usePrices'
import { BigNumber } from 'bignumber.js'

// Stablecoin amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.

// Two different consts used as a hack for allLiquidity flag in useUSDCPrice fn.
// Doing another way makes amounts in EnterAmount stuck somehow.
const STABLECOIN_AMOUNT_OUT_ALL = CurrencyAmount.fromRawAmount(USDC_V3_TOKEN, 1)
const STABLECOIN_AMOUNT_OUT_FILTERED = CurrencyAmount.fromRawAmount(USDC_V3_TOKEN, 100_000e1)

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useUSDCPrice(currency, allLiquidity) {
  const amountOut = allLiquidity ? STABLECOIN_AMOUNT_OUT_ALL : STABLECOIN_AMOUNT_OUT_FILTERED
  const stablecoin = amountOut?.currency

  const v3USDCTrade = useTokenPrice(currency?.wrapped.address)

  return useMemo(() => {
    if (!currency || !stablecoin) {
      return undefined
    }

    // handle usdc
    if (currency?.wrapped.equals(stablecoin)) {
      return new Price(stablecoin, stablecoin, '1', '1')
    }

    if (currency?.wrapped.symbol === 'USDT') {
      return new Price(currency, stablecoin, '1', '1')
    }

    if (v3USDCTrade) {
      const adjustingDecimal = BigNumber(currency.decimals - stablecoin.decimals);
      const prices = BigNumber(v3USDCTrade).div(BigNumber(10).pow(adjustingDecimal)).toFraction()
      return new Price(currency, stablecoin, prices[1], prices[0])
    }

    return undefined
  }, [currency, stablecoin, v3USDCTrade.trade])
}

export function useUSDCValue(currencyAmount, allLiquidity = false) {
  const price = useUSDCPrice(currencyAmount?.currency, allLiquidity)

  return useMemo(() => {
    if (!price || !currencyAmount) return null
    try {
      return price.quote(currencyAmount)
    } catch (error) {
      return null
    }
  }, [currencyAmount, price])
}
