export const setupNetwork = async (chainId) => {
  const provider = window.ethereum

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: 'Mantle',
            nativeCurrency: {
              name: 'Mantle',
              symbol: 'MNT',
              decimals: 18,
            },
            rpcUrls: ['https://rpc.mantle.xyz/'],
            blockExplorerUrls: ['https://explorer.mantle.xyz/'],
          },
        ],
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error("Can't setup network")
    return false
  }
}
