import SwapIcon from '../../../icons/SwapIcon'
import LiquidityIcon from '../../../icons/LiquidityIcon'
// import LockIcon from '../../../icons/LockIcon'
// import VoteIcon from '../../../icons/VoteIcon'
// import RewardIcon from '../../../icons/RewardIcon'
// import { ORetroIcon } from 'icons/ORetroIcon'

const navLinks = [
  {
    name: 'SWAP',
    link: '/swap',
    icon: <SwapIcon />,
  },
  {
    name: 'LIQUIDITY',
    icon: <LiquidityIcon />,
    link: '/liquidity',
  },
  // {
  //   name: 'LOCK',
  //   link: '/lock',
  //   icon: <LockIcon />,
  // },
  // {
  //   name: 'VOTE',
  //   link: '/vote',
  //   icon: <VoteIcon />,
  // },
  // {
  //   name: 'REWARDS',
  //   link: '/rewards',
  //   icon: <RewardIcon />,
  // },
  // {
  //   name: 'oRETRO',
  //   link: '/options',
  //   icon: <ORetroIcon />,
  // },
  // {
  //   name: 'BRIBES',
  //   link: '/bribes',
  // },
  // {
  //   dropdown: true,
  //   name: 'MORE',
  //   link: [
      // {
      //   name: 'Dashboard',
      //   link: '/dashboard',
      // },
      // {
      //   name: 'Docs',
      //   link: 'https://docs.retro.finance/',
      //   external: true,
      // },
  //   ],
  //   external: true,
  // },
]

export default navLinks
