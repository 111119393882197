import { Distributor__factory, registry } from '@angleprotocol/sdk'
import { useWeb3React } from '@web3-react/core'
import { ChainId } from 'config/constants'
import { ethers } from 'ethers'
import { useGetMerklDataQuery } from 'hooks/queries/useGetMerklDataQuery'
import { useState } from 'react'

export const useMerklClaimAll = () => {
  const { account } = useWeb3React()
  const { merklDataQuery } = useGetMerklDataQuery()
  const [isLoading, setIsLoading] = useState()

  const data = merklDataQuery.data?.transactionData

  const claimAll = async () => {
    setIsLoading(true)

    const provider = new ethers.providers.Web3Provider(window.ethereum)

    const signer = provider.getSigner()

    if (!data) return

    const tokens = Object.keys(data ?? {}).filter((k) => data?.[k]?.proof !== undefined)
    const claims = tokens.map((t) => data?.[t]?.claim)
    const proofs = tokens.map((t) => data?.[t]?.proof)

    const contractAddress = registry(ChainId.MANTLE)?.Merkl?.Distributor
    try {
      const contract = Distributor__factory.connect(contractAddress, signer)

      const tx = await contract.claim(
        tokens.map(() => account),
        tokens,
        claims,
        proofs,
      )

      await tx.wait()

      setIsLoading(false)
      await merklDataQuery.refetch()
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  return { claimAll, isLoading }
}
