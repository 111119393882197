export const tokenList = [
    {
        "name": "Pre Launch CRUST Token",
        "symbol": "preCRST",
        "decimals": 18,
        "address": "0x6965a4f636bb07dd8bed9174e885a0f7e7879308",
        "logoURI": "/images/tokens/crust-white.png"
    },
    {
        "name": "USD Coin",
        "symbol": "USDC",
        "decimals": 6,
        "address": "0x09bc4e0d864854c6afb6eb9a9cdf58ac190d0df9",
        "logoURI": "/images/tokens/USDC.png"
    },
    {
        "name": "Tether USD",
        "symbol": "USDT",
        "decimals": 6,
        "address": "0x201eba5cc46d216ce6dc03f6a759e8e766e956ae",
        "logoURI": "/images/tokens/USDT.png"
    },
    {
        "name": "Wrapped Ether",
        "symbol": "wETH",
        "decimals": 18,
        "address": "0xdeaddeaddeaddeaddeaddeaddeaddeaddead1111",
        "logoURI": "/images/tokens/WETH.png"
    },
    {
        "name": "Wrapped Mantle",
        "symbol": "wMNT",
        "decimals": 18,
        "address": "0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8",
        "logoURI": "/images/tokens/mantle.png"
    }
]
