import React from 'react'

const ChevronIcon = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.51642 9.85246C5.81861 9.53012 6.32488 9.5138 6.6472 9.81598L12.5001 15.303L18.3529 9.81598C18.6753 9.5138 19.1815 9.53012 19.4837 9.85246C19.7858 10.1748 19.7695 10.6811 19.4473 10.9832L13.0472 16.9833C12.7395 17.2718 12.2606 17.2718 11.9529 16.9833L5.5529 10.9832C5.23056 10.6811 5.21424 10.1748 5.51642 9.85246Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ChevronIcon
