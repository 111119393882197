export const ichiVaults = [
  // wmnt USDC vaults
  {
    depositTokenSymbol: 'USDC',
    feeTier: 0.3,
    token0: 'USDC',
    token1: 'wMNT',
    poolAddress: '0x681966b68b3fbec92078ec56614f0b8b7f4fd17a',
    vaultAddress: '0x641C457096EDa755f1B9806570CAa40D2D6bDBD8',
  },
  {
    depositTokenSymbol: 'wMNT',
    feeTier: 0.3,
    token0: 'USDC',
    token1: 'wMNT',
    poolAddress: '0x681966b68b3fbec92078ec56614f0b8b7f4fd17a',
    vaultAddress: '0x53ACD9E3eAcA8ECD8972084Ee49D1e2AB85545ae',
  },

  // wmnt USDT vaults
  {
    depositTokenSymbol: 'wMNT',
    feeTier: 0.3,
    token0: 'USDT',
    token1: 'wMNT',
    poolAddress: '0x1bae52e2b8e401de1429b7ca94bb0abbf133ae34',
    vaultAddress: '0xEfA2919CBf037144f0B27C096C570d57e84996a3',
  },
  {
    depositTokenSymbol: 'USDT',
    feeTier: 0.3,
    token0: 'USDT',
    token1: 'wMNT',
    poolAddress: '0x1bae52e2b8e401de1429b7ca94bb0abbf133ae34',
    vaultAddress: '0x55DC46E0b220c18000Af0Bd55fF93eD600277408',
  },

  // weth USDC vaults
  {
    depositTokenSymbol: 'wETH',
    feeTier: 0.3,
    token0: 'USDC',
    token1: 'wETH',
    poolAddress: '0x436486fbcd73c30ca20eaae330c445106451cadd',
    vaultAddress: '0x2F0A62c0b4A066863BeFC15e064102f4898239eA',
  },
  {
    depositTokenSymbol: 'USDC',
    feeTier: 0.3,
    token0: 'USDC',
    token1: 'wETH',
    poolAddress: '0x436486fbcd73c30ca20eaae330c445106451cadd',
    vaultAddress: '0xea75A48dCFbfB192368AAB333de14961AE9840d7',
  },

  // weth USDT vaults
  {
    depositTokenSymbol: 'wETH',
    feeTier: 0.3,
    token0: 'USDT',
    token1: 'wETH',
    poolAddress: '0x99c0afe89bedc36101691ac055f2cc7eb38b278e',
    vaultAddress: '0xBe27Ac0CD258d8126eF2bd63342c5203492020C6',
  },
  {
    depositTokenSymbol: 'USDT',
    feeTier: 0.3,
    token0: 'USDT',
    token1: 'wETH',
    poolAddress: '0x99c0afe89bedc36101691ac055f2cc7eb38b278e',
    vaultAddress: '0xf5F4B8513ad344df0F8877E7cFCF1904008c03Db',
  },
]
