import React from 'react'
import Spinner from '../../../../../common/Spinner'
import { PartnerItem } from './PartnerItem'
import { v3LiquidityRangeType } from 'v3lib/entities/constants'
import { useGetAllLiquidityPools } from 'hooks/v3/useGetAllLiquidityPools'

const PartnersList = ({
  ichiAsset,
  loading,
  baseCurrency,
  quoteCurrency,
  selectRangeType,
  selectedLiquidityRangeType,
  isIchiVaultAvailable,
  isSteerVaultAvailable
}) => {
  const allPools = useGetAllLiquidityPools()

  const pool = allPools?.find(
    (pool) =>
      (pool?.token0?.symbol.toLowerCase() === baseCurrency?.symbol.toLowerCase() &&
        pool?.token1?.symbol.toLowerCase() === quoteCurrency?.symbol.toLowerCase()) ||
      (pool?.token1?.symbol.toLowerCase() === quoteCurrency?.symbol.toLowerCase() &&
        pool?.token0?.symbol.toLowerCase() === baseCurrency?.symbol.toLowerCase()),
  )

  const getStatsData = (alm) => {
    const aprKeys = Object.keys(pool?.fullMerklData?.aprs ?? {}).filter((key) => {
      const almKey = key?.split(' ')[0]?.toLowerCase()

      return almKey === alm?.toLowerCase()
    })

    const getApr = () => {
      switch (alm) {
        case 'ICHI': {
          const aprs = aprKeys.reduce((acc, aprKey) => {
            const [alm, vaultAddress] = aprKey?.split(' ')

            acc[vaultAddress] = { alm, vaultAddress, apr: pool?.fullMerklData?.aprs?.[aprKey] }

            return acc
          }, {})

          return aprs?.[ichiAsset?.vault?.vaultAddress?.toLowerCase() ?? '']?.apr
        }

        default:
          return pool?.fullMerklData?.aprs[aprKeys[0]]
      }
    }

    return { apr: getApr() }
  }

  if (loading)
    return (
      <div className='flex justify-center items-center'>
        <Spinner />
      </div>
    )


  return (
    <>
      {isIchiVaultAvailable || isSteerVaultAvailable
          ? (
              <>
                <p className='text-left w-full text-[20px] font-semibold text-white m-0 mb-9 mt-8'>
                  Select ALM Partner
                </p>
                <ul className='list-none m-0 p-0 flex flex-row gap-2.5'>
                  {isIchiVaultAvailable && <PartnerItem
                    description='Single Deposit Positions'
                    disabled={!isIchiVaultAvailable}
                    onSelect={() => selectRangeType(v3LiquidityRangeType.ICHI_RANGE)}
                    active={selectedLiquidityRangeType === v3LiquidityRangeType.ICHI_RANGE}
                    logoUrl='/images/liquidity/ichi.svg'
                    url='https://ichi.org/'
                    apr={getStatsData('ICHI').apr}
                    tvl={0}
                  />}
                  {isSteerVaultAvailable && <PartnerItem
                    description='Steer Managed Positions'
                    disabled={!isSteerVaultAvailable}
                    onSelect={() => selectRangeType(v3LiquidityRangeType.STEER)}
                    active={selectedLiquidityRangeType === v3LiquidityRangeType.STEER}
                    logoUrl='/images/liquidity/SteerLogoFullWhite.png'
                    url='https://app.steer.finance/'
                    apr={getStatsData('STEER').apr}
                    tvl={0}
                  />}
                </ul>
              </>
        )
      :
        (<p className='text-center w-full text-[20px] font-semibold text-white m-0 mb-9 mt-8'>
          No Automatic strategies available.
        </p>)
      }
    </>
  )
}

export default PartnersList
