import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import ConnectWallet from '../../connectWallet'
import useAuth from '../../../hooks/useAuth'
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics'
import OutsideClickHandler from 'react-outside-click-handler'
import { connectors, defaultChainId } from '../../../config/constants'
import { useSelector } from 'react-redux'
import useWalletModal from '../../../hooks/useWalletModal'
import usePrices from '../../../hooks/usePrices'
import { formatAmount } from '../../../utils/formatNumber'
import ChevronIcon from '../../../icons/ChevronIcon'
import LogoutIcon from '../../../icons/LogoutIcon'
import MobileNav from '../MobileNav'
import navLinks from "./NavLinks";
import Menu from "./Menu";

const Header = () => {
  useGoogleAnalytics()
  const [open, setOpen] = useState(false)
  const [connector, setConnector] = useState(null)
  const [secondDrop, setSecondDrop] = useState(false)
  const { account, chainId } = useWeb3React()
  const { logout } = useAuth()
  const [selected, setSelected] = useState(false)
  const prices = usePrices()

  useEffect(() => {
    if (prices && prices['CRST']) {
      document.title = `CRST - $${formatAmount(prices['CRST'])}`
    }
  }, [prices])
  const { isWalletOpen } = useSelector((state) => state.wallet)
  const { openWalletModal } = useWalletModal()

  useEffect(() => {
    if (account) {
      if (!connector && !selected) {
        setConnector(connectors[0])
        setSelected(false)
      }
    } else {
      setConnector(null)
    }
  }, [account, connector, selected, setSelected])

  const dropDownhandler = () => {
    if (connector && chainId === defaultChainId) {
      setSecondDrop(!secondDrop)
    } else {
      openWalletModal()
    }
  }

  const onDisconnect = () => {
    logout()
    setConnector(null)
    setSecondDrop(false)
  }

  return (
    <div className='w-full flex items-center relative z-[100] xl:z-[1000]'>
      <div className='relative z-[100] xl:z-[1000] w-full flex items-center justify-between gap-x-3 p-5 h-[88px] xl:h-24'>
        <div className='flex items-center w-[135px] h-[40px]'>
          <Link to='/'>
            <img className='logo relative z-10' alt='' src='/images/header/logo.svg' />
          </Link>
        </div>
        <div className={`relative hidden xl:flex flex-row flex-shrink-0 flex-grow overflow-x-hidden ml-1`}>
          <ul className='flex flex-row justify-evenly items-center list-style-none overflow-hidden'>
            {navLinks.map((item, idx) => {
              return <Menu item={item} key={`main-${idx}`} idx={idx} />
            })}
          </ul>
        </div>
        <div className='flex items-center'>
          <OutsideClickHandler
            onOutsideClick={() => {
              setSecondDrop(false)
            }}
          >
            <div className='relative w-full'>
              <button
                onClick={() => {
                  dropDownhandler()
                }}
                className={`hidden xl:block bg-white w-full min-w-[150px] max-w-[252px] xl:max-w-full xl:w-[252px] flex-shrink-0 py-2 px-4 xl:py-4 xl:px-10 rounded-[15px] items-center gap-x-1 xl:gap-x-2.5 font-semibold text-black text-sm md:text-sm relative z-20 xl:text-base leading-7`}
              >
                {connector && chainId === defaultChainId ? (
                  <div className='flex items-center gap-x-1 xl:gap-x-2.5'>
                    <div className='flex items-center flex-shrink-0 gap-x-1 xl:gap-x-2.5'>
                      <img
                        src={connector.title == 'MetaMask' ? '/images/header/metamask.svg' : connector.logo}
                        className='max-w-[24px] h-6 '
                        alt=''
                      />
                      <p className=''>{account ? `${account.slice(0, 6)}...${account.slice(-4)}` : ''}</p>
                    </div>
                    <span
                      className={`${
                        secondDrop ? ' rotate-180' : ' rotate-0'
                      } transition-all duration-300 ease-in-out transform w-full`}
                    >
                      <div className='w-6 h-6 flex-shrink-0'>
                        <ChevronIcon />
                      </div>
                    </span>
                  </div>
                ) : (
                  <span className='w-full'>Connect Wallet</span>
                )}
              </button>

              {secondDrop && (
                <div className='absolute w-full bg-grayDark rounded-[15px] top-[50px] xl:top-[70px] block z-[101]'>
                  <button
                    onClick={onDisconnect}
                    className='flex items-center gap-x-2.5 w-full py-[8px] xl:py-[15px] px-5 xl:px-10 text-white'
                  >
                    <span className='w-4 xl:w-6 h-4 xl:h-6'>
                      <LogoutIcon />
                    </span>
                    <p className='flex-shrink-0 text-sm xl:text-base text-white'>Logout</p>
                  </button>
                </div>
              )}
            </div>
          </OutsideClickHandler>
          <button
            onClick={() => {
              setOpen(true)
              setSecondDrop(false)
            }}
            className='bg-transparent w-8 xl:hidden'
          >
            <img alt='' src='/images/header/hamburger-menu.png' />
          </button>
        </div>
      </div>
      {isWalletOpen && <ConnectWallet setConnector={setConnector} setSelected={setSelected} />}

      <MobileNav isOpen={open} setIsOpen={setOpen} />
    </div>
  )
}

export default Header
