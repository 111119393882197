import { useContext, useEffect, useState } from 'react'
import { getETHAddress, getCRSTAddress, getWMNTAddress } from '../utils/addressHelpers'
import { BaseAssetsConetext } from '../context/BaseAssetsConetext'

const usePrices = () => {
  const [prices, setPrices] = useState({
    CRST: 0,
    MNT: 0,
    ETH: 0,
  })
  const baseAssets = useContext(BaseAssetsConetext)

  useEffect(() => {
    if (baseAssets.length > 0) {
      const crstAsset = baseAssets.find((asset) => asset.address.toLowerCase() === getCRSTAddress().toLowerCase())
      const mntAsset = baseAssets.find((asset) => asset.address.toLowerCase() === getWMNTAddress().toLowerCase())
      const ethAsset = baseAssets.find((asset) => asset.address.toLowerCase() === getETHAddress().toLowerCase())
      const bveCRST = baseAssets.find((asset) => asset.symbol === 'bveCRST')
      const oCRST = baseAssets.find((asset) => asset.symbol === 'oCRST')

      setPrices({
        CRUST: crstAsset ? crstAsset.price : 0,
        MNT: mntAsset ? mntAsset.price : 0,
        ETH: ethAsset ? ethAsset.price : 0,
        bveCRST: bveCRST ? bveCRST.price : 0,
        oCRST: oCRST ? oCRST.price : 0,
        preCRST: 0.08
      })
    }
  }, [baseAssets])

  return prices
}

export const useTokenPrice = (address) => {
  const [price, setPrice] = useState(0)
  const baseAssets = useContext(BaseAssetsConetext)

  useEffect(() => {
    if (baseAssets.length > 0 && address != undefined) {
      const asset = baseAssets.find((asset) => asset.address.toLowerCase() === address.toLowerCase())
      setPrice(asset ? asset.price : 0)
    }
  }, [baseAssets])

  return price
}

export default usePrices
