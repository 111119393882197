import { BigNumber, ethers } from 'ethers'
import { getAllowance, sendContract } from '../utils/api'
import { MAX_UINT256 } from '../utils/formatNumber'
import { useCallback, useEffect, useState } from 'react'
import { openTransaction, updateTransaction, completeTransaction } from 'state/transactions/actions'
import { useBribeOptionContract, useOptionContract } from './useContract'
import { TransactionType } from 'config/constants'
import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { v4 as uuidv4 } from 'uuid'
import { getERC20Contract } from 'utils/contractHelpers'
import useWeb3 from './useWeb3'
import { useTokenAllowance } from './useAllowance'
import Big from 'big.js'
import { useQuery } from '@tanstack/react-query'

export const fetchOptionTwap = async (optionContract, amount) => {
  const twap = await optionContract.methods.getTimeWeightedAveragePrice(amount).call()
  return ethers.BigNumber.from(twap)
}

export const useDiscount = () => {
  const optionContract = useOptionContract()
  const [discount, setDiscount] = useState()

  const fetchDiscount = async () => {
    return optionContract.methods.discount().call()
  }

  useEffect(() => {
    fetchDiscount().then((newDiscount) => setDiscount(newDiscount))
  }, [])

  return discount
}

export const useVeDiscount = () => {
  const optionContract = useOptionContract()
  const [discount, setDiscount] = useState()

  const fetchDiscount = async () => {
    return await optionContract.methods.veDiscount().call()
  }

  useEffect(() => {
    fetchDiscount().then((newDiscount) => setDiscount(newDiscount))
  }, [])

  return discount
}

export const useOption = () => {
  const optionToken = {
    symbol: 'oCRST',
    decimals: 18,
  }

  const underlyingToken = {
    symbol: 'CRST',
    decimals: 18,
  }

  const paymentToken = {
    name: 'USDC',
    address: '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
    symbol: 'USDC',
    decimals: 6,
  }

  const voteEscrowToken = {
    name: 'veCRST',
    decimals: 18,
  }

  const bribeOptionContract = useBribeOptionContract()
  const optionContract = useOptionContract()

  const twapQuery = useQuery(
    ['option-token-twap'],
    async () => {
      const amount = '1'

      try {
        const twap = await fetchOptionTwap(optionContract, ethers.utils.parseUnits(amount, paymentToken.decimals))

        return BigNumber.from(twap?.toString() ?? '0')
      } catch (error) {
        console.error(error)
      }
    },
    { enabled: Boolean(optionContract) },
  )

  const twap = twapQuery.data ?? Big(0)

  const [exercisePending, setExercisePending] = useState(false)
  const [exerciseVePending, setExerciseVePending] = useState(false)
  const fullLock = 63120000
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const allowance = useTokenAllowance(paymentToken.address, optionContract._address)

  const handleExercise = useCallback(async (amount) => {
    const key = uuidv4()
    const approveUuid = uuidv4()
    const exerciseUuid = uuidv4()

    //TODO: Use real numbers and not names
    setExercisePending(true)
    dispatch(
      openTransaction({
        key,
        title: `Exercise ${Big(amount)
          .div(10 ** optionToken.decimals)
          .toFixed(2)} ${optionToken.symbol} for ${underlyingToken.symbol}`,
        transactions: {
          [approveUuid]: {
            desc: `Approve payment token`,
            status: TransactionType.WAITING,
            hash: null,
          },
          [exerciseUuid]: {
            desc: `Exercise ${Big(amount)
              .div(10 ** optionToken.decimals)
              .toFixed(2)} ${optionToken.symbol}`,
            status: TransactionType.START,
            hash: null,
          },
        },
      }),
    )

    const cost = await fetchOptionTwap(optionContract, amount)

    const tokenContract = getERC20Contract(web3, paymentToken.address)
    const allowance = await getAllowance(tokenContract, optionContract._address, account)

    if (ethers.BigNumber.from(cost).gt(allowance)) {
      try {
        await sendContract(
          dispatch,
          key,
          approveUuid,
          tokenContract,
          'approve',
          [optionContract._address, MAX_UINT256],
          account,
        )
      } catch (err) {
        console.log('approve 0 error :>> ', err)
        setExercisePending(false)
        return
      }
    } else {
      dispatch(
        updateTransaction({
          key,
          uuid: approveUuid,
          status: TransactionType.SUCCESS,
        }),
      )
    }

    const recipient = account
    const maxPaymentAmount = ethers.constants.MaxUint256

    const func = 'exercise'
    const args = [amount, maxPaymentAmount, recipient]


    try {
      await sendContract(dispatch, key, exerciseUuid, optionContract, func, args, account)
    } catch (err) {
      console.log('exercise error :>> ', err)
      setExercisePending(false)
      return
    }

    dispatch(
      completeTransaction({
        key,
        final: 'Exercise Successful',
      }),
    )

    setExercisePending(false)
  })

  const handleExerciseVe = useCallback(async (amount, asset) => {
    const key = uuidv4()
    // const approveUuid = uuidv4()
    const exerciseUuid = uuidv4()

    setExerciseVePending(true)
    dispatch(
      openTransaction({
        key,
        title: `Exercise ${Big(amount)
          .div(10 ** voteEscrowToken.decimals)
          .toFixed(2)} ${optionToken.symbol} for ${voteEscrowToken.name}`,
        transactions: {
          // [approveUuid]: {
          //   desc: `Approve payment token`,
          //   status: TransactionType.WAITING,
          //   hash: null,
          // },
          [exerciseUuid]: {
            desc: `Exercise ${Big(amount)
              .div(10 ** voteEscrowToken.decimals)
              .toFixed(2)} ${voteEscrowToken.name}`,
            status: TransactionType.START,
            hash: null,
          },
        },
      }),
    )

    const recipient = account
    const maxPaymentAmount = ethers.constants.MaxUint256
    const deadline = ethers.constants.MaxUint256

    const func = 'exerciseVe'
    let args = []

    switch (asset) {
      case 'ocrst':
        args = [amount, maxPaymentAmount, recipient, deadline]
        try {
          await sendContract(dispatch, key, exerciseUuid, optionContract, func, args, account)
        } catch (err) {
          console.log('exercise error :>> ', err)
          setExerciseVePending(false)
          return
        }
        break
      case 'bvecrst':
        args = [amount, recipient, deadline]

        try {
          await sendContract(dispatch, key, exerciseUuid, bribeOptionContract, func, args, account)
        } catch (err) {
          console.log('exercise error :>> ', err)
          setExerciseVePending(false)
          return
        }
        break
    }
    dispatch(
      completeTransaction({
        key,
        final: 'Exercise Successful',
      }),
    )

    setExerciseVePending(false)
  })

  return {
    optionContract,
    paymentToken,
    exercisePending,
    exerciseVePending,
    handleExercise,
    handleExerciseVe,
    fullLock,
    allowance,
    twap,
  }
}
