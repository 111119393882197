import React from 'react'

const Index = ({ className, range, content }) => {
  return (
    <div className={`bg-white bg-opacity-[0.05] rounded-xl text-center pt-2 md:pt-3 pb-2 md:pb-4 flex flex-col items-center justify-center ${className}`}>
      <p className='text-white leading-[15px]  md:leading-4 text-xs md:text-sm'>{range} Price</p>
      {content}
    </div>
  )
}

export default Index
