import React, { useState, useEffect, useContext } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import useRefresh from '../hooks/useRefresh'
import usePrices from '../hooks/usePrices'
import { getFusions } from '../utils/api'
import { ZERO_VALUE } from '../utils/formatNumber'
import { BaseAssetsConetext } from './BaseAssetsConetext'
import { useGetMerklDataQuery } from 'hooks/queries/useGetMerklDataQuery'
import { getAddress } from 'ethers/lib/utils'
import { useDiscount } from 'hooks/useOption'

const FusionsContext = React.createContext([])

const FusionsContextProvider = ({ children }) => {
  const [fusions, setFusions] = useState([])
  const { fastRefresh } = useRefresh()
  const { account } = useWeb3React()
  const prices = usePrices()
  const assets = useContext(BaseAssetsConetext)
  const { merklDataQuery } = useGetMerklDataQuery()
  const discountValue = useDiscount()
  const getAllData = async () => {
        try {
            const { data: fusions } = await getFusions()
            if (fusions.length > 0) {
                let mntcrst = '' // mnt-crst
                let userInfos = []
                const totalWeight = fusions.reduce((sum, current) => {
                    return sum.plus(current.gauge.weight)
                }, new BigNumber(0))

                const userInfo = fusions
                    .map((fusion) => {
                        const merklPool = merklDataQuery.data?.pools?.[getAddress(fusion.underlyingPool)]

                        const apr = new BigNumber(merklPool?.meanAPR ?? 0)
                        const gaugeEarned = merklPool?.rewardsPerToken ?? {}
                        const userTvl = new BigNumber(merklPool?.userTVL ?? 0)
                        const totalTvl = new BigNumber(merklPool?.tvl ?? ZERO_VALUE)
                        const userTotalBalance0 = new BigNumber(merklPool?.userBalanceToken0 ?? 0)
                        const userTotalBalance1 = new BigNumber(merklPool?.userBalanceToken1 ?? 0)
                        const found = userInfos.find((item) => item.address.toLowerCase() === fusion.address.toLowerCase())
                        const asset0 = assets.find((ele) => ele.address.toLowerCase() === fusion.token0.address.toLowerCase())
                        const asset1 = assets.find((ele) => ele.address.toLowerCase() === fusion.token1.address.toLowerCase())
                        const token0 = {
                            address: asset0?.address || fusion.token0.address,
                            symbol: asset0?.symbol || 'UNKNOWN',
                            decimals: asset0?.decimals || 18,
                            logoURI: asset0?.logoURI || 'https://retro.finance/images/tokens/UKNOWN.png',
                            price: asset0?.price || 0,
                        }
                        const token1 = {
                            address: asset1?.address || fusion.token1.address,
                            symbol: asset1?.symbol || 'UNKNOWN',
                            decimals: asset1?.decimals || 18,
                            logoURI: asset1?.logoURI || 'https://retro.finance/images/tokens/UKNOWN.png',
                            price: asset1?.price || 0,
                        }

                        let user = {
                            lpBalance: ZERO_VALUE,
                            gaugeBalance: ZERO_VALUE,
                            gaugeEarned: null,
                            totalLp: ZERO_VALUE,
                            token0claimable: ZERO_VALUE,
                            token1claimable: ZERO_VALUE,
                            staked0: ZERO_VALUE,
                            staked1: ZERO_VALUE,
                            stakedUsd: ZERO_VALUE,
                            earnedUsd: null,
                            total0: ZERO_VALUE,
                            total1: ZERO_VALUE,
                            totalUsd: ZERO_VALUE,
                        }
                        let extraRewards = ZERO_VALUE

                        if (found) {
                            const earnedUsdValues = gaugeEarned
                                ? Object.entries(gaugeEarned).reduce((acc, [gaugeAddress, rewardData]) => {
                                    const price = prices[rewardData.symbol]

                                    if (price && rewardData?.unclaimed) {
                                        acc[gaugeAddress] = new BigNumber(price).times(rewardData.unclaimed)
                                    }

                                    return acc
                                }, {})
                                : {}

                            const earnedUsd = Object.values(earnedUsdValues).reduce(
                                (acc, earned) => earned.plus(acc),
                                new BigNumber(0),
                            )

                            user = {
                                ...found,
                                staked0: fusion.totalSupply
                                    ? found.gaugeBalance.times(fusion.token0.reserve).div(fusion.totalSupply)
                                    : ZERO_VALUE,
                                staked1: fusion.totalSupply
                                    ? found.gaugeBalance.times(fusion.token1.reserve).div(fusion.totalSupply)
                                    : ZERO_VALUE,
                                stakedUsd: found.gaugeBalance.times(lpPrice),
                                gaugeEarned,
                                earnedUsd,
                                total0: userTotalBalance0,
                                total1: userTotalBalance1,
                                lpBalance: found.totalLp,
                                totalUsd: new BigNumber(userTvl),
                                extraRewards,
                            }
                        }

                        const weightPercent = totalWeight.isZero()
                            ? new BigNumber(0)
                            : new BigNumber(fusion.gauge.weight).div(totalWeight).times(100)
                        const lpPrice = fusion.totalSupply > 0 ? totalTvl.div(fusion.totalSupply) : new BigNumber(0)
                        const gaugeTvl = lpPrice.times(fusion.gauge.totalSupply)

                        const bveBribes =
                            fusion.gauge?.bribes?.bribe?.filter(
                                (bribe) => bribe.symbol === 'bveZERO' || bribe.symbol === 'bveRETRO',
                            ) ?? null

                        let bribeUsd = new BigNumber(0)
                        let feeUsd = new BigNumber(0)
                        let bribeOnlyUsd = new BigNumber(0)
                        let bribeBveUsd = new BigNumber(0)

                        const poolBribes = {
                            fee: fusion.gauge?.bribes?.fee,
                            bribe:
                                fusion.gauge.bribes?.bribe?.filter(
                                    (bribe) => bribe.symbol !== 'bveZERO' && bribe.symbol !== 'bveRETRO',
                                ) ?? null,
                        }

                        if (poolBribes) {
                            if (bveBribes?.length > 0) {
                                bveBribes.forEach((ele) => {
                                    const found = assets.find((asset) => asset.address.toLowerCase() === ele.address.toLowerCase())

                                    bribeBveUsd = bribeBveUsd.plus(new BigNumber(ele.amount).times(found ? found.price : 0))
                                })
                            }

                            if (poolBribes.bribe) {
                                poolBribes.bribe.forEach((ele) => {
                                    const found = assets.find((asset) => asset.address.toLowerCase() === ele.address.toLowerCase())

                                    bribeUsd = bribeUsd.plus(new BigNumber(ele.amount).times(found ? found.price : 0))
                                    bribeOnlyUsd = bribeOnlyUsd.plus(new BigNumber(ele.amount).times(found ? found.price : 0))
                                })
                            }

                            if (poolBribes.fee) {
                                poolBribes.fee.forEach((ele) => {
                                    const found = assets.find((asset) => asset.address.toLowerCase() === ele.address.toLowerCase())

                                    bribeUsd = bribeUsd.plus(new BigNumber(ele.amount).times(found ? found.price : 0))

                                    feeUsd = feeUsd.plus(new BigNumber(ele.amount).times(found ? found.price : 0))
                                })
                            }
                        }

                        return {
                            ...fusion,
                            poolAddress: fusion.underlyingPool,
                            tvl: new BigNumber(fusion.tvl),
                            token0: {
                                ...token0,
                                reserve: new BigNumber(fusion.token0.reserve),
                            },
                            token1: {
                                ...token1,
                                reserve: new BigNumber(fusion.token1.reserve),
                            },
                            fee: fusion.feeLevel,
                            gauge: {
                                ...fusion.gauge,
                                bribes: poolBribes,
                                bveBribes,
                                bribeBveUsd,
                                tvl: gaugeTvl,
                                apr,
                                voteApr: new BigNumber(fusion.gauge.voteApr),
                                projectedApr: new BigNumber(fusion.gauge.projectedApr),
                                weight: new BigNumber(fusion.gauge.weight),
                                weightPercent,
                                bribeUsd,
                                feeUsd,
                                bribeOnlyUsd,
                                gaugeEarned,
                                pooled0: fusion.totalSupply
                                    ? new BigNumber(fusion.token0.reserve).times(fusion.gauge.totalSupply).div(fusion.totalSupply)
                                    : new BigNumber(0),
                                pooled1: fusion.totalSupply
                                    ? new BigNumber(fusion.token1.reserve).times(fusion.gauge.totalSupply).div(fusion.totalSupply)
                                    : new BigNumber(0),
                            },
                            account: user,
                            totalTvl,
                            fullMerklData: merklPool,
                        }
                    })
                    .sort((a, b) => {
                        return a.gauge.tvl.minus(b.gauge.tvl).times(-1).toNumber()
                    })
                    .sort(function (x, y) {
                        return x.address == mntcrst.toLowerCase() ? -1 : y.address == mntcrst ? 1 : 0
                    })
                setFusions(userInfo)
            }
        } catch (e) {
            console.error('user fusions fetched had error', e)
        }
    }
  useEffect(() => {
    getAllData()
  }, [account, assets, fastRefresh, discountValue])

  return <FusionsContext.Provider value={fusions}>{children}</FusionsContext.Provider>
}

export { FusionsContext, FusionsContextProvider }
