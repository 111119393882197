import MyAutoPools from "../../components/pages/liquidity/v3/MyAutoPools";
import MyManualPools from "../../components/pages/liquidity/v3/MyManualPools";
import React, {useState} from "react";
import TabFilter from "../../components/common/TabFilter";
import {TabSizes} from "../../config/constants";

const ManageLiquidityV3 = () => {
    const PoolTypes = {
        AUTOPOOLS: 'My Auto Pools',
        MANUALPOOLS: 'My Manual Pools',
    }
    const [filter, setFilter] = useState(PoolTypes.AUTOPOOLS)

    return(
        <div className='w-full flex justify-center flex-col max-w-[650px] px-6 pt-5 pb-10 xl:pt-20 md:pt-14 mx-auto'>
            <div className='w-full flex justify-center mb-4'>
                <TabFilter data={Object.values(PoolTypes)} filter={filter} setFilter={setFilter} size={TabSizes.LARGE}/>
            </div>
            {filter === PoolTypes.AUTOPOOLS && <MyAutoPools />}
            {filter === PoolTypes.MANUALPOOLS && <MyManualPools />}
        </div>
    )
}

export default ManageLiquidityV3
