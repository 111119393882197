import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useWeb3React} from '@web3-react/core'
import {v4 as uuidv4} from 'uuid'
import {TransactionType} from '../../config/constants'
import useWeb3 from '../useWeb3'
import {completeTransaction, openTransaction} from '../../state/transactions/actions'
import {sendContract} from "../../utils/api";
import {BigNumber} from "ethers";
import {useSteerVaultContract} from "../useContract";

export const useSteerRemove = (vaultAddress) => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const steerVault = useSteerVaultContract(vaultAddress);

  const web3 = useWeb3()

  const handleSteerRemove = useCallback(
    async (amount, strategy) => {
      const key = uuidv4()
      const removeuuid = uuidv4()
      const lpInputRemoved = amount;

      setPending(true)

      dispatch(
        openTransaction({
          key,
          title: `Withdraw ${strategy.title} liquidity`,
          transactions: {
            [removeuuid]: {
              desc: `Withdraw tokens from the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )
      try {
          await sendContract(
              dispatch,
              key,
              removeuuid,
              steerVault,
              'withdraw',
              [
                  lpInputRemoved,
                  BigNumber.from(0), //Min amount 0
                  BigNumber.from(0), //Min amount 1
                  account],
              account,
          )
      } catch (err) {
        console.log('remove error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Removed',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { handleSteerRemove: handleSteerRemove, pending }
}
