import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { v4 as uuidv4 } from 'uuid'
import { routerAbi } from '../config/abi'
import { PairsContext } from '../context/PairsContext'
import { routeAssets, TaxAssets, TransactionType } from '../config/constants'
import { getRouterAddress } from '../utils/addressHelpers'
import { formatAmount, fromWei, isInvalidAmount, MAX_UINT256, toWei } from '../utils/formatNumber'
import { multicall } from '../utils/multicall'
import { getAllowance, sendContract } from '../utils/api'
import { useRouter } from './useContract'
import useWeb3 from './useWeb3'
import { completeTransaction, openTransaction, updateTransaction } from '../state/transactions/actions'
import { getERC20Contract, getWMNTContract } from '../utils/contractHelpers'
import moment from 'moment'

const useQuoteSwap = (fromAsset, toAsset, fromAmount) => {
  const [bestTrade, setBestTrade] = useState()
  const [priceImpact, setPriceImpact] = useState(new BigNumber(0))
  const [quotePending, setQuotePending] = useState(false)
  const { pairs } = useContext(PairsContext)

  useEffect(() => {
    const fetchAllowance = async () => {
      const bases = routeAssets.filter((item) => ![fromAsset.address.toLowerCase(), toAsset.address.toLowerCase()].includes(item.address.toLowerCase()))
      const result = []
      // direct pairs
      result.push({
        routes: [
          {
            from: fromAsset.address,
            to: toAsset.address,
            stable: true,
          },
        ],
      })
      result.push({
        routes: [
          {
            from: fromAsset.address,
            to: toAsset.address,
            stable: false,
          },
        ],
      })
      // 1 hop
      bases.forEach((base) => {
        result.push({
          routes: [
            {
              from: fromAsset.address,
              to: base.address,
              stable: true,
            },
            {
              from: base.address,
              to: toAsset.address,
              stable: true,
            },
          ],
          base: [base],
        })
        result.push({
          routes: [
            {
              from: fromAsset.address,
              to: base.address,
              stable: true,
            },
            {
              from: base.address,
              to: toAsset.address,
              stable: false,
            },
          ],
          base: [base],
        })
        result.push({
          routes: [
            {
              from: fromAsset.address,
              to: base.address,
              stable: false,
            },
            {
              from: base.address,
              to: toAsset.address,
              stable: true,
            },
          ],
          base: [base],
        })
        result.push({
          routes: [
            {
              from: fromAsset.address,
              to: base.address,
              stable: false,
            },
            {
              from: base.address,
              to: toAsset.address,
              stable: false,
            },
          ],
          base: [base],
        })
      })
      // 2 hop
      bases.forEach((base) => {
        const otherbases = bases.filter((ele) => ele.address !== base.address)
        otherbases.forEach((otherbase) => {
          // true true true
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: true,
              },
              {
                from: base.address,
                to: otherbase.address,
                stable: true,
              },
              {
                from: otherbase.address,
                to: toAsset.address,
                stable: true,
              },
            ],
            base: [base, otherbase],
          })
          // true true false
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: true,
              },
              {
                from: base.address,
                to: otherbase.address,
                stable: true,
              },
              {
                from: otherbase.address,
                to: toAsset.address,
                stable: false,
              },
            ],
            base: [base, otherbase],
          })
          // true false true
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: true,
              },
              {
                from: base.address,
                to: otherbase.address,
                stable: false,
              },
              {
                from: otherbase.address,
                to: toAsset.address,
                stable: true,
              },
            ],
            base: [base, otherbase],
          })
          // true false false
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: true,
              },
              {
                from: base.address,
                to: otherbase.address,
                stable: false,
              },
              {
                from: otherbase.address,
                to: toAsset.address,
                stable: false,
              },
            ],
            base: [base, otherbase],
          })
          // false true true
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: false,
              },
              {
                from: base.address,
                to: otherbase.address,
                stable: true,
              },
              {
                from: otherbase.address,
                to: toAsset.address,
                stable: true,
              },
            ],
            base: [base, otherbase],
          })
          // false true false
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: false,
              },
              {
                from: base.address,
                to: otherbase.address,
                stable: true,
              },
              {
                from: otherbase.address,
                to: toAsset.address,
                stable: false,
              },
            ],
            base: [base, otherbase],
          })
          // false false true
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: false,
              },
              {
                from: base.address,
                to: otherbase.address,
                stable: false,
              },
              {
                from: otherbase.address,
                to: toAsset.address,
                stable: true,
              },
            ],
            base: [base, otherbase],
          })
          // false false false
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: false,
              },
              {
                from: base.address,
                to: otherbase.address,
                stable: false,
              },
              {
                from: otherbase.address,
                to: toAsset.address,
                stable: false,
              },
            ],
            base: [base, otherbase],
          })
        })
      })
      const final = result.filter((item) => {
        let isExist = true
        for (let i = 0; i < item.routes.length; i++) {
          const route = item.routes[i]
          const found = pairs
            .filter((pair) => pair.isValid)
            .find(
              (pair) =>
                pair.stable === route.stable &&
                [pair.token0.address.toLowerCase(), pair.token1.address.toLowerCase()].includes(route.from.toLowerCase()) &&
                [pair.token0.address.toLowerCase(), pair.token1.address.toLowerCase()].includes(route.to.toLowerCase()),
            )
          if (!found || found.tvl.lt(1e-5)) {
            isExist = false
            break
          }
        }
        return isExist
      })
      if (final.length === 0) {
        setBestTrade(null)
        return
      }
      setQuotePending(true)
      const sendFromAmount = toWei(fromAmount, fromAsset.decimals).toFixed(0)
      const calls = final.map((item) => {
        return {
          address: getRouterAddress(),
          name: 'getAmountsOut',
          params: [sendFromAmount, item.routes],
        }
      })
      try {
        const receiveAmounts = await multicall(routerAbi, calls)

        for (let i = 0; i < receiveAmounts.length; i++) {
          final[i].receiveAmounts = receiveAmounts[i].amounts
          final[i].finalValue = fromWei(Number(receiveAmounts[i].amounts[receiveAmounts[i].amounts.length - 1]), toAsset.decimals)
        }
        const bestAmountOut = final.reduce((best, current) => {
          if (!best) {
            return current
          }
          return best.finalValue.gt(current.finalValue) ? best : current
        }, 0)

        if (bestAmountOut.finalValue.isZero()) {
          setQuotePending(false)
          setBestTrade(null)
          return
        }

        let totalRatio = new BigNumber(1)

        for (let i = 0; i < bestAmountOut.routes.length; i++) {
          const route = bestAmountOut.routes[i]
          if (!route.stable) {
            const found = pairs.find(
              (pair) =>
                pair.stable === route.stable &&
                [pair.token0.address.toLowerCase(), pair.token1.address.toLowerCase()].includes(route.from.toLowerCase()) &&
                [pair.token0.address.toLowerCase(), pair.token1.address.toLowerCase()].includes(route.to.toLowerCase()),
            )
            let reserveIn
            let reserveOut
            if (found.token0.address.toLowerCase() === route.from.toLowerCase()) {
              reserveIn = toWei(found.token0.reserve, found.token0.decimals)
              reserveOut = toWei(found.token1.reserve, found.token1.decimals)
            } else {
              reserveIn = toWei(found.token1.reserve, found.token1.decimals)
              reserveOut = toWei(found.token0.reserve, found.token0.decimals)
            }
            let amountIn = 0
            let amountOut = 0
            if (i == 0) {
              amountIn = sendFromAmount
              amountOut = Number(bestAmountOut.receiveAmounts[1])
            } else {
              amountIn = Number(bestAmountOut.receiveAmounts[i])
              amountOut = Number(bestAmountOut.receiveAmounts[i + 1])
            }

            const amIn = new BigNumber(amountIn).div(reserveIn)
            const amOut = new BigNumber(amountOut).div(reserveOut)
            const ratio = new BigNumber(amOut).div(amIn)

            totalRatio = totalRatio.times(ratio)
          }
        }

        setBestTrade(bestAmountOut)
        setPriceImpact(new BigNumber(1).minus(totalRatio).times(100))
        setQuotePending(false)
      } catch (error) {
        console.log('error :>> ', error)
        setBestTrade(null)
        setQuotePending(false)
      }
    }
    if (fromAsset && toAsset && !isInvalidAmount(fromAmount) && fromAsset.address.toLowerCase() !== toAsset.address.toLowerCase()) {
      fetchAllowance()
    } else {
      setBestTrade(null)
    }
  }, [fromAsset, toAsset, fromAmount, pairs])

  return { bestTrade, priceImpact, quotePending }
}

const useProceedSwap = () => {
  const [swapPending, setSwapPending] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const routerAddress = getRouterAddress()
  const routerContract = useRouter()
  const web3 = useWeb3()

  const handleSwap = useCallback(
    async (fromAsset, toAsset, fromAmount, bestTrade, slippage, deadline) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const swapuuid = uuidv4()
      setSwapPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Swap ${fromAsset.symbol} for ${toAsset.symbol}`,
          transactions: {
            [approveuuid]: {
              desc: `Approve ${fromAsset.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [swapuuid]: {
              desc: `Swap ${formatAmount(fromAmount)} ${fromAsset.symbol} for ${toAsset.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      if (fromAsset.address !== 'MATIC') {
        const tokenContract = getERC20Contract(web3, fromAsset.address)
        const allowance = await getAllowance(tokenContract, routerAddress, account)
        if (fromWei(allowance, fromAsset.decimals).lt(fromAmount)) {
          isApproved = false
          try {
            await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [routerAddress, MAX_UINT256], account)
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setSwapPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      const sendSlippage = new BigNumber(100).minus(slippage).div(100)
      const sendFromAmount = toWei(fromAmount, fromAsset.decimals).toFixed(0)
      const sendMinAmountOut = toWei(bestTrade.finalValue.times(sendSlippage), toAsset.decimals).toFixed(0)
      const deadlineVal =
        '' +
        moment()
          .add(Number(deadline) * 60, 'seconds')
          .unix()

      let func =
        TaxAssets.includes(fromAsset.address.toLowerCase()) || TaxAssets.includes(toAsset.address.toLowerCase())
          ? 'swapExactTokensForTokensSupportingFeeOnTransferTokens'
          : 'swapExactTokensForTokens'
      let params = [sendFromAmount, sendMinAmountOut, bestTrade.routes, account, deadlineVal]
      let sendValue = '0'

      if (fromAsset.address === 'MATIC') {
        if (TaxAssets.includes(toAsset.address.toLowerCase())) {
          func = 'swapExactETHForTokensSupportingFeeOnTransferTokens'
        } else {
          func = 'swapExactETHForTokens'
        }
        params = [sendMinAmountOut, bestTrade.routes, account, deadlineVal]
        sendValue = sendFromAmount
      }
      if (toAsset.address === 'MATIC') {
        if (TaxAssets.includes(fromAsset.address.toLowerCase())) {
          func = 'swapExactTokensForETHSupportingFeeOnTransferTokens'
        } else {
          func = 'swapExactTokensForETH'
        }
      }
      try {
        await sendContract(dispatch, key, swapuuid, routerContract, func, params, account, sendValue)
      } catch (err) {
        console.log('swap error :>> ', err)
        setSwapPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Swap Successful',
        }),
      )
      setSwapPending(false)
    },
    [account, web3, routerContract],
  )

  const handleWrap = useCallback(
    async (amount) => {
      const key = uuidv4()
      const wrapuuid = uuidv4()
      setSwapPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Wrap BNB for WBNB`,
          transactions: {
            [wrapuuid]: {
              desc: `Wrap ${formatAmount(amount)} BNB for WBNB`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      const wbnbContract = getWMNTContract(web3)
      try {
        await sendContract(dispatch, key, wrapuuid, wbnbContract, 'deposit', [], account, toWei(amount).toFixed(0))
      } catch (err) {
        console.log('wrap error :>> ', err)
        setSwapPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Wrap Successful',
        }),
      )
      setSwapPending(false)
    },
    [account, web3, routerContract],
  )

  const handleUnwrap = useCallback(
    async (amount) => {
      const key = uuidv4()
      const wrapuuid = uuidv4()
      setSwapPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Unwrap WBNB for BNB`,
          transactions: {
            [wrapuuid]: {
              desc: `Unwrap ${formatAmount(amount)} WBNB for BNB`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      const wbnbContract = getWMNTContract(web3)
      try {
        await sendContract(dispatch, key, wrapuuid, wbnbContract, 'withdraw', [toWei(amount).toFixed(0)], account)
      } catch (err) {
        console.log('unwrap error :>> ', err)
        setSwapPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Unwrap Successful',
        }),
      )
      setSwapPending(false)
    },
    [account, web3, routerContract],
  )

  return { onSwap: handleSwap, onWrap: handleWrap, onUnwrap: handleUnwrap, swapPending }
}

export { useQuoteSwap, useProceedSwap }
