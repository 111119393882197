import React from 'react'

const SwapIcon = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.05426 11.735C3.05426 16.4874 6.49994 19.7247 10.3692 20.3947C10.8119 20.4714 11.1087 20.8924 11.0321 21.3353C10.9554 21.778 10.5343 22.0748 10.0916 21.9982C5.54612 21.211 1.427 17.3799 1.427 11.735C1.427 9.33612 2.51841 7.45086 3.79294 6.0167C4.70596 4.98935 5.7505 4.1552 6.59056 3.51619H4.03595C3.60486 3.51619 3.25539 3.16672 3.25539 2.73563C3.25539 2.30455 3.60486 1.95508 4.03595 1.95508H8.71928C9.15037 1.95508 9.49984 2.30455 9.49984 2.73563V7.41897C9.49984 7.85005 9.15037 8.19952 8.71928 8.19952C8.2882 8.19952 7.93872 7.85005 7.93872 7.41897V4.53783L7.93637 4.53961L7.93623 4.53972L7.93621 4.53973H7.9362C7.04407 5.21259 5.95383 6.03482 5.00928 7.09766C3.89384 8.35281 3.05426 9.86916 3.05426 11.735ZM20.3946 12.3695C20.3946 7.66739 17.022 4.44908 13.2025 3.73204C12.7608 3.64913 12.47 3.2239 12.5529 2.78225C12.6358 2.34061 13.0611 2.04981 13.5027 2.13272C17.9897 2.97509 22.0217 6.7852 22.0217 12.3695C22.0217 14.7685 20.9304 16.6537 19.6559 18.0879C18.7428 19.1152 17.6982 19.9494 16.8582 20.5885H19.413C19.844 20.5885 20.1935 20.9378 20.1935 21.369C20.1935 21.8 19.844 22.1496 19.413 22.1496H14.7296C14.2985 22.1496 13.949 21.8 13.949 21.369V16.6857C13.949 16.2545 14.2985 15.9051 14.7296 15.9051C15.1606 15.9051 15.5101 16.2545 15.5101 16.6857V19.5667L15.5126 19.5648C16.4047 18.892 17.495 18.0698 18.4395 17.007C19.555 15.7518 20.3946 14.2354 20.3946 12.3695Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SwapIcon
