import { ichiVaults } from 'config/constants/ichiVaults'
import { BaseAssetsConetext } from 'context/BaseAssetsConetext'
import { useContext, useMemo } from 'react'

export const useIchiVaultAssets = () => {
  const baseAssets = useContext(BaseAssetsConetext)

  const ichiVaultAssets = useMemo(() => {
    return baseAssets.length > 0
      ? ichiVaults.map(
          (vault) => {
            const baseAsset = baseAssets.find((asset) => asset.symbol === vault.depositTokenSymbol)

            const secondAssetSymbol = vault.depositTokenSymbol === vault.token0 ? vault.token1 : vault.token0

            const secondAsset = baseAssets.find((asset) => asset.symbol === secondAssetSymbol)

            return {
              ...baseAsset,
              name: `${vault.token0}/${vault.token1} (${vault.feeTier}%)`,
              vault,
              firstAsset: baseAsset,
              secondAsset,
            }
          },
          [baseAssets],
        )
      : []
  })

  return ichiVaultAssets
}
