import {Token} from '@crust_finance/sdk-core'
import {ChainId} from 'config/constants'

export const WMNT_EXTENDED = {
  [ChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8',
    18,
    'WMNT',
    'Wrapped MNT',
  ),
}

// export class ExtendedEther extends MANTLE {
//   static _cachedEther = {}
//
//   get wrapped() {
//     if (this.chainId in WMNT_EXTENDED) return WMNT_EXTENDED[this.chainId]
//     throw new Error('Unsupported chain ID')
//   }
//
//   static onChain(chainId) {
//     return this._cachedEther[chainId] ?? (this._cachedEther[chainId] = new ExtendedEther(chainId))
//   }
// }

export const v3LiquidityRangeType = {
  MANUAL_RANGE: 'MANUAL_RANGE',
  ICHI_RANGE: 'ICHI_RANGE',
  STEER: 'STEER',
}
