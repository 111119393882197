import React from 'react'
import { Widget } from '@kyberswap/widgets'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import { getNetworkLibrary } from 'utils'
import {tokenList} from "../../../config/constants/tokenList";

const darkTheme = {
  text: '#B8B6CB',
  subText: '#B8B6CB',
  primary: 'transparent',
  dialog: '#141B22',
  secondary: '#242132',
  interactive: '#242132',
  stroke: '#E1E1E1',
  accent: '#E1E1E1',
  success: '#189470',
  warning: '#FF9901',
  error: '#FF537B',
  fontFamily: 'Inter, sans-serif',
  borderRadius: '15px',
  buttonRadius: '15px',
  boxShadow: '0',
}

export const KyberWidget = () => {
  const { library } = useActiveWeb3React()

  const baseAssets = tokenList;

  return (
    <Widget
      width={(window.innerWidth < 498) ? 340 : 400}
      client="crust"
      theme={darkTheme}
      tokenList={baseAssets}
      provider={library ?? getNetworkLibrary()}
      enableRoute={true}
      defaultTokenIn='0xdeaddeaddeaddeaddeaddeaddeaddeaddead1111'
      defaultTokenOut='0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8'
    />
  )
}
