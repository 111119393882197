import { useQuery } from '@tanstack/react-query'
import { BaseAssetsConetext } from 'context/BaseAssetsConetext'
import { useContext } from 'react'
import { gql, request } from 'graphql-request'

export const useAllPoolsQuery = () => {
    const baseAssets = useContext(BaseAssetsConetext)

    return useQuery(
        ['all-pools-list'],
        async () => {
            try {
                const getSubgraphQuery = () => {
                    return (
                    `
                          pools{
                            id
                            totalValueLockedToken0
                            totalValueLockedToken1
                            feeTier
                            collectedFeesUSD
                            volumeUSD
                            totalValueLockedUSD
                            token0 {
                              id
                              symbol
                            }
                            token1 {
                              id
                              symbol
                            }
                          }
                    `
                    )
                }

                const resSubgraph = await request(
                    process.env.REACT_APP_GRAPH_FUSION_API_URL,
                    gql`{
                        ${getSubgraphQuery()}
                    }`,
                )

                const result = Object.values(resSubgraph.pools).map((pool) => {
                    const token0 = baseAssets.find((asset) => asset.address.toLowerCase() === pool.token0.id.toLowerCase())
                    const token1 = baseAssets.find((asset) => asset.address.toLowerCase() === pool.token1.id.toLowerCase())

                    return {
                        ...pool,
                        token0,
                        token1,
                    }
                })
                return result
            } catch (error) {
                console.error(error)
            }
        },
        {enabled: baseAssets.length > 0},
    )
}