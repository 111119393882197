import './footer.scss'
import { useLocation } from 'react-router-dom'

const socialLinks = [
  {
    link: 'https://discord.gg/QpYbhcQP83',
    image: '/images/retro/footer/DiscordLogo.svg',
  },
  {
    link: 'https://medium.com/@crustt.finance',
    image: '/images/retro/footer/MediumLogo.svg',
  },
  {
    link: 'https://twitter.com/CrustFinance',
    image: '/images/retro/footer/TwitterLogo.png',
  },
  {
    link: 'https://docs.crust.finance/',
    image: '/images/retro/footer/GitBook.png',
  },
]

const toolsLinks = [
  // {
  //   name: 'Analytics',
  //   link: 'https://info.retro.finance/',
  // },
  // {
  //   name: 'veNFT Manager',
  //   link: '/ve-manager',
  // },
  {
    name: 'DEX Screener',
    link: 'https://dexscreener.com/mantle/f:0xEaD128BDF9Cff441eF401Ec8D18a96b4A2d25252',
  },
]
const resourcesLinks = [
  {
    name: 'Docs',
    link: 'https://docs.crust.finance',
  },
  {
    name: 'Media Kit',
    link: 'https://docs.crust.finance/info/brand-assets',
  },
]
const helpLinks = [
  {
    name: 'Apply for Whitelisting',
    link: 'https://discord.gg/QpYbhcQP83',
  },
  {
    name: 'Report Bugs',
    link: 'https://discord.gg/QpYbhcQP83',
  },
]

const Footer = () => {
  const route = useLocation()
  return (
    <footer className={route.pathname.includes('swap') ? 'hidden': 'footer'}>
      <div className='socials-container'>
        <img src='/images/retro/footer/logo.svg' alt='logo' className='logo' />
        <h2 className='title'>Join Community</h2>
        <div className='social-links-container'>
          {socialLinks.map((link, idx) => {
            return (
              <a key={idx} href={link.link} target='_blank' rel='noreferrer' className='social-link'>
                <img src={link.image} alt='Discord' className='social-image' />
              </a>
            )
          })}
        </div>
        <p className='copyright'>Copyright © 2023 Crust Finance. All rights reserved.</p>
      </div>
      <div className='containers'>
        <div className='links-container container-type-tools'>
          <h2 className='title'>Tools</h2>
          {toolsLinks.map((link, idx) => {
            return (
              <a key={idx} href={link.link} target='_blank' rel='noreferrer' className='link'>
                {link.name}
              </a>
            )
          })}
        </div>
        <div className='links-container container-type-resources'>
          <h2 className='title'>Resources</h2>
          {resourcesLinks.map((link, idx) => {
            return (
              <a key={idx} href={link.link} target='_blank' rel='noreferrer' className='link'>
                {link.name}
              </a>
            )
          })}
        </div>
        <div className='links-container container-type-help'>
          <h2 className='title'>Help</h2>
          {helpLinks.map((link, idx) => {
            return (
              <a key={idx} href={link.link} target='_blank' rel='noreferrer' className='link'>
                {link.name}
              </a>
            )
          })}
        </div>
      </div>
    </footer>
  )
}

export default Footer
