import { useState, useCallback } from 'react'
import { useAlgebra } from '../useContract'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { TransactionType } from '../../config/constants'
import { useWeb3React } from '@web3-react/core'
import useWeb3 from '../useWeb3'
import { completeTransaction, openTransaction, updateTransaction } from '../../state/transactions/actions'
import { getERC20Contract } from '../../utils/contractHelpers'
import { getAllowance, sendContract, sendV3Contract } from '../../utils/api'
import { MAX_UINT256, fromWei } from '../../utils/formatNumber'
import { getAlgebraAddress } from '../../utils/addressHelpers'
import { Percent } from '@crust_finance/sdk-core'
import { useV3NFTPositionManagerContract } from 'hooks/useContractV3'
import { NonfungiblePositionManager } from '@uniswap/v3-sdk'
import JSBI from 'jsbi'

// manual v3 liquidity
const useAlgebraAdd = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  // const algebraContract = useAlgebra()
  const web3 = useWeb3()
  // const algebraAddress = getAlgebraAddress()
  const positionManager = useV3NFTPositionManagerContract()

  const handleAlgebraAdd = useCallback(
    async (amountA, amountB, mintInfo, slippage, deadline) => {
      const allowedSlippage = new Percent(JSBI.BigInt(slippage * 100), JSBI.BigInt(10000))
      const { position, depositADisabled, depositBDisabled, noLiquidity } = mintInfo
      const baseCurrency = amountA.currency
      const quoteCurrency = amountB.currency
      const baseCurrencyAddress = baseCurrency.wrapped ? baseCurrency.wrapped.address.toLowerCase() : ''
      const quoteCurrencyAddress = quoteCurrency.wrapped ? quoteCurrency.wrapped.address.toLowerCase() : ''
      const key = uuidv4()
      const approve0uuid = uuidv4()
      const approve1uuid = uuidv4()
      const supplyuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Add ${baseCurrency.symbol}/${quoteCurrency.symbol} liquidity`,
          transactions: {
            [approve0uuid]: {
              desc: `Approve ${baseCurrency.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [approve1uuid]: {
              desc: `Approve ${quoteCurrency.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [supplyuuid]: {
              desc: `Deposit tokens in the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      if (!baseCurrency.isNative && !depositADisabled) {
        const baseTokenContract = getERC20Contract(web3, baseCurrencyAddress)
        const baseAllowance = await getAllowance(baseTokenContract, positionManager.address, account)

        if (fromWei(baseAllowance, baseCurrency.decimals).lt(amountA.toExact())) {
          isApproved = false
          try {
            await sendContract(
              dispatch,
              key,
              approve0uuid,
              baseTokenContract,
              'approve',
              [positionManager.address, MAX_UINT256],
              account,
            )
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve0uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      isApproved = true
      if (!quoteCurrency.isNative && !depositBDisabled) {
        const quoteTokenContract = getERC20Contract(web3, quoteCurrencyAddress)
        const quoteAllowance = await getAllowance(quoteTokenContract, positionManager.address, account)
        if (fromWei(quoteAllowance, quoteCurrency.decimals).lt(amountB.toExact())) {
          isApproved = false
          try {
            await sendContract(
              dispatch,
              key,
              approve1uuid,
              quoteTokenContract,
              'approve',
              [positionManager.address, MAX_UINT256],
              account,
            )
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve1uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const timestamp = Math.floor(new Date().getTime() / 1000) + deadline * 60
      const useNative = baseCurrency.isNative ? baseCurrency : quoteCurrency.isNative ? quoteCurrency : undefined

      const { calldata, value } = NonfungiblePositionManager.addCallParameters(position, {
        slippageTolerance: allowedSlippage,
        recipient: account,
        deadline: timestamp.toString(),
        useNative,
        createPool: noLiquidity,
      })
      console.log('value :>> ', value)

      try {
        await sendV3Contract(dispatch, key, supplyuuid, web3, account, positionManager.address, calldata, value)
      } catch (err) {
        console.log('supply error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Added',
        }),
      )
      setPending(false)
    },
    [account, web3, positionManager?.address],
  )

  return { onManualAdd: handleAlgebraAdd, pending }
}

const useAlgebraIncrease = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  // const algebraContract = useAlgebra()
  const web3 = useWeb3()
  // const algebraAddress = getAlgebraAddress()
  const positionManager = useV3NFTPositionManagerContract()

  const handleAlgebraIncrease = useCallback(
    async (amountA, amountB, position, slippage, deadline, tokenId) => {
      const allowedSlippage = new Percent(JSBI.BigInt(slippage * 100), JSBI.BigInt(10000))
      const baseCurrency = amountA.currency
      const quoteCurrency = amountB.currency
      const baseCurrencyAddress = baseCurrency.wrapped ? baseCurrency.wrapped.address.toLowerCase() : ''
      const quoteCurrencyAddress = quoteCurrency.wrapped ? quoteCurrency.wrapped.address.toLowerCase() : ''
      const key = uuidv4()
      const approve0uuid = uuidv4()
      const approve1uuid = uuidv4()
      const increaseuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Increase ${baseCurrency.symbol}/${quoteCurrency.symbol} liquidity`,
          transactions: {
            [approve0uuid]: {
              desc: `Approve ${baseCurrency.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [approve1uuid]: {
              desc: `Approve ${quoteCurrency.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [increaseuuid]: {
              desc: `Deposit tokens in the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      if (!baseCurrency.isNative) {
        const baseTokenContract = getERC20Contract(web3, baseCurrencyAddress)
        const baseAllowance = await getAllowance(baseTokenContract, positionManager.address, account)
        if (fromWei(baseAllowance, baseCurrency.decimals).lt(amountA.toExact())) {
          isApproved = false
          try {
            await sendContract(
              dispatch,
              key,
              approve0uuid,
              baseTokenContract,
              'approve',
              [positionManager.address, MAX_UINT256],
              account,
            )
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve0uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      isApproved = true
      if (!quoteCurrency.isNative) {
        const quoteTokenContract = getERC20Contract(web3, quoteCurrencyAddress)
        const quoteAllowance = await getAllowance(quoteTokenContract, positionManager.address, account)
        if (fromWei(quoteAllowance, quoteCurrency.decimals).lt(amountB.toExact())) {
          isApproved = false
          try {
            await sendContract(
              dispatch,
              key,
              approve1uuid,
              quoteTokenContract,
              'approve',
              [positionManager.address, MAX_UINT256],
              account,
            )
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve1uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const timestamp = Math.floor(new Date().getTime() / 1000) + deadline * 60
      const useNative = baseCurrency.isNative ? baseCurrency : quoteCurrency.isNative ? quoteCurrency : undefined
      const { calldata, value } = NonfungiblePositionManager.addCallParameters(position, {
        tokenId,
        slippageTolerance: allowedSlippage,
        deadline: timestamp.toString(),
        useNative,
      })

      try {
        await sendV3Contract(dispatch, key, increaseuuid, web3, account, positionManager.address, calldata, value)
      } catch (err) {
        console.log('increase error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Increased',
        }),
      )
      setPending(false)
    },
    [account, web3, positionManager.address],
  )

  return { onAlgebraIncrease: handleAlgebraIncrease, pending }
}

const useAlgebraRemove = (derivedInfo) => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  // const algebraContract = useAlgebra()
  const web3 = useWeb3()
  // const algebraAddress = getAlgebraAddress()

  const positionManager = useV3NFTPositionManagerContract()

  const { positionSDK, liquidityPercentage, liquidityValue0, liquidityValue1, feeValue0, feeValue1 } = derivedInfo
  const handleAlgebraRemove = async (tokenId, slippage, deadline) => {
    const allowedSlippage = new Percent(JSBI.BigInt(slippage * 100), JSBI.BigInt(10000))
    const key = uuidv4()
    const removeuuid = uuidv4()
    setPending(true)
    dispatch(
      openTransaction({
        key,
        title: `Remove ${liquidityValue0.currency.symbol}/${liquidityValue1.currency.symbol} liquidity`,
        transactions: {
          [removeuuid]: {
            desc: `Remove tokens from the pool`,
            status: TransactionType.START,
            hash: null,
          },
        },
      }),
    )
    const timestamp = Math.floor(new Date().getTime() / 1000) + deadline * 60

    const { calldata, value } = NonfungiblePositionManager.removeCallParameters(positionSDK, {
      tokenId: tokenId.toString(),
      liquidityPercentage,
      slippageTolerance: allowedSlippage,
      deadline: timestamp.toString(),
      collectOptions: {
        expectedCurrencyOwed0: feeValue0,
        expectedCurrencyOwed1: feeValue1,
        recipient: account,
      },
    })

    try {
      await sendV3Contract(dispatch, key, removeuuid, web3, account, positionManager.address, calldata, value)
    } catch (err) {
      console.log('remove error :>> ', err)
      setPending(false)
      return
    }

    dispatch(
      completeTransaction({
        key,
        final: 'Liquidity Removed',
      }),
    )
    setPending(false)
  }

  return { onAlgebraRemove: handleAlgebraRemove, pending }
}

const useAlgebraClaim = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const algebraContract = useAlgebra()
  const web3 = useWeb3()
  const algebraAddress = getAlgebraAddress()

  const handleAlgebraClaim = useCallback(
    async (tokenId, feeValue0, feeValue1) => {
      const key = uuidv4()
      const collectuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Claim ${feeValue0.currency.symbol}/${feeValue1.currency.symbol} Fees`,
          transactions: {
            [collectuuid]: {
              desc: `Claim Fees`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      const { calldata, value } = NonfungiblePositionManager.collectCallParameters({
        tokenId: tokenId.toString(),
        expectedCurrencyOwed0: feeValue0,
        expectedCurrencyOwed1: feeValue1,
        recipient: account,
      })

      try {
        await sendV3Contract(dispatch, key, collectuuid, web3, account, algebraAddress, calldata, value)
      } catch (err) {
        console.log('claim error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Fees Claimed',
        }),
      )
      setPending(false)
    },
    [account, web3, algebraContract],
  )

  return { onAlgebraClaim: handleAlgebraClaim, pending }
}

export { useAlgebraAdd, useAlgebraIncrease, useAlgebraRemove, useAlgebraClaim }
