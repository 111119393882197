import React, { useState } from 'react'
import Slider from 'react-rangeslider'
import Modal from '../../../../../common/Modal'
import StyledButton from '../../../../../common/Buttons/styledButton'
import 'react-rangeslider/lib/index.css'
import { formatAmount, fromWei } from '../../../../../../utils/formatNumber'
import { customNotify } from '../../../../../../utils/notify'
import useDebounce from '../../../../../../hooks/useDebounce'
import { useIchiRemove } from 'hooks/v3/useIchiRemove'

const percentArray = [25, 50, 75, 100]

const IchiRemoveModal = ({ isOpen, setIsOpen, position }) => {
  const [percent, setPercent] = useState(0)
  const debouncedPercent = useDebounce(percent)

  const { logoURI, symbol, secondAsset } = position

  const { pending, onIchiRemove } = useIchiRemove()

  const onRemove = () => {
    if (debouncedPercent > 0) {
      onIchiRemove(position.share.mul(debouncedPercent).div(100).toFixed(0), position)
    } else {
      customNotify('Invalid Amount', 'warn')
    }
  }

  return (
    <Modal popup={isOpen} setPopup={setIsOpen} title={'Remove Liquidity'} width={548}>
      <div className='px-2 pt-5 pb-3 rounded-[5px] mt-[13px]'>
        <div className='flex items-start md:items-center justify-between cursor-pointer'>
          <div className='flex items-center space-x-3 '>
            <div className='flex items-center'>
              <img alt='' className='w-6 lg:w-[30px] relative shadow' src={logoURI} />
              <img alt='' className='w-6 lg:w-[30px] -ml-3' src={position.secondAsset.logoURI} />
            </div>
            <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>{position.name}</p>
          </div>
        </div>
        <div className='mt-5 flex items-center justify-between'>
          <span className='text-[#E0DEF0] leading-5'>Amount</span>
          <div className='flex items-center space-x-2.5'>
            {percentArray.map((perc, index) => {
              return (
                <div
                  className={`flex items-center justify-center bg-white bg-opacity-[0.05] rounded-[15px] text-white text-[13px] md:text-base w-[40px] md:w-[56px] h-[22px] md:h-[28px] cursor-pointer`}
                  onClick={() => setPercent(perc)}
                  key={`percent-${index}`}
                >
                  {perc}%
                </div>
              )
            })}
          </div>
        </div>
        <div className='w-full px-2.5 md:px-4 pt-4 md:pt-5 bg-white bg-opacity-[0.05] rounded-[15px] pb-5 md:pb-[22px] mt-1 md:mt-[7px]'>
          <div className='text-lg md:text-2xl leading-5 md:leading-[29px] text-white'>{debouncedPercent}%</div>
          <Slider
            min={0}
            max={100}
            className='!mt-3 mb-0'
            value={percent}
            onChange={(value) => {
              setPercent(value)
            }}
          />
        </div>
        <div className='mt-4 w-full'>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px]'>
            <div className='flex items-center space-x-[5px]'>
              <img alt='' src={logoURI} className={'w-4 md:w-5'} />
              <span>{symbol}</span>
            </div>
            <span>
              {formatAmount(
                fromWei(position.balances[position.address].toString(), position.decimals)
                  .times(debouncedPercent)
                  .div(100),
              )}
            </span>
          </div>

          <div className='mt-2 flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px]'>
            <div className='flex items-center space-x-[5px]'>
              <img alt='' src={secondAsset.logoURI} className={'w-4 md:w-5'} />
              <span>{secondAsset.symbol}</span>
            </div>
            <span>
              {formatAmount(
                fromWei(position.balances[secondAsset.address].toString(), position.secondAsset.decimals)
                  .times(debouncedPercent)
                  .div(100),
              )}
            </span>
          </div>
        </div>
        <StyledButton
          onClickHandler={onRemove}
          disabled={pending}
          content={'Withdraw'}
          className='mt-4 py-[13px] md:py-[14.53px] text-black text-sm md:text-lg leading-4 flex items-center justify-center md:leading-[30px] px-[19px] w-full rounded-[15px]'
        />
      </div>
    </Modal>
  )
}

export default IchiRemoveModal
