import BigNumber from 'bignumber.js'
import { TransactionType } from '../config/constants'
import { updateTransaction } from '../state/transactions/actions'
import { getWMNTAddress } from './addressHelpers'
import { customNotify } from './notify'
import {logoURIMap} from "../config/constants/logo";
import fetchAllAssets from "./getPrices";

const backendApi = process.env.REACT_APP_BACKEND_API
const chainID = process.env.REACT_APP_CHAIN_ID

let baseAssets = []

const getBaseAssets = async () => {
  try {
      if (baseAssets.length === 0) {
          await updateBaseAssets()
      }
      return baseAssets
  } catch (ex) {
    console.error('get baseAssets had error', ex)
    return null
  }
}

const updateBaseAssets = async () => {
    try {
        baseAssets = await fetchAllAssets()

        const wmntPrice = baseAssets.find(
            (asset) => asset.address.toLowerCase() === getWMNTAddress().toLowerCase()
        )?.price

        const nativeMNT = {
            address: 'MNT',
            name: 'Mantle',
            symbol: 'MNT',
            decimals: 18,
            price: wmntPrice,
            isNative: true,
        }
        baseAssets.unshift(nativeMNT)
        baseAssets = baseAssets.map((item) => ({
            ...item,
            balance: new BigNumber(0),
            chainId: chainID,
            logoURI: logoURIMap[item?.symbol].logoURI,
        }))
    } catch (ex) {
        console.error('get baseAssets had error', ex)
    }
}

const symbolMap = {
  '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1': 'MAI',
}

const getFusions = async () => {
  try {
    const response = await fetch(`${backendApi}/fusions`, {
      method: 'get',
        headers: new Headers({
            "ngrok-skip-browser-warning" : "10"
        })
    })
    const pairsCall = await response.json();

    return {
      ...pairsCall,
      data: pairsCall.data.map((pair) => {
        let [token0, token1] = pair.symbol.split(' ')[0].split('/')
        const rest = pair.symbol.split(' ').slice(1).join(' ')
        const [token0Address, token1Address] = [pair.token0.address, pair.token1.address]

        return {
          ...pair,
          symbol: `${symbolMap[token0Address] ?? token0}/${symbolMap[token1Address] ?? token1} ${rest}`,
        }
      }),
    }
  } catch (ex) {
    console.error('v3 Pairs fetched had error', ex)
    return []
  }
}

const sendContract = (dispatch, key, uuid, contract, method, params, account, msgValue = '0') => {
  let hash
  dispatch(
    updateTransaction({
      key,
      uuid,
      status: TransactionType.WAITING,
    }),
  )
  return new Promise((resolve, reject) => {
    contract.methods[method](...params)
      .send({
        from: account,
        value: msgValue,
      })
      .on('transactionHash', (tx) => {
        hash = tx
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.PENDING,
            hash,
          }),
        )
      })
      .then((res) => {
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.SUCCESS,
            hash,
          }),
        )
        customNotify('Transaction Successful!', 'success', hash)
        resolve(res)
      })
      .catch((err) => {
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.FAILED,
            hash,
          }),
        )
        customNotify(err.message, 'error')
        reject(err)
      })
  })
}

const sendV3Contract = (dispatch, key, uuid, web3, from, to, data, msgValue = '0') => {
  let hash
  dispatch(
    updateTransaction({
      key,
      uuid,
      status: TransactionType.WAITING,
    }),
  )
  return new Promise((resolve, reject) => {
    web3.eth
      .sendTransaction({
        from,
        to,
        data,
        value: msgValue,
      })
      .on('transactionHash', (tx) => {
        hash = tx
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.PENDING,
            hash,
          }),
        )
      })
      .then((res) => {
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.SUCCESS,
            hash,
          }),
        )
        customNotify('Transaction Successful!', 'success', hash)
        resolve(res)
      })
      .catch((err) => {
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.FAILED,
            hash,
          }),
        )
        customNotify(err.message, 'error')
        reject(err)
      })
  })
}

const getAllowance = async (contract, target, account) => {
  try {
    return await contract.methods.allowance(account, target).call()
  } catch (ex) {
    console.error(ex)
    return 0
  }
}

export {
  getBaseAssets,
  getFusions,
  sendContract,
  sendV3Contract,
  getAllowance,
}
