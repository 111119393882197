import React from 'react'

import { formatAmount } from '../../../../../../utils/formatNumber'
import { useCurrencyLogo } from '../../../../../../hooks/v3/useCurrencyLogo'

const percentArray = ['25', '50', '75', '100']

export const TokenAmountCard = ({ currency, value, maxAmount, handleInput, locked }) => {
  const logoURI = useCurrencyLogo(currency)

  return (
    <>
      <div className='w-full'>
        {locked && (
          <div className='mb-2 text-white'>
            <p className='span'>
              Price is outside specified price range.
              <br />
              Single-asset deposit only
            </p>
          </div>
        )}
        <div className='flex items-center justify-between w-full'>
          <div className={`flex flex-wrap items-center text-sm lg:text-base gap-2 justify-between w-full`}>
            <div className='flex items-center gap-2.5'>
              {percentArray.map((percent, index) => {
                return (
                  <div
                    className={`flex items-center justify-center bg-white bg-opacity-[0.05] rounded-[15px] min-w-[62px] text-white text-[13px] md:text-base w-[40px] md:w-[56px] h-[22px] md:h-[28px] cursor-pointer`}
                    onClick={() => {
                      handleInput(maxAmount?.divide('100')?.multiply(percent)?.toExact() ?? '')
                    }}
                    key={`percent-${index}`}
                  >
                    {percent}%
                  </div>
                )
              })}
            </div>
            <p className='text-white'>Balance: {maxAmount ? formatAmount(maxAmount.toSignificant()) : 'Loading'}</p>
          </div>
        </div>
        <div className='mt-1.5 lg:mt-2.5 p-px w-full rounded-[15px]'>
          <div className='bg-white bg-opacity-[0.05] px-3  rounded-[15px] flex items-center justify-between'>
            <input
              className={`bg-transparent w-[80%] py-[8px] lg:py-[15px] text-xl lg:text-2xl leading-10 placeholder-placeholder text-white`}
              value={value}
              disabled={locked}
              onChange={(e) => {
                handleInput(Number(e.target.value) < 0 ? '' : e.target.value)
              }}
              placeholder='0.00'
              type={'number'}
              min={0}
            />
            {currency && (
              <div className='flex items-center justify-between space-x-2'>
                <img alt='' width={28} height={28} src={logoURI} />
                <p className='font-medium text-sm lg:text-[1.2rem] leading-6 text-white'>{currency.symbol}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
