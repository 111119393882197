import {Token} from '@crust_finance/sdk-core'
import {useContext, useMemo} from 'react'
import {ChainId} from 'config/constants'
import {BaseAssetsConetext} from '../../context/BaseAssetsConetext'
import {MANTLE} from "../../v3lib/entities/MANTLE";

// undefined if invalid or does not exist
// otherwise returns the token
export function useToken(tokenAddress) {
  const assets = useContext(BaseAssetsConetext)

  return useMemo(() => {
    if (!tokenAddress) return undefined
    const asset = assets.find((item) => item.address.toLowerCase() === tokenAddress.toLowerCase())
    if (!asset) return undefined
    return new Token(ChainId.MANTLE, asset.address, asset.decimals, asset.symbol, asset.name)
  }, [assets, tokenAddress])
}

export const useCurrency = (currencyId) => {
  let isMNT = currencyId?.toUpperCase() === 'MNT'
  const token = useToken(isMNT ? undefined : currencyId)
  return isMNT ? MANTLE.onChain(ChainId.MANTLE) : token
}
