import React, { useCallback, useMemo } from 'react'
import JSBI from 'jsbi'
import { ChainId, defaultChainId } from 'config/constants'
import { Field } from '../../../../../../state/mintV3/actions'
import StyledButton from '../../../../../common/Buttons/styledButton'
import { useProceedSwap } from '../../../../../../hooks/useSwap'
import { WMNT_EXTENDED, v3LiquidityRangeType } from '../../../../../../v3lib/entities/constants'
import { useCurrencyBalance } from '../../../../../../hooks/v3/useCurrencyBalances'
import { customNotify } from '../../../../../../utils/notify'
import { useAlgebraAdd } from 'hooks/v3/useAlgebra'
import { useAddIchiLiquidity } from 'hooks/v3/useAddIchiLiquidity'
import { useWeb3React } from '@web3-react/core'
import useWalletModal from 'hooks/useWalletModal'
import { useSteerAdd } from 'hooks/v3/useSteerAdd'

const chainId = ChainId.MANTLE

export const AddLiquidityButton = ({
  baseCurrency,
  quoteCurrency,
  mintInfo,
  slippage,
  deadline,
  ichiAsset,
}) => {
  const { account, chainId: activeChainId } = useWeb3React()
  const { openWalletModal } = useWalletModal()

  const { liquidityRangeType, errorMessage } = mintInfo
  const { onWrap, swapPending: wrappingETH } = useProceedSwap()

  const { onManualAdd, pending: isManualPending } = useAlgebraAdd() // manual
  const { onIchiAdd, pending: isIchiPending } = useAddIchiLiquidity()

  const { onSteerAdd, pending: isSteerPending } = useSteerAdd(baseCurrency, quoteCurrency)

  const amountA = mintInfo.parsedAmounts[Field.CURRENCY_A]
  const amountB = mintInfo.parsedAmounts[Field.CURRENCY_B]
  const wbnbBalance = useCurrencyBalance(WMNT_EXTENDED[chainId])

  const amountToWrap = useMemo(() => {
    if (
      !baseCurrency ||
      !quoteCurrency ||
      !amountA ||
      !amountB ||
      liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE
    )
      return

    if (
      baseCurrency.isNative ||
      baseCurrency.wrapped.address.toLowerCase() === WMNT_EXTENDED[chainId].address.toLowerCase()
    ) {
      if (wbnbBalance && JSBI.greaterThan(amountA.numerator, wbnbBalance.numerator)) {
        return JSBI.subtract(amountA.numerator, wbnbBalance.numerator)
      }
      return
    } else if (
      quoteCurrency.isNative ||
      quoteCurrency.wrapped.address.toLowerCase() === WMNT_EXTENDED[chainId].address.toLowerCase()
    ) {
      if (wbnbBalance && JSBI.greaterThan(amountB.numerator, wbnbBalance.numerator)) {
        return JSBI.subtract(amountB.numerator, wbnbBalance.numerator)
      }
      return
    }

    return
  }, [amountA, amountB, baseCurrency, quoteCurrency, liquidityRangeType, wbnbBalance])

  const onAddLiquidity = useCallback(() => {
    const checkError = () => {
      if (errorMessage) {
        customNotify(errorMessage, 'warn')
        return
      }
    }

    switch (liquidityRangeType) {
      case v3LiquidityRangeType.MANUAL_RANGE: {
        checkError()

        return onManualAdd(amountA, amountB, mintInfo, slippage, deadline)
      }

      case v3LiquidityRangeType.ICHI_RANGE:
        return onIchiAdd(amountA, amountB, ichiAsset)

      case v3LiquidityRangeType.STEER:
        return onSteerAdd(amountA, amountB, amountToWrap)

      default:
        return
    }
  }, [
    errorMessage,
    amountToWrap,
    onWrap,
    baseCurrency,
    quoteCurrency,
    liquidityRangeType,
    amountA,
    amountB,
    mintInfo,
    slippage,
    deadline,
  ])

  return (
    <>
      {account && activeChainId === defaultChainId ? (
        <StyledButton
          disabled={
            (!amountA && !amountB) ||
            wrappingETH ||
            isManualPending ||
            isIchiPending ||
            isSteerPending
          }
          onClickHandler={onAddLiquidity}
          content={'ADD LIQUIDITY'}
          className='py-[13px] md:py-[14.53px] mt-8 text-sm text-[14px] leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[15px]'
        />
      ) : (
        <StyledButton
          onClickHandler={openWalletModal}
          content={'CONNECT WALLET'}
          className='my-3 py-[13px] md:py-[14.53px] text-sm text-[14px] leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[15px]'
        />
      )}
    </>
  )
}
