// import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import React, { useState } from 'react'

import SteerRemoveModal from './steerRemoveModal'

// import { formatAmount } from 'utils/formatNumber'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import {useNavigate} from "react-router-dom";
import TransparentButton from "../../../../../common/Buttons/transparentButton";

export const SteerPosition = ({ openedPositionIndex, setOpenedPositionIndex, index, position }) => {
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false)
  const navigate = useNavigate()

  const { token0, token1 } = position

  return (
      <div
          onClick={() => {
            openedPositionIndex === index ? setOpenedPositionIndex(-1) : setOpenedPositionIndex(index)
          }}
          className={`${index > 0 ? 'mt-[9px]' : ''} w-full px-5 py-[19.2px] bg-white bg-opacity-[0.05] rounded-[15px] cursor-pointer`}
      >
        {isWithdrawModalOpen && (
            <SteerRemoveModal
                isOpen={isWithdrawModalOpen}
                setIsOpen={setIsWithdrawModalOpen}
                position={position}
            />
        )}

        <div className='flex items-start md:items-center justify-between'>
          <div className='flex items-start md:items-center space-x-3'>
            <div className='flex items-center space-x-3 '>
              <div className='flex items-center'>
                <img alt='' className='w-6 lg:w-[30px] relative shadow' src={token0.logoURI} />
                <img alt='' className='w-6 lg:w-[30px] -ml-3' src={token1.logoURI} />
              </div>
              <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>
                {token0.symbol}/{token1.symbol} ({position.feeTier/10000}%)
              </p>
            </div>
          </div>
          <button className={`${openedPositionIndex === index ? 'rotate-180' : 'rotate-0'} mt-2 md:mt-0 w-3 md:w-auto`}>
            <img alt='' src='/images/svgs/dropdown.svg' />
          </button>
        </div>
        {openedPositionIndex === index ? (
            <div className='w-full mt-5'>
              <div className='flex flex-row'>
                <div className='flex items-center justify-center mr-4'>
                  <TransparentButton
                      onClickHandler={() => {
                        navigate(
                            `/liquidity/addv3?currency0=${position.token0.address}&currency1=${position.token1.address}&strategy=${position.token0Symbol}/${position.token1Symbol} (${position.feeTier/10000}%)}`,
                        )
                      }}
                      content={'Add'}
                      className='py-[10.8px] w-[90px] text-black font-semibold leading-5 px-3 bg-white fonts-semibold text-[15px] md:text-[17px] rounded-[15px] flex items-center justify-center'
                  />
                </div>
                <div className='w-[90px]'>
                  <LinearGhostButton
                      onClickHanlder={() => {
                        setIsWithdrawModalOpen(true)
                      }}
                      smallCase={true}
                      title={'Withdraw'}
                  />
                </div>
              </div>
            </div>
        ) : (
            <></>
        )}
      </div>
  )
}

