import {Route, Routes, useLocation} from 'react-router-dom'
import Swap from './pages/swap'
import Liquidity from './pages/liquidity'
import AddLiquidityV3 from './pages/liquidity/addLiquidityV3'
import ManageLiquidityV3 from "./pages/liquidity/manageLiquidityV3";
// import Rewards from './pages/rewards'
import PageNotFound from './pages/404NotFound'
import {RefreshContextProvider} from './context/RefreshContext'
import {ToastContainer, Zoom} from 'react-toastify'
import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
// import {VeTHEsContextProvider} from './context/veTHEsConetext'
import {BaseAssetsConetextProvider} from './context/BaseAssetsConetext'
import {PairsContextProvider} from './context/PairsContext'
// import {FusionsContextProvider} from './context/FusionsContext'
import ApplicationUpdater from './state/application/updater'
import MultiCallV3Updater from './state/multicall/v3/updater'
import Header from './components/common/Header'
import Footer from './components/common/footer'
import Big from 'big.js'
import Transaction from 'components/common/Transaction'
import 'bootstrap-icons/font/bootstrap-icons.css';

Big.NE = -20
Big.DP = 20
Big.PE = 40

const Updaters = () => {
  return (
    <>
      <ApplicationUpdater />
      <MultiCallV3Updater />
    </>
  )
}

const App = () => {
  const { pathname } = useLocation()
  const isHomePage = pathname === '/'

  return (
    <div className='main'>
      <RefreshContextProvider>
        <Updaters />
        <BaseAssetsConetextProvider>
            <PairsContextProvider>
                {/*<FusionsContextProvider>*/}
                  <div>
                     <Header />
                      <div className="flex items-center text-white border-[2px] mx-10 px-4 py-3 mb-4 bg-opacity-20 border-opacity-100 border-[#f57842] bg-[#f578420d]" role="alert">
                          <i className="bi bi-info-circle mr-2 text-primary"></i>
                          To access Crust Finance v1, please visit
                          <a href={"https://v1.crust.finance"} target="_blank" rel="noreferrer" className="underline text-blue-500 ml-2 mr-1">
                              v1.crust.finance
                          </a>
                          .
                      </div>
                    <div>
                      <div
                        className={'relative w-full flex flex-col min-h-appBox  h-auto overflow-hidden'}
                      >
                        <Transaction />
                        <Routes>
                          <Route path='/' element={<Swap />} exact/>
                          <Route path='/swap' element={<Swap />} exact />
                          {/*<Route path='/lock' element={<Lock />} exact />*/}
                          {/*<Route path='/vote' element={<Vote />} exact />*/}
                          {/*<Route path='/vote/:veId' element={<Vote />} exact />*/}
                          {/*<Route path='/options' element={<Options />} exact />*/}
                          {/*<Route path='/rewards' element={<Rewards />} exact />*/}
                          <Route path='/liquidity' element={<Liquidity />} exact />
                          <Route path='/liquidity/addV3' element={<AddLiquidityV3 />} exact />
                          <Route path='/liquidity/manageV3' element={<ManageLiquidityV3/>} exact />
                          {/*<Route path='/bribes' element={<WhiteList />} exact />*/}
                          {/*<Route path='/bribes/add' element={<AddBribe />} exact />*/}
                          {/*{process.env.REACT_APP_VERCEL_ENV === 'preview' && (*/}
                          {/*  <Route path='/whitelist/gauge' element={<AddGauge />} exact />*/}
                          {/*)}*/}
                          {/*<Route path='/dashboard' element={<Dashboard />} exact />*/}
                          {/*<Route path='/ve-manager' element={<VeTokenManager />} />*/}
                          {/*<Route path='/whitelist/gauge' element={<AddGauge />} exact />*/}
                          <Route path='/404' element={<PageNotFound />} exact />
                          <Route path='*' element={<PageNotFound />} exact />
                        </Routes>
                      </div>
                        {!isHomePage && <div className='w-full'>
                            <Footer />
                          </div>}
                    </div>
                  </div>
                {/*</FusionsContextProvider>*/}
            </PairsContextProvider>
        </BaseAssetsConetextProvider>
      </RefreshContextProvider>
      <ToastContainer
        className='notify-class'
        position='top-right'
        theme='dark'
        closeOnClick={false}
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={true}
        closeButton={false}
      />
    </div>
  )
}

export default App
