import axios from "axios";
import { tokenList } from "../config/constants/tokenList";

const DEXSCREENER_ENDPOINT = 'https://api.dexscreener.com/latest/dex/tokens/'
const DEFILLAMA_ENDPOINT = 'https://coins.llama.fi/prices/current/'
const CACHE_DURATION = 5 * 60 * 1000; // 10 minute in milliseconds

// Initialize priceCache from localStorage on page load
const storedPrices = localStorage.getItem('priceCache');
const priceCache = storedPrices ? new Map(JSON.parse(storedPrices)) : new Map();

// Function to save priceCache to localStorage
const savePriceCache = () => {
    localStorage.setItem('priceCache', JSON.stringify(Array.from(priceCache.entries())));
};

const getDefillamaPrice = async (address) => {
    try {
        const chain_token = 'mantle:' + address;
        const res = await axios.get(DEFILLAMA_ENDPOINT + chain_token);
        const items = Object.values(res.data.coins);
        if (items.length > 0) {
            return items[0].price;
        }
        return 0;
    } catch (error) {
        console.log(`Defillama api error: ${error}`);
        return 0;
    }
}

const getDexScreenerPrice = async (address) => {
    try {
        const res = await axios.get(DEXSCREENER_ENDPOINT + address);
        if (res.data.pairs && res.data.pairs.length > 0) {
            const found = res.data.pairs.find((pair) => pair.baseToken.address.toLowerCase() === address);
            if (found) {
                return Number(found.priceUsd);
            }
        }
        return 0;
    } catch (error) {
        console.log(`Dexscreener api error: ${error}`);
        return 0;
    }
}

const dexAssets = [];

const getTokenPrice = async (asset) => {
    const cacheEntry = priceCache.get(asset.address);
    const now = Date.now();

    if (cacheEntry && (now - cacheEntry.timestamp < CACHE_DURATION)) {
        return cacheEntry.price;
    }

    if (dexAssets.includes(asset.address)) {
        return await getDexScreenerPrice(asset.address);
    }

    let price;

    if (asset.address === "0x86b634eac93e463fcc303e632ddf05cfaadfdad1") {
        price = await getDefillamaPrice("0x0d4b4d63149cf37de1C996D3e5b6f0d9CC3Be2e3");
        if (price === 0) {
            price = await getDexScreenerPrice("0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb");
        }
    } else if (asset.address === "0x6965a4f636bb07dd8bed9174e885a0f7e7879308") {
        price = 0.08;
    } else {
        price = await getDefillamaPrice(asset.address);
        if (price === 0) {
            price = await getDexScreenerPrice(asset.address);
        }
    }

    if (price > 0) {
        priceCache.set(asset.address, { price, timestamp: now });
        savePriceCache();
    }

    return price;
}

export const fetchAllAssets = async () => {
    const updatedTokenList = [];
    for (let i = 0; i < tokenList.length; i++) {
        const asset = tokenList[i];
        const price = await getTokenPrice(asset);
        updatedTokenList.push({ ...asset, price });
    }
    return updatedTokenList;
}

export default fetchAllAssets;