import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import React, { useState } from 'react'
import IchiRemoveModal from './IchiRemoveModal'
import {useNavigate} from "react-router-dom";
import TransparentButton from "../../../../../common/Buttons/transparentButton";

export const IchiPosition = ({openedPositionIndex, setOpenedPositionIndex, id, position }) => {
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false)
  const navigate = useNavigate()

  return (
      <div
          onClick={() => {
            openedPositionIndex === id ? setOpenedPositionIndex(-1) : setOpenedPositionIndex(id)
          }}
          className={`${id > 0 ? 'mt-[9px]' : ''} w-full px-5 py-[19.2px] bg-white bg-opacity-[0.05] rounded-[15px]`}
      >
        {isWithdrawModalOpen && (
            <IchiRemoveModal isOpen={isWithdrawModalOpen} setIsOpen={setIsWithdrawModalOpen} position={position} />
        )}

        <div className='flex items-start md:items-center justify-between'>
          <div className='flex items-start md:items-center space-x-3'>
            <div className='flex items-center space-x-3 '>
              <div className='flex items-center'>
                <img alt='' className='w-6 lg:w-[30px]' src={position.logoURI} />
              </div>
              <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>{position.name}</p>
            </div>
          </div>
          <button className={`${openedPositionIndex === id ? 'rotate-180' : 'rotate-0'} mt-2 md:mt-0 w-3 md:w-auto`}>
            <img alt='' src='/images/svgs/dropdown.svg' />
          </button>
        </div>
        {openedPositionIndex === id ? (
            <div className='w-full mt-5'>
              <div className='flex flex-row'>
                <div className='flex items-center justify-center mr-4'>
                  <TransparentButton onClickHandler={() => {
                    navigate(
                        `/liquidity/addv3?currency0=${position.firstAsset.address}&currency1=${position.secondAsset.address}&strategy=${position.name}`,
                    )
                  }}
                  content= {'Add'}
                  className='py-[10.8px] w-[90px] text-black font-semibold leading-5 px-3 bg-white fonts-semibold text-[15px] md:text-[17px] rounded-[15px] flex items-center justify-center'
                  />
                </div>
                <div className='text-white w-[90px]'>
                  <LinearGhostButton onClickHanlder={() => setIsWithdrawModalOpen(true)} smallCase = {true} title={'Withdraw'} />
                </div>
              </div>
            </div>
        ) : (
            <></>
        )}
      </div>
  )
}

