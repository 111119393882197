import { useQuery } from '@tanstack/react-query'
import { BaseAssetsConetext } from 'context/BaseAssetsConetext'
import { useContext } from 'react'
import { gql, request } from 'graphql-request'
import {steerVaults} from "../../config/constants/steerVaults";
// import {useWeb3React} from "@web3-react/core";

export const useSteerStrategiesQuery = () => {
  const baseAssets = useContext(BaseAssetsConetext)

  return useQuery(
      ['steer-strategies-list'],
      async () => {
        try {
            if (steerVaults.length === 0) {
                return;
            }
          const getSubgraphQuery = () => {
            return steerVaults.reduce((acc, strategy) => {
              return `
                        vaults (where :{id: "${strategy.vaultAddress}", beaconName :"MultiPositionCrust"}) {   
                        id
                        name
                        pool
                        createdAt
                        totalAmount0
                        totalAmount1
                        feeTier
                        accruedStrategistFees0
                        accruedStrategistFees1
                        fees0
                        fees1
                        token0
                        token0Name
                        token0Symbol
                        token0Decimals
                        token0Balance
                        token1
                        token1Name
                        token1Symbol
                        token1Decimals
                        token1Balance
                        state
                        totalLPTokensIssued
                        decimals
                        beaconName
                        totalValueLockedToken0
                        totalValueLockedToken1
                    }
                  ${acc}
                `
          }, '')
        }

        const resSubgraph = await request(
          'https://subgraph-api.mantle.xyz/subgraphs/name/steerprotocol/steer-protocol-mantle',
          gql`{
          ${getSubgraphQuery()}
        }`,
        )
          const result = Object.values(resSubgraph.vaults).map((strategy) => {
            const token0 = baseAssets.find((asset) => {
              return asset.address.toLowerCase() === strategy.token0.toLowerCase()
            })

            const token1 = baseAssets.find((asset) => asset.address.toLowerCase() === strategy.token1.toLowerCase())

            return {
              ...strategy,
              token0,
              token1,
            }
          })
          return result
        } catch (error) {
          console.error(error)
        }
      },
      {enabled: baseAssets.length > 0},
  )
}