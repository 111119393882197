import React from 'react'
import Spinner from '../../../../../common/Spinner'

const PoolStats = ({ fee, apr, noLiquidity, loading }) => {
  if (loading)
    return (
      <div className='flex justify-center items-center'>
        <Spinner />
      </div>
    )

  return (
    <div className='w-1/2 px-2.5 md:px-5 py-2 md:py-3 rounded-2xl bg-white bg-opacity-[0.05]'>
      <span className='text-[13px] md:text-[15px] text-[#DAD8ED] leading-4 md:leading-[19px]'>
        {noLiquidity ? 'New pool' : 'Current pool stats'}
      </span>
      <div className='mt-1 md:mt-2 flex items-center space-x-[5px] md:space-x-2'>
        <div className='bg-componentSecondary px-1.5 md:px-2 py-0.5 md:py-1 rounded-md text-[11px] md:text-sm leading-[13px] md:leading-4 text-white font-medium'>
          {fee}
        </div>
        {apr && (
          <div className='bg-componentSecondary px-1.5 md:px-2 py-0.5 md:py-1 rounded-md text-[11px] md:text-sm leading-[13px] md:leading-4 text-[#51B961] font-medium'>
            {apr}% APR
          </div>
        )}
      </div>
    </div>
  )
}

export default PoolStats
