import React from 'react'
import { formatAmount } from 'utils/formatNumber'

const percentArray = ['25', '50', '75', '100']

export const IchiTokenSelect = ({ setTokenModalOpen, asset, value, maxAmount, handleInput, locked }) => {
  return (
    <>
      <div className='w-full'>
        <div className='flex items-center justify-between w-full'>
          <div className={`flex flex-wrap items-center text-sm lg:text-base gap-2 justify-between w-full`}>
            <div className='flex items-center gap-2.5'>
              {percentArray.map((percent, index) => {
                return (
                  <div
                    className={`${
                      locked && 'opacity-25'
                    } flex items-center justify-center bg-white bg-opacity-[0.05] rounded-[15px] min-w-[62px] text-white text-[13px] md:text-base w-[40px] md:w-[56px] h-[22px] md:h-[28px] cursor-pointer`}
                    onClick={() => {
                      !locked && handleInput(maxAmount?.divide('100')?.multiply(percent)?.toExact() ?? '')
                    }}
                    key={`percent-${index}`}
                  >
                    {percent}%
                  </div>
                )
              })}
            </div>
            <p className='text-white'>
              Balance: {asset?.balance ? (asset.balance ? formatAmount(asset.balance.toString()) : 'Loading') : '-'}
            </p>
          </div>
        </div>
        <div className='mt-1.5 lg:mt-2.5 p-px w-full rounded-[15px]'>
          <div className='bg-white bg-opacity-[0.05] px-3  rounded-[15px] flex items-center justify-between'>
            <input
              className={`bg-transparent w-[70%] py-[8px] lg:py-[15px] text-xl lg:text-2xl leading-10 placeholder-placeholder text-white`}
              value={value}
              disabled={locked}
              onChange={(e) => {
                handleInput(Number(e.target.value) < 0 ? '' : e.target.value)
              }}
              placeholder='0.00'
              type={'number'}
              min={0}
            />
            <div
              onClick={() => {
                setTokenModalOpen(true)
              }}
              className={`px-4 md:px-5 py-[4px] cursor-pointer flex items-center justify-between bg-white rounded-[15px] bg-opacity-[0.05] w-full`}
            >
              <div className='flex items-center space-x-[9px] md:space-x-1.5'>
                {asset ? (
                  <>
                    <div className='flex items-center space-x-2.5 md:space-x-3'>
                      <img alt='' src={asset.logoURI} className='flex-shrink-0' width={28} height={28} loading='lazy' />
                      <div className=''>
                        <p className='text-white text-sm md:text-base f-f-fg'>{asset.symbol}</p>
                        <p className='text-[13px] md:text-sm tracking-[0.52px] text-dimGray'>{asset.name}</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <span className='text-[15px] md:text-lg font-semibold text-white leading-[10px]'>Select a token</span>
                )}
              </div>

              <button className='md:block hidden'>
                <img alt='dropdown' src={'/images/svgs/dropdown.svg'} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
