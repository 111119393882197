const contracts = {
  CRUST: {
    5000: ''
  },
  WMNT: {
    5000: '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8'
  },
  ETH: {
    5000: '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111'
  },
  multiCall: {
    5000: '0x9D85A910113219F7851Aba9EaB8999a4F113950b'
  },
  router: {
    5000: '0x2C2954cf16f974033e1e2345fCe1cA434dc14497'
  },
  veCRST: {
    5000: ''
  },
  veDist: {
    5000: ''
  },
  voter: {
    5000: ''
  },
  v3voter: {
    5000: ''
  },
  minter: {
    5000: '0x1844A814C7E9fC25FD1cDbBA3A63ad87Cd72a112'
  },
  veNFTAPI: {
    5000: ''
  },
  rewardsAPI: {
    5000: '0x6321543754e7395aB700198BBEBE238a0d8e6823'
  },
  pairV3API: {
    5000: '0x4fA98271ae54C0770D9014fCBb8f48564BF0BB4E'
  },
  veNFTV3API: {
    5000: ''
  },
  rewardsV3API: {
    5000: '0x6321543754e7395aB700198BBEBE238a0d8e6823'
  },
  option: {
    5000: '',
  },
  merklDistributor: {
    5000: '0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae'
  },
  bribeOption: {
    5000: ''
  },
}
export default contracts
