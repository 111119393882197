import { useQuery } from '@tanstack/react-query'
import {useSteerStrategiesQuery} from "./useSteerStrategiesQuery";
import {usePool} from "../v3/usePools";
import {useCurrency} from "../v3/Tokens";
import BigNumber from "bignumber.js";
// import {useSteerPositionsQuery} from "./useSteerPositionsQuery";

const calculatePrice = (sqrtPriceX96) => {
    return (BigInt(sqrtPriceX96) ** 2n) / (2n ** 192n);
};
const calculateSteerRatio = (strategy,sqrtPriceX96) => {
    try {
        const price = calculatePrice(sqrtPriceX96);

        // Convert price to a floating-point number
        const priceFloat = parseFloat(price.toString());
        const decimalAdjustment = 10 ** (strategy.token1Decimals - strategy.token0Decimals);
        const nativeTokenRatio = priceFloat / decimalAdjustment;

        let ratio;

        const token0Balance = new BigNumber(strategy.token0Balance.toString());
        const token1Balance = new BigNumber(strategy.token1Balance.toString());
        const token0Decimals = new BigNumber("10").pow(strategy.token0Decimals.toString());
        const token1Decimals = new BigNumber("10").pow(strategy.token1Decimals.toString());

        if (token0Balance.isZero() && token1Balance.isZero()) {
            // Case when both balances are 0
            ratio = nativeTokenRatio !== 0 ? 1 / nativeTokenRatio : nativeTokenRatio;
        } else if (token0Balance.gt(0) || token1Balance.gt(0)) {
            // Case when there is liquidity in the pool
            if (token1Balance.isZero()) {
                ratio = token0Balance.div(token0Decimals).toNumber();
            } else if (token0Balance.isZero()) {
                ratio = token1Balance.div(token1Decimals).toNumber();
            } else {
                const token0Normalized = token0Balance.div(token0Decimals);
                const token1Normalized = token1Balance.div(token1Decimals);
                ratio = token0Normalized.div(token1Normalized).toNumber();
            }
        }
        return ratio;
    } catch (error) {
        console.error(error)
    }
}
export const useSteerLiquidityRatio = (strategyAddress) => {
    const steerStrategies = useSteerStrategiesQuery();

    // const positionData = useSteerPositionsQuery();

    // console.log(positionData);

    const strategy = steerStrategies?.data?.find(strategy => strategy?.id === strategyAddress)

    const currency0 = useCurrency(strategy?.token0?.address)
    const currency1 = useCurrency(strategy?.token1?.address)
    const pool = usePool(currency0, currency1, strategy?.feeTier)[1];

    return useQuery(
        ['steer-liquidity-ratio', strategyAddress],
        async () => {
            try {
                if (!strategy || !pool || !pool.sqrtRatioX96) {
                    throw new Error('Missing strategy or pool data');
                };
                const ratio = calculateSteerRatio(strategy, pool.sqrtRatioX96);
                return ratio;
            } catch (error) {
                console.error(error)
            }
        },
            { enabled: Boolean(strategyAddress), refetchInterval: 5000},
        )
}
