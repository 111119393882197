export const logoURIMap = {
    "CRST": {
        "logoURI": "/images/tokens/crust-white.png"
    },
    "preCRST": {
        "logoURI": "/images/tokens/crust-white.png"
    },
    "wETH": {
        "logoURI": "/images/tokens/WETH.png"
    },
    "wMNT": {
        "logoURI": "/images/tokens/mantle.png"
    },
    "MNT": {
        "logoURI": "/images/tokens/mantle.png"
    },
    "USDC": {
        "logoURI": "/images/tokens/USDC.png"
    },
    "USDT": {
        "logoURI": "/images/tokens/USDT.png"
    }
};