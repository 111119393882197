import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { getCRSTAddress } from '../../../utils/addressHelpers'
import { formatAmount } from '../../../utils/formatNumber'
import Modal from '../Modal'
import NoFound from '../NoFound'
// import { useTokenBalances } from 'hooks/useTokenBalance'
// import { ethers } from 'ethers'

const usdcAddress = '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9'
const usdtAddress = '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE'
const ethAddress = '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111'
const crstAddress = getCRSTAddress()

const SearchTokenPopup = ({
  popup,
  setPopup,
  selectedAsset,
  setSelectedAsset,
  otherAsset,
  setOtherAsset,
  baseAssets,
  hideCommon,
  onAssetSelect = () => {},
}) => {
  // const balanceAssets = useMemo(() => baseAssets.map((item) => item.address), [baseAssets])
  //
  // const balances = useTokenBalances(balanceAssets)

  const [manage, setManage] = useState(false)
  const [searchText, setSearchText] = useState('')
  const { account } = useWeb3React()
  const inputRef = useRef()

  const filteredAssets = searchText
    ? baseAssets.filter((asset) => {
        return (
          asset?.name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          asset?.symbol?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          asset?.address?.toLowerCase()?.includes(searchText.toLowerCase())
        )
      })
    : baseAssets

  const commonAssets = useMemo(() => {
    return baseAssets.filter(
      (asset) =>
        asset.address === 'MNT' ||
        [
          crstAddress?.toLowerCase(),
          usdcAddress.toLowerCase(),
          usdtAddress.toLowerCase(),
          ethAddress.toLowerCase(),
        ].includes(asset?.address?.toLowerCase()),
    )
  }, [baseAssets])

  useEffect(() => {
    if (!popup) {
      setSearchText('')
    } else {
      setTimeout(() => {
        inputRef.current && inputRef.current.focus()
      }, 300)
    }
  }, [popup])

  return (
    <Modal
      popup={popup}
      setPopup={setPopup}
      title={manage ? 'Manage Tokens' : 'Select a Token'}
      isBack={manage}
      setIsBack={setManage}
      width={540}
      isToken={true}
    >
      <>
        <div className='w-full'>
          <div className='px-3 md:px-6'>
            <div className='border-[1px] border-white w-full mt-3 rounded-[15px]'>
              <input
                ref={inputRef}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder='Search by name, symbol or address'
                className='bg-white bg-opacity-[0.05] placeholder-placeholder h-14 w-full text-white text-base md:text-lg px-4 py-[18px] rounded-[15px]'
              />
            </div>
          </div>

          {!hideCommon && (
            <div className='w-full mt-3.5 md:mt-[18px] px-3 md:px-6'>
              <p className='text-[13px] md:text-sm tracking-[0.52px] md:tracking-[0.56px] f-f-fg text-dimGray mb-1'>
                COMMON TOKENS
              </p>
              <div className='flex flex-wrap md:-mx-3'>
                {commonAssets.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`px-2 md:px-3 cursor-pointer py-1 md:py-1.5 border-[1px] m-[5px] flex items-center gap-x-2.5 border-white rounded-xl hover:bg-white hover:bg-opacity-[0.05]`}
                      onClick={() => {
                        if (otherAsset && otherAsset.address === item.address) {
                          const temp = selectedAsset
                          setSelectedAsset(otherAsset)
                          setOtherAsset(temp)
                        } else {
                          setSelectedAsset(item)
                        }
                        onAssetSelect()
                        setPopup(false)
                      }}
                    >
                      <img alt='' src={item.logoURI} width={28} height={28} loading='lazy' />
                      <p className='text-sm md:text-base text-white f-f-fg'>{item.symbol}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        <div className='w-full mt-3'>
          <div className='flex justify-between text-[13px] md:text-sm tracking-[0.52px] md:tracking-[0.56px] f-f-fg text-dimGray mb-1 px-3 md:px-6'>
            <span>TOKEN NAME</span>
            {account && <span>BALANCE</span>}
          </div>
          <div className='w-full mt-3 md:mt-[13px] max-h-[340px] overflow-auto'>
            {filteredAssets.length > 0 ? (
              filteredAssets.map((asset, idx) => {
                return (
                  <div
                    key={`asset-${idx}`}
                    className={`flex items-center justify-between py-[6px] px-3 md:px-6 cursor-pointer hover:bg-white hover:bg-opacity-[0.05]`}
                    onClick={() => {
                      if (otherAsset && otherAsset.address === asset.address) {
                        const temp = selectedAsset
                        setSelectedAsset(otherAsset)
                        setOtherAsset(temp)
                      } else {
                        setSelectedAsset(asset)
                      }
                      onAssetSelect()
                      setPopup(false)
                    }}
                  >
                    <div className='flex items-center space-x-2.5 md:space-x-3'>
                      <img alt='' src={asset.logoURI} className='flex-shrink-0' width={28} height={28} loading='lazy' />
                      <div className=''>
                        <p className='text-white text-sm md:text-base f-f-fg'>{asset.symbol}</p>
                        <p className='text-[13px] md:text-sm tracking-[0.52px] text-dimGray'>{asset.name}</p>
                      </div>
                    </div>
                    {account && (
                      <p className='text-sm md:text-base text-white'>
                        {formatAmount(asset.balance.toNumber()) || ''}
                      </p>
                    )}
                  </div>
                )
              })
            ) : (
              <NoFound title='No tokens found' />
            )}
          </div>
        </div>
      </>
    </Modal>
  )
}

export default SearchTokenPopup
