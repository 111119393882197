import React, { useState, useMemo, useEffect } from 'react'
import TransparentButton from '../../common/Buttons/transparentButton'
import { Tooltip } from 'react-tooltip'
import Pagination from '../../common/Pagination'
import { useWeb3React } from '@web3-react/core'
import Sticky from 'react-stickynode'
import useWalletModal from '../../../hooks/useWalletModal'
import { formatAmount, getLP0Symbol, getLP1Symbol } from '../../../utils/formatNumber'
import { useNavigate } from 'react-router-dom'
import { NumberOfRows, defaultChainId } from '../../../config/constants'
import NoFound from '../../common/NoFound'
import { useGetMerklDataQuery } from 'hooks/queries/useGetMerklDataQuery'

const TableRow = ({ item, idx }) => {
  const { isLoading: isMerklDataLoading } = useGetMerklDataQuery()
  const [isOpen, setIsOpen] = useState(!item.account.gaugeBalance.isZero())
  const { account, chainId } = useWeb3React()
  const { openWalletModal } = useWalletModal()
  const navigate = useNavigate()

  return (
    <div
      key={idx}
      className={'mb-px rounded-[15px] flex flex-wrap lg:flex-nowrap items-start lg:items-center w-full justify-between text-[#DEDBF2] p-4 lg:py-5 px-4 xl:px-6 bg-white bg-opacity-[0.08] item.account?.totalUsd?.gt(0) border-[1px] border-[#9986C0]'}
    >
      <div className='w-full  lg:w-[20%]'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-3 mr:0 lg:mr-3'>
            <div className='flex items-center -space-x-2'>
              <img className='relative z-10' alt='' src={item?.token0?.logoURI} width={30} height={30} />
              <img className='relative z-[5]' alt='' src={item?.token1?.logoURI} width={30} height={30} />
            </div>
            <div>
              <div className='flex flex-wrap text-base xl:text-[13px] leading-[30px] font-medium'>
                <span>{getLP0Symbol(item)}</span>/
                <span>
                  {getLP1Symbol(item)}
                </span>
              </div>
               <p className='tracking-[0.78px] text-[12px] leading-none'>{`${item.fee / 10000}% Fee Tier`}</p>
            </div>
          </div>
          <button
            className='lg:hidden'
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <img alt='' className={`${isOpen ? 'rotate-180' : ''} transform`} src='/images/swap/dropdown-arrow.png' />
          </button>
        </div>
      </div>
      <div className='flex flex-col mt-5 lg:mt-0 w-1/2 lg:w-[14%] items-start justify-center'>
        <p className='lg:hidden xl:text-[14px] text-sm font-semibold z-20'>Avg. APR</p>
        <div
          data-tip
          data-tooltip-id={`apr-toolip-${idx}`}
          className='flex flex-col items-start cursor-pointer justify-center xl:text-[14px] text-white px-3 py-1 bg-[#2166fa] rounded-[15px] text-sm'
        >
          {isMerklDataLoading && item.gauge.apr.toNumber() === 0 ? '-' : `${formatAmount(item.gauge.apr, true)}%`}
        </div>
        {Object.keys(item.fullMerklData?.aprs ?? {}).length > 0 && (
          <Tooltip
            className='max-w-[470px] !bg-tooltip !text-base z-50 !opacity-100 after:!bg-tooltip py-[10px]'
            id={`apr-toolip-${idx}`}
            place='right'
            effect='solid'
          >
            <div className='w-full text-dimGray z-50'>
              <h3 className='mb-2'>APR Details:</h3>
              <div className='text-white text-[14px]'>Average: {formatAmount(item.gauge.apr, true)}%</div>
            </div>
            <div className='w-full border-b px-[25px] mt-3' />
            <ul className='list-disc px-[25px] xl:text-[14px] text-sm text-dimGray mt-3'>
              {Object.entries(item.fullMerklData?.aprs ?? {}).map(([key, value]) => {
                return (
                  key !== 'avg' &&
                  key !== 'min' &&
                  key !== 'max' && (
                    <li className='mt-2' key={key}>
                      <span className='text-white'>{formatAmount(value)}%</span> - {key}
                    </li>
                  )
                )
              })}
            </ul>
          </Tooltip>
        )}
      </div>
      <div className='flex flex-col w-1/2 mt-5 lg:mt-0 items-start lg:w-[11%] justify-center'>
        <p className='lg:hidden text-sm xl:text-[14px] font-semibold z-20'>Total Deposited</p>
        <p className={'xl:text-[14px] text-sm'}> {'$' + formatAmount(item.totalTvl.toString(), true)}</p>
      </div>
      {/* second row */}
      <div
        className={`${
          isOpen ? 'flex' : 'hidden'
        } lg:flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[11%] justify-center`}
      >
        <p className='lg:hidden text-sm xl:text-[14px] font-semibold z-10'>Total Pool</p>
        {isMerklDataLoading ? (
          '-'
        ) : (
            <>
              <p className={'xl:text-[14px] text-sm'}> {formatAmount(item.totalToken0Balance)} {item?.token0?.symbol}</p>
              <p className={'xl:text-[14px] text-sm'}> {formatAmount(item.totalToken1Balance)} {item?.token1?.symbol}</p>
            </>
        )}
      </div>
      <div
          className={`${
              isOpen ? 'flex' : 'hidden'
          } lg:flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[11%] justify-center`}
      >
        <p className='lg:hidden text-sm xl:text-[14px] font-semibold z-10'>Total Pool</p>
        {isMerklDataLoading ? (
            '-'
        ) : (
            <>
              <p className={'xl:text-[14px] text-sm'}> {formatAmount(item.account.earnedUsd)}$</p>
            </>
        )}
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} lg:block w-full lg:w-[12.5%] mt-3.5 lg:mt-0`}>
        {account && chainId === defaultChainId ? (
          (
            <div className='space-x-5 xl:space-x-6 w-full flex items-center lg:justify-end'>
              <TransparentButton
                content={'Manage'}
                onClickHandler={() => {
                  navigate(
                    `/liquidity/addv3?currency0=${item.token0.address}&currency1=${item.token1.address}&strategy=${item.title}`,
                  )
                }}
                fontWeight={'font-medium'}
                className='h-10 px-[26px] text-white bg-white bg-opacity-[0.2] hover:bg-grayDark rounded-[15px] flex  max-w-[96px] whitespace-nowrap flex-col items-center justify-center text-sm xl:text-[14px] w-full'
              />
            </div>
          )
        ) : (
          <div className='w-full  flex  items-center lg:justify-end'>
            <TransparentButton
              onClickHandler={openWalletModal}
              content={'Connect Wallet'}
              className='h-10 px-[26px] !bg-transparent border-[1px] text-white border-white border-solid rounded-[15px] flex lg:max-w-[173px] whitespace-nowrap flex-col items-center justify-center text-sm xl:text-[14px] w-full '
            />
          </div>
        )}
      </div>
    </div>
  )
}

const TablePairs = ({
  pairsData,
  sort,
  setSort,
  sortOptions,
  /* active, */ filter,
  searchText,
  isMigration = false,
}) => {
  const [pageSize, setPageSize] = useState(NumberOfRows[0])
  const [currentPage, setCurrentPage] = useState(0)

  const pageCount = useMemo(() => {
    return Math.ceil(pairsData.length / pageSize)
  }, [pairsData, pageSize])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [pageSize, filter, searchText])

  return (
    <>
      {/* for desktop */}
      <div className='w-full mt-6 xl:mt-11'>
        <div className='w-full'>
          {pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length > 0 ? (
            <>
              <Sticky
                enabled={false}
                innerActiveClass={'bg-componentPrimary border[1px] border-white'}
                top={95}
                bottomBoundary={1200}
                activeClass={''}
                innerClass={
                  'px-6 lg:flex bg-componentPrimary rounded-[15px] justify-between hidden py-[0.475rem] lg:!-mb-[10px] xl:!mb-[5px] lg:!top-[-19px] xl:!top-[0]'
                }
                className={`z-10`}
              >
                <div className='w-[22%] font-medium text-[15px] xl:text-[15px] text-white'></div>
                {sortOptions.map((option, index) => (
                  <div
                    className={`${
                      index === 4 ? 'w-[8%]' : index === 0 ? 'w-[14%]' : 'w-[11%]'
                    } font-medium text-[14px] xl:text-[14px] text-white`}
                    key={`header-${index}`}
                  >
                    <div
                      onClick={() => {
                        setSort({
                          ...option,
                          isDesc: sort.value === option.value ? !sort.isDesc : true,
                        })
                      }}
                      className='flex items-center cursor-pointer space-x-1 -ml-1 relative '
                    >
                      {sort.value === option.value && (
                        <button className={`${sort.isDesc ? '' : 'rotate-180'} transform absolute -left-3.5`}>
                          <img alt='' src='/images/liquidity/arrow-bottom.svg' />
                        </button>
                      )}
                      <p className='flex items-center'>{option.label}</p>
                    </div>
                  </div>
                ))}
                <div className='w-[12%] font-medium text-[14px] xl:text-[14px] text-white'></div>
              </Sticky>
              <div className='flex flex-col gap-y-2'>
                {pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, idx) => {
                  return (
                    <TableRow
                      isLast={idx === pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length - 1}
                      idx={idx}
                      item={item}
                      key={`row-${idx}`}
                      isMigration={isMigration}
                    />
                  )
                })}
              </div>
              <Pagination
                pageSize={pageSize}
                setPageSize={setPageSize}
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
                total={pairsData.length}
              />
            </>
          ) : (
            <NoFound title='No pools found' />
          )}
        </div>
      </div>
    </>
  )
}

export default TablePairs
