import React, {useEffect, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import useWalletModal from '../../../../../hooks/useWalletModal'
import StyledButton from '../../../../common/Buttons/styledButton'
// import TabFilter from 'components/common/TabFilter'
// import {TabSizes} from 'config/constants'
import {defaultChainId} from 'config/constants'
import {useUserIchiPositions} from 'hooks/v3/useUserIchiPositions'
import {IchiPosition} from './components/IchiPosition'
import Spinner from 'components/common/Spinner'
import BackIcon from "../../../../../icons/BackIcon";
import {useNavigate} from "react-router-dom";
import {useSteerPositionsQuery} from "../../../../../hooks/queries/useSteerPositionsQuery";
import {SteerPosition} from "./components/steerPosition";

export const MyAutoPoolType = {
  ICHI: 'ICHI',
  // STEER: 'STEER',
}

const MyAutoPools = () => {
  const navigate = useNavigate()
  const [openedPositionIndex, setOpenedPositionIndex] = useState(-1)
  // const [filter, setFilter] = useState(MyAutoPoolType.ICHI)
  const filter = MyAutoPoolType.ICHI
  const { account, chainId } = useWeb3React()
  const { openWalletModal } = useWalletModal()

  const ichiPositionsQuery = useUserIchiPositions({ enabled: true })
  const steerPostionsQuery = useSteerPositionsQuery()

  useEffect(() => {
    setOpenedPositionIndex(-1)
  }, [filter])

  const renderPositions = () => {
    switch (filter) {
      case MyAutoPoolType.ICHI: {
        if (ichiPositionsQuery.isLoading) {
          return (
              <div className='flex justify-center mt-8'>
                <Spinner/>
              </div>
          )
        }

        if (ichiPositionsQuery.data?.length > 0) {
          const ichiPosition = ichiPositionsQuery.data?.filter((position) => position.share.gt(0))
          if(ichiPosition.length) {
            return (
                <div className='mt-8 md:mt-[9px]'>
                  {ichiPosition.map((position, id) => {
                    return <IchiPosition openedPositionIndex={openedPositionIndex}
                                         setOpenedPositionIndex={setOpenedPositionIndex} key={position.vault.vaultAddress} id={id} position={position}/>
                  })}
                </div>
            )
          }else{
            return (
                <p className='mt-8 mb-4 text-lg f-f-fg leading-[22px] text-[#DEDBF2] font-light text-center'>
                  You do not have any liquidity positions.
                </p>
            )
          }
        }

        return (
            <p className='mt-8 mb-4 text-lg f-f-fg leading-[22px] text-[#DEDBF2] font-light text-center'>
              You do not have any liquidity positions.
            </p>
        )
      }

      case MyAutoPoolType.STEER: {
        if (steerPostionsQuery.isLoading) {
          return (
              <div className='flex justify-center mt-8'>
                <Spinner/>
              </div>
          )
        }

        if (steerPostionsQuery.data?.length > 0) {
          const steerPosition = steerPostionsQuery.data?.filter((position) => position.shareBalance.gt(0))
          if(steerPosition.length) {
            return (
                <div className='mt-8 md:mt-[9px]'>
                  {steerPosition.map((position, index) => {
                    return (
                        <SteerPosition
                            openedPositionIndex={openedPositionIndex}
                            setOpenedPositionIndex={setOpenedPositionIndex}
                            key={position.id}
                            index={index}
                            position={position}
                        />
                    )
                  })}
                </div>
            )
          }
          else{
            return (
                <p className='mt-8 mb-4 text-lg f-f-fg leading-[22px] text-[#DEDBF2] font-light text-center'>
                  You do not have any liquidity positions.
                </p>
            )
          }
        }

        return (
            <p className='mt-8 mb-4 text-lg f-f-fg leading-[22px] text-[#DEDBF2] font-light text-center'>
              You do not have any liquidity positions.
            </p>
        )
      }

      default:
        return null
    }
  }

  return (
    <div
      className={`max-h-[550px] overflow-auto w-full  rounded-[15px] bg-componentPrimary py-3 px-4 lg:px-6 relative`}
    >
      <div className='flex items-center px-2 py-4'>
        <button className='w-5 md:w-auto mr-1.5 md:mr-5' onClick={() => {navigate('/liquidity')}}>
          <span className='block w-[20px] lg:w-[26px] text-white'>
            <BackIcon />
          </span>
        </button>
        <p className='text-lg lg:text-[20px] leading-[20px] font-semibold text-white'>
          Your Automatic LP
        </p>
      </div>
      {/*<div className='mt-[20px] flex justify-center items-center'>*/}
        {/*<TabFilter data={Object.values(MyAutoPoolType)} filter={filter} setFilter={setFilter} size={TabSizes.LARGE} />*/}
      {/*</div>*/}

      {!account || chainId !== defaultChainId ? (
        <StyledButton
          onClickHandler={openWalletModal}
          content={'CONNECT WALLET'}
          className='mb-4 mt-8 py-[13px] md:py-[14.53px] text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[15px]'
        />
      ) : (
        renderPositions()
      )}
    </div>
  )
}

export default MyAutoPools
