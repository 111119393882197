import { useGetMerklDataQuery } from 'hooks/queries/useGetMerklDataQuery'
import { useContext, useMemo } from 'react'
import { ZERO_VALUE } from 'utils/formatNumber'
import { useIchiVaultAssets } from './useIchiVaultAssets'
import BigNumber from 'bignumber.js'
import { getAddress } from 'ethers/lib/utils'
import usePrices from 'hooks/usePrices'
import { BaseAssetsConetext } from 'context/BaseAssetsConetext'
// import { useSteerStrategiesQuery } from 'hooks/queries/useSteerStrategiesQuery'
import {useAllPoolsQuery} from "./useGetAllManualPools";

export const useGetAllLiquidityPools = () => {
  const prices = usePrices()
  const baseAssets = useContext(BaseAssetsConetext)

  // const SteerStrategiesQuery =  useSteerStrategiesQuery()
  const ichiVaultAssets = useIchiVaultAssets()
  const { merklDataQuery } = useGetMerklDataQuery()
  const allPoolsQuery = useAllPoolsQuery()

  // const steerAssetsReady = Boolean(SteerStrategiesQuery?.data?.[0]?.pool);
  const allPoolsReady = Boolean(allPoolsQuery?.data?.[0]?.id);
  const ichiAssetsReady = Boolean(ichiVaultAssets?.[0]?.vault?.poolAddress);

  // const filterAutoPools = (allPools, ichiPools, steerPools) => {
  //   // Extract pool addresses from ichiPools and steerPools
  //   const ichiPoolAddresses = ichiPools?.map(pool => pool?.vault?.poolAddress?.toLowerCase());
  //   const steerPoolAddresses = steerPools?.data?.map(pool => pool?.pool?.toLowerCase());
  //
  //   // Filter out pools from allPools that are in ichiPools or steerPools
  //   const filteredPools = allPools?.data?.filter(pool =>
  //       !ichiPoolAddresses?.includes(pool?.id?.toLowerCase()) && !steerPoolAddresses?.includes(pool?.id?.toLowerCase())
  //   );
  //
  //   return filteredPools;
  // }
  //
  // const allManualPools = (steerAssetsReady && ichiAssetsReady && allPoolsReady) ? filterAutoPools(allPoolsQuery, ichiVaultAssets, SteerStrategiesQuery) : [];

  const getPoolData = (merklPool) => {
    const apr = merklPool?.meanAPR ?? ZERO_VALUE
    const userTvl = merklPool?.userTVL ?? ZERO_VALUE
    const gaugeEarned = merklPool?.rewardsPerToken ?? {}
    const totalTvl = new BigNumber(merklPool?.tvl ?? ZERO_VALUE)

    const earnedUsd = gaugeEarned
      ? Object.entries(gaugeEarned).reduce((acc, [gaugeAddress, rewardData]) => {
          const price = prices[rewardData.symbol]

          if (price) {
            acc[gaugeAddress] = new BigNumber(price).times(rewardData.unclaimed)
          }

          return acc
        }, {})
      : {}

    const earnedUsdTotal = Object.values(earnedUsd).reduce((acc, earned) => earned.plus(acc), new BigNumber(0))

    const userTotalBalance0 = merklPool?.userTotalBalance0
    const userTotalBalance1 = merklPool?.userTotalBalance1

    return { apr, userTvl, totalTvl, earnedUsd: earnedUsdTotal, userTotalBalance0, gaugeEarned, userTotalBalance1 }
  }

  const ichiPools = useMemo(
    () =>
      (ichiAssetsReady && allPoolsReady ? ichiVaultAssets : [])
        .map((ichiVaultAsset) => {
          const merklPool = merklDataQuery.data?.pools?.[getAddress(ichiVaultAsset?.vault?.poolAddress)]

          const { apr, userTvl, earnedUsd, gaugeEarned, userTotalBalance0, userTotalBalance1 } =
            getPoolData(merklPool)

          const correspondingPool = allPoolsQuery.data.find(t =>
                  (t.token0.address.toLowerCase() === ichiVaultAsset.firstAsset.address.toLowerCase()
                      && t.token1.address.toLowerCase() === ichiVaultAsset.secondAsset.address.toLowerCase()) ||
                  (t.token0.address.toLowerCase() === ichiVaultAsset.secondAsset.address.toLowerCase() &&
                      t.token1.address.toLowerCase() === ichiVaultAsset.firstAsset.address.toLowerCase()))

          return {
            ...ichiVaultAsset,
            fee: ichiVaultAsset.vault.feeTier * 10000,
            alm: 'ICHI',
            symbol: ichiVaultAsset.name,
            title: ichiVaultAsset.name,
            gauge: {
              apr: new BigNumber(apr),
            },
            token0: {
              name: ichiVaultAsset.name,
              symbol: ichiVaultAsset.symbol,
              price: ichiVaultAsset.price,
              decimals: ichiVaultAsset.decimals,
              address: ichiVaultAsset.address,
              logoURI: ichiVaultAsset.logoURI,
              balance: ichiVaultAsset.balance,
              chainId: ichiVaultAsset.chainId,
            },
            poolAddress: ichiVaultAsset.vault.poolAddress,
            token1: ichiVaultAsset?.secondAsset,
            account: {
              earnedUsd,
              gaugeEarned,
              totalUsd: new BigNumber(userTvl) ?? ZERO_VALUE,
              total0: new BigNumber(userTotalBalance0 ?? 0),
              total1: new BigNumber(userTotalBalance1 ?? 0),
              lpBalance: new BigNumber(userTvl) ?? ZERO_VALUE,
              gaugeBalance: ZERO_VALUE,
            },
            // totalTvl: totalTvl,
            totalTvl: (correspondingPool?.totalValueLockedToken0 * correspondingPool?.token0.price +
                correspondingPool?.totalValueLockedToken1 * correspondingPool?.token1.price),
            totalToken0Balance: correspondingPool?.totalValueLockedToken0,
            totalToken1Balance: correspondingPool?.totalValueLockedToken1,
            fullMerklData: merklPool,
          }
        })
        .filter((asset) => !asset.vault.disabled),
    [ichiVaultAssets],
  )

  const allPools = useMemo(
      () =>
          (allPoolsReady ? allPoolsQuery.data : [])
              .map((position) => {
                const tokenA = baseAssets?.find((asset) => asset.address.toLowerCase() === position.token0.address.toLowerCase())
                const tokenB = baseAssets?.find((asset) => asset.address.toLowerCase() === position.token1.address.toLowerCase())

                const poolAddress = position.id

                if (!tokenA || !tokenB) return

                const merklPool = merklDataQuery.data?.pools?.[Object.keys(merklDataQuery.data?.pools || {}).find(key => key.toLowerCase() === poolAddress)];

                const { apr, userTvl, earnedUsd, gaugeEarned, userTotalBalance0, userTotalBalance1 } =
                    getPoolData(merklPool)

                const poolName = `${tokenA.symbol}/${tokenB.symbol} (${position.fee / 10000})%`

                return {
                  fee: position.feeTier,
                  alm: 'MANUAL',
                  symbol: poolName,
                  title: poolName,
                  gauge: {
                    apr: new BigNumber(apr),
                  },
                  token0: tokenA,
                  token1: tokenB,
                  poolAddress,
                  account: {
                    earnedUsd,
                    gaugeEarned,
                    totalUsd: new BigNumber(userTvl) ?? ZERO_VALUE,
                    total0: new BigNumber(userTotalBalance0 ?? 0),
                    total1: new BigNumber(userTotalBalance1 ?? 0),
                    lpBalance: new BigNumber(userTvl) ?? ZERO_VALUE,
                    gaugeBalance: ZERO_VALUE,
                  },
                  totalTvl: (position.totalValueLockedToken0 * tokenA.price + position.totalValueLockedToken1 * tokenB.price),
                  totalToken0Balance: position.totalValueLockedToken0,
                  totalToken1Balance: position.totalValueLockedToken1,
                  fullMerklData: merklPool,
                }
              })
              .filter((pool) => pool !== undefined),
      [allPoolsQuery],
  )

  // const steerPools = useMemo(
  //   () =>
  //     (steerAssetsReady ? SteerStrategiesQuery.data : []).map((strategy) => {
  //       const merklPool = merklDataQuery.data?.pools?.[getAddress(strategy.pool)]
  //
  //       const { apr, userTvl, totalTvl, earnedUsd, userTotalBalance0, gaugeEarned, userTotalBalance1 } =
  //         getPoolData(merklPool)
  //
  //       const poolName = `${strategy.token0Symbol}/${strategy.token1Symbol} (${strategy.feeTier / 10000}%)`
  //
  //       return {
  //         fee: Number(strategy.feeTier),
  //         alm: 'STEER',
  //         symbol: poolName,
  //         title: poolName,
  //         gauge: {
  //           apr: new BigNumber(apr),
  //         },
  //         token0: strategy.token0,
  //         token1: strategy.token1,
  //         poolAddress: strategy.pool,
  //         account: {
  //           earnedUsd,
  //           gaugeEarned,
  //           totalUsd: new BigNumber(userTvl) ?? ZERO_VALUE,
  //           total0: new BigNumber(userTotalBalance0 ?? 0),
  //           total1: new BigNumber(userTotalBalance1 ?? 0),
  //           lpBalance: new BigNumber(userTvl) ?? ZERO_VALUE,
  //           gaugeBalance: ZERO_VALUE,
  //         },
  //         totalTvl: totalTvl,
  //         fullMerklData: merklPool,
  //       }
  //     }),
  //   [SteerStrategiesQuery.data],
  // )

  const pools = [...allPools, ...ichiPools]

  return pools
}
