import React, { useState } from 'react'
import { useLocation, Link } from 'react-router-dom'

const Menu = ({ item, idx }) => {
  const route = useLocation()
  const [more, setMore] = useState(false)

  return (
    <li key={`main-${idx}`} className={`w-full`}>
      {item.dropdown ? (
        <div className='relative w-full'>
          <div
              onClick={() => {
              setMore(!more)
            }}
            className='flex items-center space-x-2 px-6 cursor-pointer font-semibold text-light hover:text-white transition-colors'
          >
            <img
              alt='dropdown'
              src='/images/header/dropdown-arrow.svg'
              className={`${!more ? 'rotate-180' : 'rotate-0'} transition-all duration-150 ease-in-out`}
            />
            <span>{item.name}</span>
          </div>
          {more && (
              <div
                  onClick={() => {
                    setMore(!more)
                  }}
                  className='bg-amber-300 relative block font-light text-white'
              >
                {item.link.map((_item, j) => {
                  return _item.external ? (
                      <div
                          onClick={() => {
                            window.open(_item.link, '_blank')
                          }}
                          key={`more-${j}`}
                          className='py-2 cursor-pointer hover:opacity-70'
                      >
                        {_item.name}
                      </div>
                  ) : (
                      <div className={'flex flex-col'}>
                        <Link
                            key={`more-${j}`}
                            onClick={() => setMore(false)}
                            className={`flex hover:opacity-70 items-center py-2 font-light text-white ${
                                route.pathname.includes(item.link) ? 'text-white' : ''
                            }`}
                            to={_item.link}
                        >
                          {_item.name}
                        </Link>
                      </div>
                  )
                })}
              </div>
          )}
        </div>
      ) : (
        <Link
          className={`items-center px-4 font-semibold text-light hover:text-white transition-colors ${
            route.pathname.includes(item.link) ? 'text-white' : ''
          }`}
          to={item.link}
          target={item.external && '_blank'}
        >
          {item.name}
        </Link>
      )}
    </li>
  )
}

export default Menu
