import React, { useEffect, useState } from 'react'
import V3AddLiquidity from '../../components/pages/liquidity/v3/addLiquidity'
import { EnterAmounts } from 'components/pages/liquidity/v3/addLiquidity/containers/EnterAmounts'
import { useCurrency } from 'hooks/v3/Tokens'
import { useV3DerivedMintInfo, useV3MintActionHandlers, useV3MintState } from 'state/mintV3/hooks'
import { AddLiquidityButton } from 'components/pages/liquidity/v3/addLiquidity/containers/AddLiquidityButton'
import { useIchiVaultAssets } from 'hooks/v3/useIchiVaultAssets'
import { v3LiquidityRangeType } from 'v3lib/entities/constants'
import { EnterIchiAmount } from 'components/pages/liquidity/v3/addLiquidity/containers/EnterIchiAmount'
import {useSteerStrategiesQuery} from "../../hooks/queries/useSteerStrategiesQuery";

const AddLiquidity = () => {
  const [firstAsset, setFirstAsset] = useState()
  const [secondAsset, setSecondAsset] = useState()

  const baseCurrency = useCurrency(firstAsset ? firstAsset.address : undefined)
  const currencyB = useCurrency(secondAsset ? secondAsset.address : undefined)
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  const baseCurrencyAddress = baseCurrency?.address?.toLowerCase()
  const quoteCurrencyAddress = quoteCurrency?.address?.toLowerCase()

  const mintInfo = useV3DerivedMintInfo(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    baseCurrency ?? undefined,
    undefined,
  )

  const { liquidityRangeType } = useV3MintState()
  const { onChangeLiquidityRangeType } = useV3MintActionHandlers()

  const [settings, setSettings] = useState(false)
  const [slippage, setSlippage] = useState(0.5)
  const [deadline, setDeadline] = useState(20)

  const currencyAAddress = baseCurrency && baseCurrency.wrapped ? baseCurrency.wrapped.address.toLowerCase() : ''
  const currencyBAddress = quoteCurrency && quoteCurrency.wrapped ? quoteCurrency.wrapped.address.toLowerCase() : ''

  const ichiVaultAssets = useIchiVaultAssets()
  const [ichiAsset, setIchiAsset] = useState()

  const filterIchiVaults = ichiVaultAssets.filter((asset) => {
    return (
        !asset?.vault?.disabled &&
        (asset?.vault?.depositTokenSymbol?.toLowerCase() === firstAsset?.symbol?.toLowerCase() &&
            (asset?.vault?.token1?.toLowerCase() === secondAsset?.symbol?.toLowerCase() ||
                asset?.vault?.token0?.toLowerCase() === secondAsset?.symbol?.toLowerCase()))
    )
  })
  const availableIchiVaults = filterIchiVaults.length != 0;

  const steerStategies = useSteerStrategiesQuery()

  const isSteerVaultAvailable = steerStategies.data?.find((strategy) => {
    return (
        (strategy?.token0?.address?.toLowerCase() === baseCurrencyAddress &&
            strategy?.token1?.address?.toLowerCase() === quoteCurrencyAddress) ||
        (strategy?.token0?.address?.toLowerCase() === quoteCurrencyAddress &&
            strategy?.token1?.address?.toLowerCase() === baseCurrencyAddress)
    )
  })

  useEffect(() => {
      onChangeLiquidityRangeType(null);
      onChangeLiquidityRangeType(null);
  }, [firstAsset, secondAsset])

  return (
    <div className='w-full flex justify-center flex-col max-w-[650px] px-6 pt-5 pb-10 xl:pt-20 md:pt-14 mx-auto'>
      <div className='max-w-[650px] mx-auto w-full'>
        <div className='flex flex-col items-center justify-center'>
          <V3AddLiquidity
            mintInfo={mintInfo}
            firstAsset={firstAsset}
            setFirstAsset={setFirstAsset}
            secondAsset={secondAsset}
            setSecondAsset={setSecondAsset}
            settings={settings}
            setSettings={setSettings}
            slippage={slippage}
            setSlippage={setSlippage}
            deadline={deadline}
            setDeadline={setDeadline}
            ichiVaultAssets={ichiVaultAssets}
            ichiAsset={ichiAsset}
            isIchiVaultAvailable = {availableIchiVaults}
            isSteerVaultAvailable= {isSteerVaultAvailable}
          />
          {currencyAAddress && currencyBAddress && <div className='w-full mb-5 rounded-[15px] bg-componentPrimary py-3 px-5'>
              <p className='text-left w-full text-[20px] font-semibold text-white m-0 mb-[15px] mt-[30px]'>
                Select Deposit Amounts
              </p>
              {availableIchiVaults && liquidityRangeType === v3LiquidityRangeType.ICHI_RANGE && (
                <EnterIchiAmount
                  mintInfo={mintInfo}
                  liquidityRangeType={liquidityRangeType}
                  firstAsset={firstAsset}
                  secondAsset={secondAsset}
                  ichiAsset={ichiAsset}
                  setIchiAsset={setIchiAsset}
                />
              )}
              {(liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE || (liquidityRangeType === v3LiquidityRangeType.STEER && isSteerVaultAvailable)) && (
                <EnterAmounts
                  currencyA={baseCurrency ?? undefined}
                  currencyB={currencyB ?? undefined}
                  mintInfo={mintInfo}
                />
              )}
              <AddLiquidityButton
                baseCurrency={baseCurrency ?? undefined}
                quoteCurrency={quoteCurrency ?? undefined}
                firstAsset={firstAsset}
                mintInfo={mintInfo}
                slippage={slippage}
                deadline={deadline}
                ichiVaultAssets={ichiVaultAssets}
                ichiAsset={ichiAsset}
              />
            </div>
          }
          </div>
      </div>
    </div>
  )
}

export default AddLiquidity
