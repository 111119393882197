import JSBI from 'jsbi'
import { useContext, useMemo } from 'react'
import { CurrencyAmount, Ether } from '@crust_finance/sdk-core'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'
import { toWei } from '../../utils/formatNumber'
import { ChainId } from 'config/constants'

export function useCurrencyBalances(currencies) {
  const baseAssets = useContext(BaseAssetsConetext)
  return useMemo(
    () =>
      currencies.map((currency) => {
        if (!currency) return undefined
        if (currency.isToken) {
          const address = currency.address
          if (!address) return undefined
          const found = baseAssets.find((asset) => asset.address === address.toLowerCase())
          const amount =
            found && found.balance ? JSBI.BigInt(toWei(found.balance, found.decimals).toString(10)) : undefined
          return amount ? CurrencyAmount.fromRawAmount(currency, amount) : undefined
        }
        if (currency.isNative) {
          const found = baseAssets.find((asset) => asset.address === 'MNT')
          if (!found) return undefined
          const val = toWei(found.balance).toString(10)
          return CurrencyAmount.fromRawAmount(Ether.onChain(ChainId.MANTLE), JSBI.BigInt(val))
        }
      }) ?? [],
    [baseAssets, currencies],
  )
}

export function useCurrencyBalance(currency) {
  return useCurrencyBalances([currency])[0]
}
