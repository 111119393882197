import React, {useState} from 'react'
import { KyberWidget } from 'components/pages/swap/KyberWidget'
import TabFilter from "../../components/common/TabFilter";
import {TabSizes} from "../../config/constants";
// import { Swap } from '@swing.xyz/ui';
// import '@swing.xyz/ui/dist/index.css';
// import "./swing.css";


const SwapTypes = {
    NORMAL: 'NORMAL',
    CROSS: 'CROSS CHAIN',
}

const Index = () => {
    const [filter, setFilter] = useState(SwapTypes.NORMAL)

    return (
    <div className='flex items-center justify-center p-5 mt-10 flex-col w-full h-full px-4'>
        <div className='flex justify-center mb-4'>
            <TabFilter data={Object.values(SwapTypes)} filter={filter} size={(window.innerWidth < 498) ? TabSizes.MEDIUM : TabSizes.LARGE} setFilter={setFilter}/>
        </div>
        {filter === SwapTypes.NORMAL ?
            <div className='backdrop-blur-[100px] backdrop-saturation-[110%] backdrop-brightness-[120%] border-[1px] border-white rounded-[15px]'>
                <KyberWidget />
            </div>
            : <div className={`${(window.innerWidth < 498) ? 'w-[340px]' : 'w-[400px]'} h-[600px] border-[1px] border-white rounded-[15px] text-white flex justify-center items-center`}>
                Coming Soon !!
                {/*<Swap projectId="crust-finance"/>*/}
            </div>
        }
    </div>
  )
}

export default Index
