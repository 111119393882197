import React, { useState, useEffect } from 'react'
import './style.scss'
import { useLocation, Link } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { connectors, defaultChainId } from '../../../config/constants'
import useWalletModal from '../../../hooks/useWalletModal'
import ChevronIcon from '../../../icons/ChevronIcon'
import LogoutIcon from '../../../icons/LogoutIcon'
import navLinks from '../Header/NavLinks'
import './style.scss'
import CloseModalIcon from '../../../icons/CloseModalIcon'
import { useWeb3React } from '@web3-react/core'
import useAuth from '../../../hooks/useAuth'

const MobileMenu = ({ item, idx, route }) => {
  return (
    <li key={idx} className={`links`}>
      <Link
        className={`${route.pathname === item.link && 'text-sky'} no-link text-white`}
        to={item.link}
      >
        {item.name}
      </Link>
    </li>
  )
}

const MobileNav = ({ isOpen, setIsOpen }) => {
  const route = useLocation()
  const [mobileDrop, setMobileDrop] = useState(false)
  const [connector, setConnector] = useState(null)
  const { account, chainId } = useWeb3React()
  const { logout } = useAuth()
  const [selected, setSelected] = useState(false)
  // const { isWalletOpen } = useSelector((state) => state.wallet)
  const { openWalletModal, closeWalletModal } = useWalletModal()

  useEffect(() => {
    setIsOpen(false)
    closeWalletModal()
    setMobileDrop(false)
  }, [route.pathname])

  useEffect(() => {
    if (account) {
      if (!connector && !selected) {
        setConnector(connectors[0])
        setSelected(false)
      }
    } else {
      setConnector(null)
    }
  }, [account, connector, selected, setSelected])

  const mobileDownhandler = () => {
    if (connector) {
      setMobileDrop(!mobileDrop)
    } else {
      openWalletModal()
    }
  }

  const onMobileDisconnect = () => {
    logout()
    setConnector(null)
    setMobileDrop(false)
  }

  return (
    <div className={`top-bg !z-[1000] xl:hidden ${isOpen ? 'top-0' : 'top-minus'}`}>
      <div className='inner-tab'>
        <div className='top-navigation'>
          <Link to='/'>
            <img className='logo-2' alt='' src='/images/header/logo.svg' />
          </Link>
          <div
            onClick={() => {
              setIsOpen(false)
              closeWalletModal()
            }}
            className='closeButton'
          >
            <span className='flex w-8 h-8'>
              <CloseModalIcon />
            </span>
          </div>
        </div>
        <div className='bottom-navigation w-full'>
          <ul className='flex flex-col items-center justify-center'>
            {navLinks.map((item, idx) => {
              return <MobileMenu item={item} idx={idx} route={route} key={`mobile-${idx}`} />
            })}
          </ul>
          {
            <OutsideClickHandler
              onOutsideClick={() => {
                setMobileDrop(false)
              }}
            >
              <div className='relative w-full mt-5'>
                <button
                  onClick={() => {
                    mobileDownhandler()
                  }}
                  className={` bg-white w-full min-w-[150px] max-w-[252px] xl:max-w-full xl:w-[252px] flex-shrink-0 py-2 px-4 xl:py-4 xl:px-10 rounded-[15px] items-center gap-x-1 xl:gap-x-2.5 font-semibold text-black text-sm md:text-sm relative z-20  xl:text-base leading-7`}
                >
                  {connector && chainId === defaultChainId ? (
                    <div className='flex items-center gap-x-1 xl:gap-x-2.5'>
                      <div className='flex items-center flex-shrink-0 gap-x-1 xl:gap-x-2.5'>
                        <img
                          src={connector.title == 'MetaMask' ? '/images/header/metamask.svg' : connector.logo}
                          className='max-w-[24px] h-6 '
                          alt=''
                        />
                        <p className=''>{account ? `${account.slice(0, 6)}...${account.slice(-4)}` : ''}</p>
                      </div>
                      <span
                        className={`${
                          mobileDrop ? ' rotate-180' : ' rotate-0'
                        } transition-all duration-300 ease-in-out transform w-full`}
                      >
                        <div className='w-6 h-6 flex-shrink-0'>
                          <ChevronIcon />
                        </div>
                      </span>
                    </div>
                  ) : (
                    <span className='w-full'>Connect Wallet</span>
                  )}

                  {mobileDrop && (
                    <div className='absolute left-0 w-full bg-grayDark rounded-[15px] top-[50px] xl:top-[70px] block z-[101]'>
                      <button
                        onClick={onMobileDisconnect}
                        className='flex items-center gap-x-2.5 w-full py-[8px] xl:py-[15px] px-5 xl:px-10 text-white'
                      >
                        <span className='w-4 xl:w-6 h-4 xl:h-6'>
                          <LogoutIcon />
                        </span>
                        <p className='flex-shrink-0 text-sm xl:text-base text-white'>Logout</p>
                      </button>
                    </div>
                  )}
                </button>
              </div>
            </OutsideClickHandler>
          }
        </div>
      </div>
    </div>
  )
}

export default MobileNav
