import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { v4 as uuidv4 } from 'uuid'
import { TransactionType } from '../../config/constants'
import { useActivePreset, useV3MintActionHandlers } from '../../state/mintV3/hooks'
import useWeb3 from '../useWeb3'
import { completeTransaction, openTransaction } from '../../state/transactions/actions'
import { getIchiVaultContract } from '../../utils/contractHelpers'
import { sendContract } from '../../utils/api'

export const useIchiRemove = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const preset = useActivePreset()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers()

  const handleIchiemove = useCallback(
    async (amount, asset) => {
      const ichiVault = asset ? asset.vault : undefined
      const ichiVaultContract = getIchiVaultContract(web3, ichiVault.vaultAddress)

      const key = uuidv4()
      const removeuuid = uuidv4()

      setPending(true)

      dispatch(
        openTransaction({
          key,
          title: `Withdraw ${asset.vault.depositTokenSymbol} liquidity`,
          transactions: {
            [removeuuid]: {
              desc: `Withdraw tokens from the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      try {
        await sendContract(
          dispatch,
          key,
          removeuuid,
          ichiVaultContract,
          'withdraw',
          [amount.toString(), account],
          account,
        )
      } catch (err) {
        console.log('remove error :>> ', err)
        setPending(false)
        return
      }

      onFieldAInput('')
      onFieldBInput('')

      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Removed',
        }),
      )
      setPending(false)
    },
    [account, web3, preset],
  )

  return { onIchiRemove: handleIchiemove, pending }
}
