import { ChainId } from 'config/constants'

export const V3_CORE_FACTORY_ADDRESSES = {
  [ChainId.MANTLE]: '0xEaD128BDF9Cff441eF401Ec8D18a96b4A2d25252',
}

export const POOL_DEPLOYER_ADDRESS = {
  [ChainId.MANTLE]: '0xEaD128BDF9Cff441eF401Ec8D18a96b4A2d25252',
}

export const QUOTER_ADDRESSES = {
  [ChainId.MANTLE]: '0x8f51a95226F8bAd1b4b3a0F61F6A53D084A7E033',
}

export const FUSION_ROUTER_ADDRESSES = {
  [ChainId.MANTLE]: '0xCf43e529E8c3172C2D30aF4c27319acd09ce504d',
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = {
  [ChainId.MANTLE]: '0x9c3BDe004b6383f296112536B1E3612e9229D98d',
}

export const MULTICALL_ADDRESS = {
  [ChainId.MANTLE]: '0xBC0341C40e6AbFb918770D1eb1892aed40D0000c',
}
