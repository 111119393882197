import { isAddress } from '@ethersproject/address'
import { useMemo } from 'react'
import {
  getAlgebraContract,
  getERC20Contract,
  getExtraRewarder,
  getMinterContract,
  getOptionContract,
  getRewardsAPIContract,
  getRouterContract,
  getV3VoterContract,
  getVeDistContract,
  getVeCRSTContract,
  getVoterContract,
  getBribeOptionContract, getSteerPeriphery, getSteerVault,
} from '../utils/contractHelpers'
import useWeb3 from './useWeb3'

export const useERC20 = (address) => {
  const web3 = useWeb3()
  return useMemo(() => {
    if (!isAddress(address)) return null
    return getERC20Contract(address, web3)
  }, [address, web3])
}

export const useRouter = () => {
  const web3 = useWeb3()
  return useMemo(() => getRouterContract(web3), [web3])
}

export const useVeTHE = () => {
  const web3 = useWeb3()
  return useMemo(() => getVeCRSTContract(web3), [web3])
}

export const useVeDist = () => {
  const web3 = useWeb3()
  return useMemo(() => getVeDistContract(web3), [web3])
}

export const useVoter = () => {
  const web3 = useWeb3()
  return useMemo(() => getVoterContract(web3), [web3])
}

export const useMinter = () => {
  const web3 = useWeb3()
  return useMemo(() => getMinterContract(web3), [web3])
}

export const useRewardsApi = () => {
  const web3 = useWeb3()
  return useMemo(() => getRewardsAPIContract(web3), [web3])
}

export const useAlgebra = () => {
  const web3 = useWeb3()
  return useMemo(() => getAlgebraContract(web3), [web3])
}

export const useV3Voter = () => {
  const web3 = useWeb3()
  return useMemo(() => getV3VoterContract(web3), [web3])
}

export const useExtraRewarder = () => {
  const web3 = useWeb3()
  return useMemo(() => getExtraRewarder(web3), [web3])
}

export const useOptionContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getOptionContract(web3), [web3])
}

export const useBribeOptionContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getBribeOptionContract(web3), [web3])
}

export const useSteerPeripheryContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getSteerPeriphery(web3), [web3])
}

export const useSteerVaultContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getSteerVault(web3, address), [web3])
}
