import React, { useCallback, useState } from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import Modal from '../../../../../common/Modal'
import StyledButton from '../../../../../common/Buttons/styledButton'
import { useDerivedV3BurnInfo } from '../../../../../../hooks/v3/useDerivedV3BurnInfo'
import useDebounce from '../../../../../../hooks/useDebounce'
import { useAlgebraRemove } from '../../../../../../hooks/v3/useAlgebra'
import { customNotify } from '../../../../../../utils/notify'
import { useCurrencyLogo } from '../../../../../../hooks/v3/useCurrencyLogo'

const slippage = 0.5
const deadline = 1200

const percentArray = [25, 50, 75, 100]
const RemoveModal = ({ isOpen, setIsOpen, position }) => {
  const [percent, setPercent] = useState(0)
  const debouncedPercent = useDebounce(percent)
  const { tokenId, liquidity } = position
  const derivedInfo = useDerivedV3BurnInfo(position?.fee, position, debouncedPercent)
  const { liquidityValue0, liquidityValue1, feeValue0, feeValue1, outOfRange } = derivedInfo
  const { pending, onAlgebraRemove } = useAlgebraRemove(derivedInfo)
  const logoURIA = useCurrencyLogo(liquidityValue0?.currency)
  const logoURIB = useCurrencyLogo(liquidityValue1?.currency)

  const onRemove = useCallback(() => {
    if (debouncedPercent > 0) {
      onAlgebraRemove(tokenId, slippage, deadline)
    } else {
      customNotify('Invalid Amount', 'warn')
    }
  }, [tokenId, derivedInfo, debouncedPercent])

  const removed = liquidity?.eq(0)

  return (
    <Modal popup={isOpen} setPopup={setIsOpen} title={'Remove Liquidity'} width={548}>
      <div className='pt-5 pb-3 rounded-[5px] mt-[13px]'>
        <div className='flex items-start md:items-center justify-between cursor-pointer'>
          <div className='flex items-center space-x-3 '>
            <div className='flex items-center'>
              <img alt='' className='w-6 lg:w-[30px] relative shadow' src={logoURIA} />
              <img alt='' className='w-6 lg:w-[30px] -ml-3' src={logoURIB} />
            </div>
            <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>
              {liquidityValue0?.currency.symbol}/{liquidityValue1?.currency.symbol}
            </p>
          </div>
          <div className='bg-white bg-opacity-[0.05] py-1 pl-3 mt-2 md:mt-0 rounded-[13px] flex items-center space-x-[5px] pr-4 flex-shrink-0'>
            <div className={`${removed ? 'bg-red-700' : !outOfRange ? 'bg-[#55A361]' : 'bg-red-700'} w-2 h-2 bg-[#55A361] rounded-full`} />
            <span className='text-[15px] fonts-medium text-white whitespace-nowrap'>{removed ? 'Closed' : !outOfRange ? 'In range' : 'Out of range'}</span>
          </div>
        </div>
        <div className='mt-5 flex items-center justify-between'>
          <span className='text-[#E0DEF0] leading-5'>Amount</span>
          <div className='flex items-center space-x-2.5'>
            {percentArray.map((percent, index) => {
              return (
                <div
                  className={`flex items-center justify-center bg-white bg-opacity-[0.05] rounded-[15px] text-white text-[13px] md:text-base w-[40px] md:w-[56px] h-[22px] md:h-[28px] cursor-pointer`}
                  onClick={() => setPercent(percent)}
                  key={`percent-${index}`}
                >
                  {percent}%
                </div>
              )
            })}
          </div>
        </div>
        <div className='w-full px-2.5 md:px-4 pt-4 md:pt-5 bg-white bg-opacity-[0.05] rounded-[3px] pb-5 md:pb-[22px] mt-1 md:mt-[7px]'>
          <div className='text-lg md:text-2xl leading-5 md:leading-[29px] text-white'>{percent}%</div>
          <Slider
            min={0}
            max={100}
            className='!mt-3 mb-0'
            value={percent}
            onChange={(value) => {
              setPercent(value)
            }}
          />
        </div>
        <div className='mt-3 w-full'>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px]'>
            <span>Pooled {liquidityValue0?.currency.symbol}</span>
            <span>{liquidityValue0?.toSignificant()}</span>
          </div>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px] mt-2 md:mt-3'>
            <span>Pooled {liquidityValue1?.currency.symbol}</span>
            <span>{liquidityValue1?.toSignificant()}</span>
          </div>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px] mt-2 md:mt-3'>
            <span>{feeValue0?.currency.symbol} Fees Earned:</span>
            <span>{feeValue0?.toSignificant()}</span>
          </div>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px] mt-2 md:mt-3'>
            <span>{feeValue1?.currency.symbol} Fees Earned:</span>
            <span>{feeValue1?.toSignificant()}</span>
          </div>
        </div>

        <StyledButton
          pending={pending}
          onClickHandler={onRemove}
          content={'Withdraw'}
          className='mt-4 py-[13px] md:py-[14.53px] text-black text-sm md:text-lg leading-4 flex items-center justify-center md:leading-[30px] px-[19px] w-full rounded-[15px]'
        />
      </div>
    </Modal>
  )
}

export default RemoveModal
