import React from 'react'

const BackIcon = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.9874 29.2374C16.304 29.9208 15.196 29.9208 14.5126 29.2374L4.01256 18.7374C3.32915 18.054 3.32915 16.946 4.01256 16.2626L14.5126 5.76256C15.196 5.07915 16.304 5.07915 16.9874 5.76256C17.6708 6.44598 17.6708 7.55401 16.9874 8.23744L9.47487 15.75H29.75C30.7165 15.75 31.5 16.5335 31.5 17.5C31.5 18.4665 30.7165 19.25 29.75 19.25H9.47487L16.9874 26.7626C17.6708 27.4459 17.6708 28.5541 16.9874 29.2374Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default BackIcon
