import { ConnectorNames } from '../../utils/connectors'
import {logoURIMap} from "./logo";

const connectorLocalStorageKey = 'crust-local-key'
// eslint-disable-next-line no-use-before-define
const defaultChainId = Number(process.env.REACT_APP_CHAIN_ID)

const ChainId = {
  MANTLE: 5000,
}

const TransactionType = {
  START: 'start',
  WAITING: 'waiting',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
}

const connectors = [
  {
    key: 'metamask',
    logo: '/images/wallets/metamask-logo.svg',
    title: 'MetaMask',
    connector: ConnectorNames.MetaMask,
  },
  {
    key: 'trustwallet',
    logo: '/images/wallets/trustwallet-logo.svg',
    title: 'Trust Wallet',
    connector: ConnectorNames.TrustWallet,
  },
  {
    key: 'walletConnect',
    logo: '/images/wallets/walletconnect-logo.svg',
    title: 'Wallet Connect',
    connector: ConnectorNames.WalletConnect,
  },
  {
    key: 'coinbase',
    logo: '/images/wallets/coinbase-wallet-logo.svg',
    title: 'Coinbase Wallet',
    connector: ConnectorNames.Coinbase,
  },
]

const routeAssets = [
  {
    name: 'Wrapped Mantle',
    symbol: 'WMNT',
    address: '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8',
    chainId: 5000,
    decimals: 18,
    logoURI: logoURIMap["wMNT"].logoURI,
  },
  {
    name: 'USD Circle',
    symbol: 'USDC',
    address: '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
    chainId: 5000,
    decimals: 6,
    logoURI: logoURIMap["USDC"].logoURI,
  },
  {
    name: 'CRUST',
    symbol: 'CRST',
    address: '0x0d4b4d63149cf37de1C996D3e5b6f0d9CC3Be2e3',
    chainId: 5000,
    decimals: 18,
    logoURI: logoURIMap["CRST"].logoURI,
  },
  {
    name: 'Tether USD',
    symbol: 'USDT',
    address: '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE',
    chainId: 5000,
    decimals: 6,
    logoURI: 'https://retro.finance/images/tokens/USDT.png',
  },
  {
    name: 'Wrapped ETH',
    symbol: 'WETH',
    address: '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111',
    chainId: 5000,
    decimals: 18,
    logoURI: 'https://retro.finance/images/tokens/WETH.png',
  },
]
const periodLevels = [
  {
    value: 0,
    label: '2 weeks',
  },
  {
    value: 1,
    label: '6 months',
  },
  {
    value: 2,
    label: '1 year',
  },
  {
    value: 3,
    label: '2 years',
  },
]

const NumberOfRows = [10, 20, 30, 40]

const PoolTypes = {
  ALL: 'ALL',
  ICHI: 'ICHI',
  // STEER: 'STEER',
}

const TabSizes = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
}

const TaxAssets = [
  '0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979', // fBOMB
  '0xc95cd75dcea473a30c8470b232b36ee72ae5dcc2', // CHAM
  '0x3a806a3315e35b3f5f46111adb6e2baf4b14a70d', // LIBERA
  '0x9a7b04fd5788ea39819723e7eb9ef5f609bc57ab', // cpTHE
]
const NewPools = []

const STABLE_TOKENS = {
  USDT: '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE',
  USDC: '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
}

export {
  connectorLocalStorageKey,
  defaultChainId,
  connectors,
  TransactionType,
  routeAssets,
  periodLevels,
  NumberOfRows,
  PoolTypes,
  TaxAssets,
  STABLE_TOKENS,
  NewPools,
  TabSizes,
  ChainId,
}
