import React from 'react'
import { TokenAmountCard } from '../components/TokenAmountCard'
import { Field } from '../../../../../../state/mintV3/actions'
// import { maxAmountSpend } from '../../../../../../v3lib/utils/utils'
import { useV3MintActionHandlers, useV3MintState } from '../../../../../../state/mintV3/hooks'
import { useCurrencyBalance } from 'hooks/v3/useCurrencyBalances'

export const EnterAmounts = ({ currencyA, currencyB, mintInfo, liquidityRangeType }) => {
  const { independentField, typedValue } = useV3MintState()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers(mintInfo.noLiquidity)
  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [mintInfo.dependentField]: mintInfo.parsedAmounts[mintInfo.dependentField]?.toExact() ?? '',
  }

  const balanceA = useCurrencyBalance(currencyA)
  const balanceB = useCurrencyBalance(currencyB)

  return (
    <div className='mt-4 md:mt-5'>
      <p className='text-[13px] md:text-base leading-5 text-[#B8B6CB]'>Deposit Amounts</p>
      <div className='mt-3'>
        <TokenAmountCard
          currency={currencyA}
          value={formattedAmounts[Field.CURRENCY_A]}
          handleInput={onFieldAInput}
          maxAmount={balanceA}
          locked={mintInfo.depositADisabled}
          liquidityRangeType={liquidityRangeType}
        />
      </div>
      <div className='mt-5'>
        <TokenAmountCard
          currency={currencyB}
          value={formattedAmounts[Field.CURRENCY_B]}
          handleInput={onFieldBInput}
          maxAmount={balanceB}
          locked={mintInfo.depositBDisabled}
          liquidityRangeType={liquidityRangeType}
        />
      </div>
    </div>
  )
}
