import React, { useEffect, useState } from 'react'
import { maxAmountSpend } from '../../../../../../v3lib/utils/utils'
import { useV3MintActionHandlers, useV3MintState } from '../../../../../../state/mintV3/hooks'
import { v3LiquidityRangeType } from 'v3lib/entities/constants'
import SearchTokenPopup from 'components/common/SearchTokenPopup'
import { useIchiVaultAssets } from 'hooks/v3/useIchiVaultAssets'
import { IchiTokenSelect } from '../components/IchiTokenSelect'
import { Field } from 'state/mintV3/actions'

export const EnterIchiAmount = ({ ichiAsset, setIchiAsset, firstAsset, secondAsset, mintInfo, liquidityRangeType }) => {
  const [isTokenModalOpen, setTokenModalOpen] = useState(false)

  const { typedValue } = useV3MintState()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers(mintInfo.noLiquidity)

  const ichiVaultAssets = useIchiVaultAssets()

  const availableIchiVaults = ichiVaultAssets.filter((asset) => {
    return (
      !asset?.vault?.disabled &&
      (asset?.vault?.depositTokenSymbol?.toLowerCase() === firstAsset?.symbol?.toLowerCase() &&
          (asset?.vault?.token1?.toLowerCase() === secondAsset?.symbol?.toLowerCase() ||
        asset?.vault?.token0?.toLowerCase() === secondAsset?.symbol?.toLowerCase()))
    )
  })

  useEffect(() => {
      console.log(availableIchiVaults);
    setIchiAsset(availableIchiVaults?.[0])
  }, [firstAsset, secondAsset])

  const ichiAmount = Object.entries(mintInfo.currencyBalances ?? {}).find(
    ([, currencyAmount]) => currencyAmount?.currency?.symbol === ichiAsset?.vault?.depositTokenSymbol,
  )

  const ichiField = ichiAmount?.[0]

  const maxAmount = maxAmountSpend(mintInfo.currencyBalances[ichiField])

  return (
    <div className='mt-4 md:mt-5'>
      <SearchTokenPopup
        hideCommon={true}
        popup={isTokenModalOpen}
        setPopup={setTokenModalOpen}
        selectedAsset={ichiAsset}
        setSelectedAsset={setIchiAsset}
        baseAssets={availableIchiVaults}
      />
      <p className='text-[13px] md:text-base leading-5 text-[#B8B6CB]'>Deposit Amounts</p>
      <div className='mt-3'>
        {liquidityRangeType === v3LiquidityRangeType.ICHI_RANGE && (
          <IchiTokenSelect
            setTokenModalOpen={setTokenModalOpen}
            locked={!ichiAsset}
            asset={ichiAsset}
            value={typedValue}
            handleInput={ichiField === Field.CURRENCY_A ? onFieldAInput : onFieldBInput}
            maxAmount={maxAmount}
          />
        )}
      </div>
    </div>
  )
}
