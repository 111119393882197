import { useQuery } from '@tanstack/react-query'
import { useWeb3React } from '@web3-react/core'
import { ERC20Abi, pairAbi } from 'config/abi'

import { multicallFailSafe } from 'utils/multicall'
import {useSteerStrategiesQuery} from './useSteerStrategiesQuery'
import Big from 'big.js'
import {BigNumber} from 'ethers'

import { chunkArr } from 'utils/chunkArray'
// import { contracts } from '@steerprotocol/contracts/deployments/mantle.json'

export const useSteerPositionsQuery = () => {
  const { account } = useWeb3React()

  const steerStrategies = useSteerStrategiesQuery();

  return useQuery(
    ['steer-positions', account],
    async () => {
      const calls = steerStrategies.data.map((strategy) => {
        return { name: 'balanceOf', address: strategy.id, params: [account] }
      })

      const callsPairs = steerStrategies.data.flatMap((strategy) => [
        { name: 'token0', address: strategy.pool },
        { name: 'token1', address: strategy.pool },
      ])

      // const vaultBalance = steerStrategies?.data?.map((strategy) => {
      //     return { name: 'vaultBalancesByAddressWithFees', address: contracts.SteerPeriphery.address, params: [strategy.id] }
      // })
      //
      // const vaultDetails = steerStrategies?.data?.map((strategy) => {
      //     return { name: 'vaultDetailsByAddress', address: contracts.SteerPeriphery.address, params: [strategy.id] }
      // })

      const responsePairs = await multicallFailSafe(pairAbi, callsPairs)

      const chunksPairs = chunkArr(responsePairs, 2)

      try {
        const response = await multicallFailSafe(ERC20Abi, calls)

        // const vaultResponse = await multicallFailSafe(contracts.SteerPeriphery.abi, vaultBalance);
        // const vaultDetailResponse = await multicallFailSafe(contracts.SteerPeriphery.abi, vaultDetails);
        //
        // console.log(vaultResponse, vaultDetailResponse);

        return response.map((item, index) => {
          const strategy = steerStrategies.data[index];
          const [[token0], [token1]] = chunksPairs[index];

          const amount0 = BigNumber.from(strategy?.token0Balance);
          const amount1 = BigNumber.from(strategy?.token1Balance);

          const totalSupply = BigNumber.from(strategy?.totalLPTokensIssued);

          const lpTokenValue = BigNumber.from(item.balance.toString());

          const balance0 = totalSupply
            ? (amount0.mul(lpTokenValue)).div(totalSupply)
            : BigNumber.from(0)

          const balance1 = totalSupply
            ? (amount1.mul(lpTokenValue)).div(totalSupply)
            : BigNumber.from(0)

            return {
            shareBalance: Big(item.balance.toString()),
            balances: {
              [token0?.toLowerCase()]: balance0,
              [token1?.toLowerCase()]: balance1,
            },
            // vaultResponse,
            // vaultDetailResponse,
            ...strategy,
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    { enabled: Boolean(account) && Boolean(steerStrategies.data) },
  )
}
