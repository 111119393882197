import { FeeAmount } from 'hooks/v3/computePoolAddress'
// import { useEffect } from 'react'
// import { Tooltip } from 'react-tooltip'
import { useV3MintActionHandlers, useV3MintState } from 'state/mintV3/hooks'
// import { ReactComponent as QuestionMarkLogo } from 'icons/question-mark.svg'

const feeAmounts = Object.entries(FeeAmount).map(([name, fee]) => ({ name, fee }))

export const SelectFeeAmount = () => {
  const { onSetFeeAmount } = useV3MintActionHandlers()
  const { feeAmount } = useV3MintState()

  return (
    <div className='flex space-x-3'>
      {feeAmounts.map(({ fee, name }) => (
        <button
          onClick={() => {
            onSetFeeAmount(fee)
          }}
          className={`py-[9px] w-full max-w-[33%] flex flex-col items-center justify-center rounded-[15px]  ${
            feeAmount === fee ? ' text-black bg-grayHover font-semibold' : 'text-white bg-white bg-opacity-[0.05]'
          } text-[13px] md:text-sm leading-4 uppercase tracking-[0.6px]`}
          key={fee}
        >
          <div>
            <div>{name}</div>
            <div>{fee / 10000}%</div>
          </div>
            {/*<div className='flex'>*/}
            {/*  <div className='flex items-center'>*/}
            {/*    <div>🔥</div>*/}
            {/*    <div data-tip data-tooltip-id='fee-level-tip' className='ml-1 w-[15px] h-[15px]'>*/}
            {/*      <QuestionMarkLogo className={feeAmount === fee ? 'fill-black' : 'fill-white'} />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <Tooltip*/}
            {/*    className='max-w-[318.77px] normal-case !bg-tooltip !text-[#E6E6E6] !text-base !p-[10px] !opacity-100 after:!bg-tooltip '*/}
            {/*    id='fee-level-tip'*/}
            {/*    place='right'*/}
            {/*    effect='solid'*/}
            {/*  >*/}
            {/*    This fee level is incentivized*/}
            {/*  </Tooltip>*/}
            {/*</div>*/}
        </button>
      ))}
    </div>
  )
}
